import {
  Chip,
  Stack,
  Box,
  FormControl,
  Autocomplete,
  TextField,
} from '@mui/material'
import { TProductSimple } from '../../../../../core/types/Product'
import React from 'react'

export interface IProductFilterProps {
  products: TProductSimple[]
  handleProductFilter: (values: string[]) => void
  productsList: string[]
  setProductLists: (value: React.SetStateAction<string[]>) => void
}

export const ProductFilter = ({
  products,
  handleProductFilter,
  setProductLists,
  productsList,
}: IProductFilterProps) => {
  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    values: string[]
  ) => {
    setProductLists(values)

    var ids = products.filter((x) => values.includes(x.name)).map((i) => i.id)
    if (ids?.length > 0) handleProductFilter(ids)
    else handleProductFilter([])
  }

  return (
    <Stack direction="row" alignContent="center" alignItems="center">
      <FormControl fullWidth>
        <Autocomplete
          id="product-multiple-chip"
          multiple
          options={products.map((p) => p.name)}
          value={productsList}
          onChange={handleChange}
          renderTags={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip size="small" key={value} label={value} />
              ))}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Produtos"
              placeholder="Pesquisar"
            />
          )}
        />
      </FormControl>
    </Stack>
  )
}
