import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
} from '@mui/material'
import MoreIcon from '@mui/icons-material/AddCircleOutline'
import { useCustomers } from '../../core/hooks/useCustomers'
import { useNavigate } from 'react-router-dom'
import { maskCpfCnpjNumber, maskPhoneNumber } from '../../masks/masks'
import TablePaginationDefault from '../../components/Tables/TablePaginationDefault'
import InputSearch from '../../components/Inputs/InputSearch'
import AlertDefault from '../../components/Alerts'

interface Column {
  id: 'name' | 'email' | 'phone' | 'document' | 'action'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'name', label: 'Nome', minWidth: 120 },
  { id: 'email', label: 'E-mail', minWidth: 100 },
  {
    id: 'phone',
    label: 'Celular',
    minWidth: 100,
  },
  {
    id: 'document',
    label: 'Documento',
    minWidth: 100,
  },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 100,
  },
]

export default function CustomersPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useCustomers({
    page,
    rows: rowsPerPage,
    name: search,
  })

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  const navigate = useNavigate()

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Clientes
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <InputSearch
        name="Filtrar por nome"
        setSearch={setSearch}
        execute={execute}
      />

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                    {columns.map((column) => {
                      if (column.id === 'action') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="text"
                              size="small"
                              startIcon={<MoreIcon />}
                              onClick={() => navigate(`/customer/${row.id}`)}
                            >
                              Mais detalhes
                            </Button>
                          </TableCell>
                        )
                      }
                      if (column.id === 'document') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {maskCpfCnpjNumber(row.document)}
                          </TableCell>
                        )
                      }
                      if (column.id === 'phone') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {maskPhoneNumber(row.phone)}
                          </TableCell>
                        )
                      }
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
