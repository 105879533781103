import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  FormControl,
  InputLabel,
  Stack,
  Select,
  MenuItem,
} from '@mui/material'
import { dateTimeString } from '../../../core/functions/dateTime'
import AffiliatesFunction from '../../../core/functions/affiliates'
import { TAffiliated, TAffiliatedStatus } from '../../../core/types/Affiliated'
import MyAffiliationsDetails from './MyAffiliationsDetails'
import AffiliationController from '../../../core/controllers/AffiliationController'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import { useMyAffiliations } from '../../../core/hooks/useMyAffiliations'
import InputSearch from '../../../components/Inputs/InputSearch'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import { useResponsive } from '../../../core/hooks/useResponsive'
import AlertDefault from '../../../components/Alerts'

interface Column {
  id:
    | 'createdDate'
    | 'productName'
    | 'commissionPercentage'
    | 'globalCommissionPercentage'
    | 'status'
    | 'productActive'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'createdDate', label: 'Data', minWidth: 30 },
  { id: 'productActive', label: 'Ativo', minWidth: 40 },
  { id: 'productName', label: 'Nome', minWidth: 50 },
  {
    id: 'commissionPercentage',
    label: 'Comissão',
    minWidth: 40,
  },
  {
    id: 'globalCommissionPercentage',
    label: 'Comissão Geral',
    minWidth: 40,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 40,
  },
]

export default function MyAffiliations() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState<TAffiliatedStatus>(
    TAffiliatedStatus.All
  )
  const [current, setCurrent] = React.useState<TAffiliated | undefined>(
    undefined
  )
  const [open, setOpen] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [copied, setCopied] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')

  const [windowSize, setWindowSize] = React.useState([
    window.outerWidth,
    window.outerHeight,
  ])

  const oldSearch = React.useRef('')

  const smUp = useResponsive('up', 'sm')

  const { loading, data, error, execute, clear } = useMyAffiliations({
    page,
    rows: rowsPerPage,
    search,
    status,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeItem = (affiliated: TAffiliated) => {
    if (!affiliated.productActive) {
      return
    }
    setCurrent(affiliated)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleChangeSave = async () => {
    if (current) {
      setSending(true)
      try {
        const response = await AffiliationController.updateAffiliated({
          data: current,
        })
        if (!response.success) {
          setErrorSending(response.error)
          return
        }
        setSuccess(true)
        handleClose()
        execute()
      } finally {
        setSending(false)
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.outerWidth, window.outerHeight])
    })
  }, [])

  React.useEffect(() => {
    if (data.rowsPerPage > 0 && data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box width="100%">
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <Stack direction="row" spacing={2}>
        <InputSearch
          id="searchMyAffiliations"
          name="Filtrar por nome"
          setSearch={setSearch}
          execute={execute}
        />

        <FormControl sx={{ width: '20%' }}>
          <InputLabel id="days-select-label">Status</InputLabel>
          <Select
            variant="outlined"
            labelId="days-select-label"
            label="Status"
            value={status}
            onChange={(e) => setStatus(Number(e.target.value))}
          >
            <MenuItem value={TAffiliatedStatus.All}>Todos</MenuItem>
            <MenuItem value={TAffiliatedStatus.Pending}>
              {AffiliatesFunction.descriptionAffiliatedStatus(
                TAffiliatedStatus.Pending
              )}
            </MenuItem>
            <MenuItem value={TAffiliatedStatus.Accepted}>
              {AffiliatesFunction.descriptionAffiliatedStatus(
                TAffiliatedStatus.Accepted
              )}
            </MenuItem>
            <MenuItem value={TAffiliatedStatus.Refused}>
              {AffiliatesFunction.descriptionAffiliatedStatus(
                TAffiliatedStatus.Refused
              )}
            </MenuItem>
            <MenuItem value={TAffiliatedStatus.Blocked}>
              {AffiliatesFunction.descriptionAffiliatedStatus(
                TAffiliatedStatus.Blocked
              )}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxWidth: smUp ? '100%' : windowSize[0] - 68 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.codeId}
                    sx={{ cursor: 'pointer' }}
                  >
                    {columns.map((column) => {
                      if (column.id === 'createdDate') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleChangeItem(row)}
                          >
                            {dateTimeString(row.createdDate)}
                          </TableCell>
                        )
                      }
                      if (column.id === 'commissionPercentage') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleChangeItem(row)}
                          >
                            {row.commissionPercentage}%
                          </TableCell>
                        )
                      }
                      if (column.id === 'globalCommissionPercentage') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleChangeItem(row)}
                          >
                            {row.globalCommissionPercentage}%
                          </TableCell>
                        )
                      }
                      if (column.id === 'status') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleChangeItem(row)}
                          >
                            {AffiliatesFunction.descriptionAffiliatedStatus(
                              row.status
                            )}
                          </TableCell>
                        )
                      }
                      if (column.id === 'productActive') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleChangeItem(row)}
                          >
                            {row.productActive === true ? 'Sim' : 'Não'}
                          </TableCell>
                        )
                      }
                      const value = row[column.id]
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => handleChangeItem(row)}
                        >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <MyAffiliationsDetails
        affiliated={current}
        open={open}
        sending={sending}
        handleClose={handleClose}
        setOpen={setOpen}
        setAffiliated={setCurrent}
        saveAffiliated={handleChangeSave}
        setCopied={setCopied}
        setErrorSending={setErrorSending}
        setSending={setSending}
        setSuccess={setSuccess}
        reload={execute}
      />

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar open={error !== ''} onClose={() => setErrorSending('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Atualizado com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}
