import { CircularProgress, Stack } from '@mui/material'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { decodeToken } from 'react-jwt'
import { AuthContext } from '../../../core/context/AuthContext'
import { TAuthTenant } from '../../../core/types/AuthTenant'
import { TAffiliated, TAffiliatedStatus } from '../../../core/types/Affiliated'
import AffiliationController from '../../../core/controllers/AffiliationController'
import { useNavigate, useParams } from 'react-router-dom'

const AffiliateRecruitingAffiliationPage = () => {
  const { id } = useParams<{ id: string }>()

  const { user, setUser } = useContext(AuthContext)
  const json = decodeToken(user?.token ?? '')
  const authTenant = json as TAuthTenant

  const executing = useRef(false)

  const navigate = useNavigate()

  const registerAffiliation = useCallback(
    async (tenantId: string, productAffiliationId: string) => {
      if (!executing.current) {
        executing.current = true
        try {
          const affiliate: TAffiliated = {
            tenantId,
            productAffiliationId,
            status: TAffiliatedStatus.Pending,
            productId: '',
            productActive: true,
            productAllowRegistratioinThanksPages: false,
            activedRecoverySale: false,
          }
          const response = await AffiliationController.insertAffiliated({
            data: affiliate,
          })
          if (!response.success) {
            //setError(response.error);
            if (response.error.startsWith('Produto ')) {
              const productId = response.error.replaceAll('Produto ', '')
              navigate(`/product/${productId}`)
            }
          } else {
            //setSuccess(true);
            //getProduct(data.id);
            navigate('/products?page=affiliations')
          }
        } finally {
          //setLoading(false);
        }
      }
    },
    []
  )

  useEffect(() => {
    registerAffiliation(authTenant.TenantId, id ?? '')
  }, [])

  return (
    <Stack alignContent="center" alignItems="center" width="100%">
      <CircularProgress color="primary" />
    </Stack>
  )
}

export default AffiliateRecruitingAffiliationPage
