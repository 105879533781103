import * as React from 'react'
import {
  Box,
  Backdrop,
  CircularProgress,
  Chip,
  Stack,
  Typography,
} from '@mui/material'
import { useCoproducers } from '../../../core/hooks/useCoproducers'
import { addDays, dateTimeString } from '../../../core/functions/dateTime'
import Convertions from '../../../core/functions/convertions'
import { TCoproducer, TCoproducerStatus } from '../../../core/types/Coproducer'
import { useResponsive } from '../../../core/hooks/useResponsive'
import DataTable, {
  IDataTableColumn,
} from '../../../components/Tables/DataTable'
import DataTableHeaderWeb from '../../../components/Tables/DataTableHeaderWeb'
import DataTableHeaderMobile from '../../../components/Tables/DataTableHeaderMobile'
import CoproducerCard from './coproducerCard'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'
import MyCoproducerFunctions from './MyCoproducerFunctions'
import AlertDefault from '../../../components/Alerts'

const columns: readonly IDataTableColumn<TCoproducer>[] = [
  {
    id: 'productName',
    label: 'Nome do Produto',
    minWidth: 120,
    render(id, value, row) {
      return (
        <Stack direction="row" alignItems="center">
          <img
            src={row.productImageUrl || '/assets/logo/logoCeletus.svg'}
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              borderRadius: 6.67,
            }}
          />
          <Typography>{value}</Typography>
        </Stack>
      )
    },
  },
  {
    id: 'createdDate',
    label: 'Início',
    minWidth: 60,
    render(id, value, row) {
      return dateTimeString(value)
    },
  },
  {
    id: 'contractDuration',
    label: 'Vencimento',
    minWidth: 60,
    render(id, value, row) {
      return row.contractDuration === 999
        ? 'Eterno'
        : dateTimeString(addDays(row.contractDuration || 0, row.createdDate))
    },
  },
  {
    id: 'issuanceInvoices',
    label: (
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        justifyContent="center"
      >
        <div>NFe</div>
        <HtmlTooltip title="Divide responsabilidade de emissão das notas fiscais">
          <img src="/assets/icons/infos/info-grey.svg" alt="info" />
        </HtmlTooltip>
      </Stack>
    ),
    align: 'center',
    render(id, value, row) {
      return value && <img src="/assets/icons/check/check.svg" alt="check" />
    },
  },
  {
    id: 'commissionPercentage',
    label: 'Comissão',
    minWidth: 80,
    align: 'right',
    render(id, value, row) {
      return `${value}%`
    },
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 60,
    render(id, value, row) {
      return (
        <Chip
          size="small"
          label={Convertions.coproducerStatusToString(row.status)}
          sx={MyCoproducerFunctions.getStatusChipStyles(row.status)}
        />
      )
    },
  },
]

export default function MyCoproducers() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState<TCoproducerStatus>(
    TCoproducerStatus.All
  )

  const [windowSize, setWindowSize] = React.useState([
    window.outerWidth,
    window.outerHeight,
  ])

  const oldSearch = React.useRef('')

  const smUp = useResponsive('up', 'sm')

  const { loading, data, error, execute, clear } = useCoproducers({
    page,
    rows: rowsPerPage,
    search,
    status,
  })

  console.log('coprodutores -> ', data)

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  React.useEffect(() => {
    window.addEventListener('resize', (ev) => {
      setWindowSize([window.outerWidth, window.outerHeight])
    })
  }, [])

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  const renderItemMobile = (item: TCoproducer, index: number) => {
    return <CoproducerCard key={item.id} index={index} coproducer={item} />
  }

  return (
    <Box>
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <DataTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        headerComponent={
          <DataTableHeaderWeb
            labelItems="produtos"
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
        headerMobileComponent={
          <DataTableHeaderMobile
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
        renderItemMobile={renderItemMobile}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        hasNewCustomHeader
      />
    </Box>
  )
}
