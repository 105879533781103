import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  TProduct,
  TProductLink,
  TProductLinkCategory,
  TProductLinkDuration,
} from '../../../../core/types/Product'
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import SwitchRound from '../../../../components/Switch/SwitchRound'
import AffiliatesFunction from '../../../../core/functions/affiliates'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import AffiliationController from '../../../../core/controllers/AffiliationController'
import { maskPercentage, maskPhoneNumber } from '../../../../masks/masks'
import CopiedSnackbar from '../../../../components/Snackbar/CopiedSnackbar'
import { HtmlTooltip } from '../../../../components/Tooltip/HtmlTooltip'
import CopyIcon from '@mui/icons-material/ContentCopy'
import Hosts from '../../../../core/functions/hosts'
import React from 'react'
import ProductLinkData from './ProductLink/ProductLinkData'
import ProductLinkDialog from './ProductLink/ProductLinkDialog'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import Validations from '../../../../core/functions/validations'
import ProductController from '../../../../core/controllers/ProductController'
import { TUserType } from '../../../../core/types/Auth'
import { AuthContext } from '../../../../core/context/AuthContext'

export interface IProductGeneralProp {
  control: Control<TProduct, any>
  errors: FieldErrors<TProduct>
  links: TProductLink[]
  productLink: TProductLink
  errorIdentifier?: string
  errorUrl?: string
  watch: UseFormWatch<TProduct>
  setValue: UseFormSetValue<TProduct>
  setError: (value: React.SetStateAction<string>) => void
  execute: () => void
  getLinks: () => Promise<void>
  setProductLink: (value: TProductLink) => void
  setErrorIdentifier: (value?: string) => void
  setErrorUrl: (value?: string) => void
}

export const productLinkDefault: TProductLink = {
  id: '',
  productId: '',
  codeId: '',
  identifier: '',
  url: '',
  otherProducts: false,
  category: TProductLinkCategory.SalesPage,
  duration: TProductLinkDuration.Unknown,
}

const ProductAffiliates = ({
  control,
  errors,
  links,
  productLink,
  errorIdentifier,
  errorUrl,
  watch,
  setValue,
  setError,
  execute,
  getLinks,
  setProductLink,
  setErrorIdentifier,
  setErrorUrl,
}: IProductGeneralProp) => {
  const [copied, setCopied] = useState(false)
  const [openConfirmDelete, setOpenConfirmeDelete] = useState(false)
  const [copiedLink, setCopiedLink] = useState(false)
  const [open, setOpen] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)

  const { register } = control

  const productId = watch('id')
  const affiliateId = watch('affiliate.id')
  const enableAffiliateProgram = watch('enableAffiliateProgram')
  const approveRequestManually = watch('affiliate.approveRequestManually')
  const category = watch('affiliate.category')
  const affiliate = watch('affiliate')
  const supportPhoneValue = watch('affiliate.supportPhone')
  const recruitingUrl = `${process.env.REACT_APP_PUBLIC_HOST}/affiliate-recruiting/view/${watch('memberServiceId')}`

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )
  const isAffiliate = enableAffiliateProgram === true

  register('affiliate.category', { required: isAffiliate })

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => Hosts.openNewBlank(recruitingUrl)}
        sx={{ color: 'white' }}
      >
        ABRIR LINK
      </Button>
    </React.Fragment>
  )

  const handleDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      if (affiliate?.id && affiliate.id !== '') {
        setOpenConfirmeDelete(true)
      } else {
        setValue('enableAffiliateProgram', false)
      }
    } else {
      setValue('enableAffiliateProgram', true)

      if (!affiliate) {
        setValue('affiliate', {
          productId: productId,
          approveRequestManually: false,
          receiveEmailNotification: false,
          viewBuyerContact: false,
          exposeOnMarketplace: false,
          supportPhone: '',
          description: '',
          commissionPercentage: 0,
          globalCommissionPercentage: 0,
          allowRegistrationThanksPages: false,
          enableRecoverySale: false,
          enableIndividualRecoverySale: false,
        })
      }
    }
  }

  const closeDelete = () => {
    setOpenConfirmeDelete(false)
  }

  const deleteCoproducer = async () => {
    if (affiliate?.id) {
      const response = await AffiliationController.removeProduct({
        id: affiliate.id,
      })
      if (!response.success) {
        setError(response.error)
        return
      }
      execute()
    }
  }

  const handleChangeCopyURL = async () => {
    await Hosts.copyTextToClipboard(recruitingUrl)
    setCopiedLink(true)
  }

  const handleChangeOpen = (link?: TProductLink) => {
    link ??= { ...productLinkDefault }
    setProductLink({ ...link })
    setOpen(true)
  }

  const handleChangeClose = () => {
    setOpen(false)
    setProductLink({ ...productLinkDefault })

    setErrorIdentifier(undefined)
    setErrorUrl(undefined)
  }

  const handleChangeSave = async () => {
    if (productLink) {
      productLink.productId = productId

      if (productLink.duration === TProductLinkDuration.Unknown) {
        productLink.duration = null
      }

      if (productLink.identifier.trim() === '') {
        setErrorIdentifier('Campo obrigatório')
        return
      }

      if (productLink.url.trim() === '') {
        setErrorUrl('Campo obrigatório')
        return
      }

      if (!Validations.URL(productLink.url)) {
        setErrorUrl('URL inválida')
        return
      }

      setSending(true)
      try {
        var response
        if (productLink.id === '') {
          response = await ProductController.insertLink({ data: productLink })
        } else {
          response = await ProductController.updateLink({ data: productLink })
        }
        if (!response.success) {
          setError(response.error)
          return
        }
        setSuccess(true)
        handleChangeClose()
        getLinks()
      } finally {
        setSending(false)
      }
    }
  }

  const handleChangeRemove = (link?: TProductLink) => {
    link ??= { ...productLinkDefault }
    setProductLink({ ...link })
    setOpenRemove(true)
  }

  const handleChangeCloseRemove = () => {
    setOpenRemove(false)
    setProductLink({ ...productLinkDefault })
  }

  const removeLink = async () => {
    if (productLink) {
      setSending(true)
      try {
        const response = await ProductController.removeLink({
          productLinkId: productLink.id,
        })
        if (!response.success) {
          setError(response.error)
          return
        }
        setSuccess(true)
        handleChangeCloseRemove()
        getLinks()
      } finally {
        setSending(false)
      }
    }
  }

  useEffect(() => {
    const phoneComplete = maskPhoneNumber(supportPhoneValue)
    setValue('affiliate.supportPhone', phoneComplete)
  }, [supportPhoneValue, setValue])

  useEffect(() => {
    if ((enableAffiliateProgram || false) === false) {
      setValue('affiliate.approveRequestManually', false)
      setValue('affiliate.receiveEmailNotification', false)
      setValue('affiliate.viewBuyerContact', false)
      setValue('affiliate.exposeOnMarketplace', false)
    }
  }, [enableAffiliateProgram])

  useEffect(() => {
    if (approveRequestManually === false) {
      setValue('affiliate.receiveEmailNotification', false)
    }
  }, [approveRequestManually])

  return (
    <>
      <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={3}>
        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1 }} p={2}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '20px',
              color: '#343948',
              mb: 2,
            }}
          >
            Informações básicas do produto
          </Typography>

          <Stack direction={'column'} spacing={2}>
            <Autocomplete
              disablePortal
              size="small"
              disabled={!isAffiliate || isAdmin}
              options={AffiliatesFunction.productAffiliatesCategories}
              getOptionLabel={(option) =>
                AffiliatesFunction.descriptionProductAffiliatesCategory(option)
              }
              onChange={(event, option) => {
                if (option && option !== null) {
                  setValue('affiliate.category', option)
                }
              }}
              value={category}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errors.affiliate?.category?.type === 'required'}
                  label="Categoria"
                />
              )}
            />
            {errors.affiliate?.category?.type === 'required' && (
              <Typography variant="overline" color={'red'}>
                Campo obrigatório
              </Typography>
            )}

            <TextField
              fullWidth
              size="small"
              type="text"
              label="Telefone de suporte para afiliados"
              disabled={!isAffiliate || isAdmin}
              {...register('affiliate.supportPhone', {
                required: isAffiliate,
              })}
              error={errors.affiliate?.supportPhone?.type === 'required'}
              InputLabelProps={{
                shrink:
                  watch('affiliate.supportPhone') != null &&
                  watch('affiliate.supportPhone') !== '',
              }}
            />
            {errors.affiliate?.supportPhone?.type === 'required' && (
              <Typography variant="overline" color={'red'}>
                Campo obrigatório
              </Typography>
            )}

            <TextField
              fullWidth
              multiline
              rows={10}
              size="small"
              type="text"
              label="Descrição para afiliados"
              disabled={!isAffiliate || isAdmin}
              {...register('affiliate.description', {
                required: isAffiliate,
              })}
              error={errors.affiliate?.description?.type === 'required'}
            />
            {errors.affiliate?.description?.type === 'required' && (
              <Typography variant="overline" color={'red'}>
                Campo obrigatório
              </Typography>
            )}
            <Alert severity="warning" sx={{ fontSize: 12 }}>
              Você precisa de uma descrição para aparecer no marketplace público
            </Alert>

            <Stack direction="row" spacing={3}>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="comissao-label"
                  sx={{ backgroundColor: 'white' }}
                  shrink={true}
                >
                  Comissão
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="comissao-label"
                  label="Comissão"
                  disabled={!isAffiliate || isAdmin}
                  value={maskPercentage(affiliate?.commissionPercentage)}
                  onChange={(e) => {
                    const commissionPercentage = maskPercentage(e.target.value)
                      .replaceAll('.', '')
                      .replaceAll(',', '.')
                    setValue(
                      'affiliate.commissionPercentage',
                      Number(commissionPercentage)
                    )
                  }}
                  endAdornment={'%'}
                />
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel
                  id="comissao-global-label"
                  sx={{ backgroundColor: 'white' }}
                  shrink={true}
                >
                  Comissão Global
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="comissao-global-label"
                  label="Comissão Global"
                  disabled={!isAffiliate || isAdmin}
                  value={maskPercentage(affiliate?.globalCommissionPercentage)}
                  onChange={(e) => {
                    const globalCommissionPercentage = maskPercentage(
                      e.target.value
                    )
                      .replaceAll('.', '')
                      .replaceAll(',', '.')
                    setValue(
                      'affiliate.globalCommissionPercentage',
                      Number(globalCommissionPercentage)
                    )
                  }}
                  endAdornment={'%'}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Box>

        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: 1,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          p={2}
        >
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '20px',
              color: '#343948',
              mb: 2,
            }}
          >
            Configurações
          </Typography>
          <Stack direction={'column'} spacing={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <SwitchRound
                    disabled={isAdmin}
                    sx={{ m: 1 }}
                    checked={isAffiliate}
                    onChange={handleDelete}
                  />
                }
                label="Habilitar programa de afiliados"
              />
              <FormControlLabel
                control={
                  <SwitchRound
                    sx={{ m: 1 }}
                    disabled={!isAffiliate || isAdmin}
                    {...register('affiliate.approveRequestManually')}
                    checked={watch('affiliate.approveRequestManually')}
                    onChange={(e) =>
                      setValue(
                        'affiliate.approveRequestManually',
                        e.target.checked
                      )
                    }
                  />
                }
                label="Aprovar cada solicitação de afiliação manualmente"
              />
              <Fade
                in={watch('affiliate.approveRequestManually')}
                mountOnEnter
                unmountOnExit
              >
                <FormControlLabel
                  control={
                    <SwitchRound
                      sx={{ m: 1, ml: 3 }}
                      disabled={
                        !isAffiliate ||
                        approveRequestManually === false ||
                        isAdmin
                      }
                      {...register('affiliate.receiveEmailNotification')}
                      checked={watch('affiliate.receiveEmailNotification')}
                      onChange={(e) =>
                        setValue(
                          'affiliate.receiveEmailNotification',
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Receber notificação via e-mail para cada solicitação"
                />
              </Fade>
              <FormControlLabel
                control={
                  <SwitchRound
                    sx={{ m: 1 }}
                    disabled={!isAffiliate || isAdmin}
                    {...register('affiliate.viewBuyerContact')}
                    checked={watch('affiliate.viewBuyerContact')}
                    onChange={(e) =>
                      setValue('affiliate.viewBuyerContact', e.target.checked)
                    }
                  />
                }
                label="Liberar acesso aos dados de contato dos compradores"
              />
              <FormControlLabel
                control={
                  <SwitchRound
                    sx={{ m: 1 }}
                    disabled={!isAffiliate || isAdmin}
                    {...register('affiliate.exposeOnMarketplace')}
                    checked={watch('affiliate.exposeOnMarketplace')}
                    onChange={(e) =>
                      setValue(
                        'affiliate.exposeOnMarketplace',
                        e.target.checked
                      )
                    }
                  />
                }
                label="Mostrar meu produto no marketplace público"
              />
              <FormControlLabel
                control={
                  <SwitchRound
                    sx={{ m: 1 }}
                    disabled={!isAffiliate || isAdmin}
                    {...register('affiliate.allowRegistrationThanksPages')}
                    checked={watch('affiliate.allowRegistrationThanksPages')}
                    onChange={(e) =>
                      setValue(
                        'affiliate.allowRegistrationThanksPages',
                        e.target.checked
                      )
                    }
                  />
                }
                label="Permitir o cadastro de páginas de obrigado pelos afiliados"
              />
              <FormControlLabel
                control={
                  <SwitchRound
                    sx={{ m: 1 }}
                    disabled={!isAffiliate || isAdmin}
                    {...register('affiliate.enableRecoverySale')}
                    checked={watch('affiliate.enableRecoverySale')}
                    onChange={(e) => {
                      setValue('affiliate.enableRecoverySale', e.target.checked)
                      if (
                        !e.target.checked &&
                        watch('affiliate.enableIndividualRecoverySale')
                      ) {
                        setValue(
                          'affiliate.enableIndividualRecoverySale',
                          false
                        )
                      }
                    }}
                  />
                }
                label="Recuperar vendas de afiliados"
              />
              <Fade
                in={watch('affiliate.enableRecoverySale')}
                mountOnEnter
                unmountOnExit
              >
                <FormControlLabel
                  control={
                    <SwitchRound
                      sx={{ m: 1, ml: 3 }}
                      disabled={!isAffiliate || isAdmin}
                      {...register('affiliate.enableIndividualRecoverySale')}
                      checked={watch('affiliate.enableIndividualRecoverySale')}
                      onChange={(e) =>
                        setValue(
                          'affiliate.enableIndividualRecoverySale',
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Recuperação individual"
                />
              </Fade>
            </FormGroup>
          </Stack>
        </Box>

        <ProductLinkDialog
          open={open}
          link={productLink}
          sending={sending}
          errorIdentifier={errorIdentifier}
          errorUrl={errorUrl}
          setLink={setProductLink}
          setOpen={setOpen}
          onSave={handleChangeSave}
        />

        <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
          Concluído com sucesso
        </SuccessSnackbar>

        <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />

        <CopiedSnackbar
          open={copiedLink}
          onClose={() => setCopiedLink(false)}
          action={action}
        />

        <ConfirmDialog
          open={openConfirmDelete}
          title="Confirmar"
          message="Deseja realmente desabilitar os afiliados ?"
          onClose={closeDelete}
          onYes={deleteCoproducer}
        />

        <ConfirmDialog
          open={openRemove}
          onClose={() => setOpenRemove(false)}
          message="Deseja realmente deletar o link de divulgação ?"
          onYes={removeLink}
        />
      </Stack>

      {isAffiliate && (
        <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 1, mt: 2 }} p={2}>
          <Stack direction={'column'} spacing={2}>
            {isAffiliate && (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                alignItems={'left'}
                justifyContent={'left'}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography variant="overline">
                    Links de divulgações
                  </Typography>
                  {productId &&
                    productId !== 'new' &&
                    productId !== '' &&
                    links.length > 0 &&
                    !isAdmin && (
                      <Button
                        size="small"
                        variant="text"
                        color="secondary"
                        onClick={() => handleChangeOpen()}
                      >
                        Adicionar novo link
                      </Button>
                    )}
                </Stack>
                <Divider />
              </Box>
            )}
            {isAffiliate && (
              <Stack direction="column">
                <ProductLinkData
                  isAffiliate={isAffiliate}
                  links={links}
                  productLink={productLink}
                  errorIdentifier={errorIdentifier}
                  errorUrl={errorUrl}
                  setProductLink={setProductLink}
                  onOpenLink={handleChangeOpen}
                  onRemoveLink={handleChangeRemove}
                />
              </Stack>
            )}
            {isAffiliate && (
              <TextField
                fullWidth
                size="small"
                label="Link de recrutamento"
                disabled={!isAffiliate || isAdmin}
                value={recruitingUrl}
                InputProps={{
                  endAdornment: (
                    <>
                      {!isAdmin && (
                        <InputAdornment position="end">
                          <HtmlTooltip
                            title={<Typography>Copiar URL</Typography>}
                          >
                            <IconButton
                              disabled={!isAffiliate}
                              onClick={handleChangeCopyURL}
                            >
                              <CopyIcon />
                            </IconButton>
                          </HtmlTooltip>
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            )}
          </Stack>
        </Box>
      )}

      <Stack mt={2} alignItems="flex-end">
        {!isAdmin && (
          <Button
            color="primary"
            type="submit"
            variant="contained"
            fullWidth={isSmallScreen ? true : false}
          >
            Salvar
          </Button>
        )}
      </Stack>
    </>
  )
}

export default ProductAffiliates
