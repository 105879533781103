import React, { useCallback, useRef } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import OrderController from '../controllers/OrderController'
import {
  ICheckoutOrdersReportProps,
  TCheckoutOrderPagination,
} from '../types/CheckoutOrder'
import LostSaleController from '../controllers/LostSaleController'
import { TLostSalePagination } from '../types/LostSale'

const valueDefault = {
  items: [],
  page: 0,
  rowsPerPage: 0,
  totalPages: 0,
  total: 0,
}

export const useCheckoutOrders = ({
  page,
  rows,
  search,
  type,
  report,
  filters,
}: ICheckoutOrdersReportProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<
    TCheckoutOrderPagination | TLostSalePagination
  >(valueDefault)
  const [error, setError] = React.useState<string>('')

  const executing = useRef(false)

  const execute = useCallback(async () => {
    if (!loading && !executing.current) {
      executing.current = true
      if (!loading) {
        const {
          startDate,
          endDate,
          affiliateds,
          payments,
          products,
          sellerType,
          status,
          lastDays = 30,
        } = filters

        try {
          setLoading(true)

          if (error !== '') {
            setError('')
          }

          var response
          if (type === 'abandoned') {
            response = await LostSaleController.getAll({
              page,
              rows,
              search,
              lastDays,
              report: report,
            })
          } else if (type === 'approved') {
            response = await OrderController.getApproved({
              page,
              rows,
              search,
              type,
              report,
              startDate,
              endDate,
              affiliateds,
              payments,
              products,
              sellerType,
              status,
            })
          } else {
            response = await OrderController.getAll({
              page,
              rows,
              search,
              type,
              report,
              startDate,
              endDate,
              affiliateds,
              payments,
              products,
              sellerType,
              status,
            })
          }

          const responseError = response as IErrorResponse
          const responseData =
            type === 'abandoned'
              ? (response as TLostSalePagination)
              : (response as TCheckoutOrderPagination)

          if (responseError.code) {
            setError(responseError.error)
            setData(valueDefault)
          } else {
            setData(responseData)
          }
        } catch (e) {
          var message = 'Erro não identificado'
          if (typeof e === 'string') {
            message = e.toUpperCase()
          } else if (e instanceof Error) {
            const err = e as Error
            message = err.message
          }
          setError(message)
        } finally {
          setLoading(false)
          executing.current = false
        }
      }
    }
  }, [error, loading, page, rows, search, type, report, executing, filters])

  React.useEffect(() => {
    execute()
  }, [page, rows, type, report, filters])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
