export const webhookSendAffiliatedExample = {
  id: '470c322e-f07a-45a1-9f65-8c0da8f1750e',
  event_name: 'Nome do Webhook',
  event_type: 'Affiliation.Created',
  affiliated: {
    code: 'DKSU12DK',
    name: 'Nome do Usuário',
    email: 'email@usuario.com',
    phone: '00999999999',
  },
  product: {
    id: 'e064ce1e-2b86-4bc9-be77-1f0a4f3999df',
    code: 'AD8OIEY75DC',
    name: 'Nome do Produto',
    commission_percentage: 50.5,
    global_commission_percentage: 5.5,
  },
}
