import * as React from 'react'
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Button,
  Paper,
} from '@mui/material'
import { useWebhooks } from '../../../core/hooks/useWebhooks'
import WebhookSwipeableDrawer from '../Webhook'
import { TWebhook } from '../../../core/types/Webhook'
import { useProductsSimple } from '../../../core/hooks/useProductsSimple'
import Validations from '../../../core/functions/validations'
import WebhookController from '../../../core/controllers/WebhookController'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import { useCurrentTenant } from '../../../core/hooks/useCurrentTenant'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import DataTable, {
  IDataTableColumn,
} from '../../../components/Tables/DataTable'
import TitlePageTypography from '../../../components/Typographys/TitlePageTypography'
import DataTableHeaderWeb from '../../../components/Tables/DataTableHeaderWeb'
import AlertDefault from '../../../components/Alerts'

const webhookEmpty: TWebhook = {
  identifier: '',
  url: '',
  allProducts: true,
  numberAttempts: 5,
  events: [],
  isSendToOrderbumps: false,
}

export default function WebhooksPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [current, setCurrent] = React.useState<TWebhook>(webhookEmpty)
  const [sending, setSending] = React.useState(false)
  const [errorSend, setErrorSend] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const [openConfirmDelete, setOpenConfirmeDelete] = React.useState(false)
  const [copied, setCopied] = React.useState(false)

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useWebhooks({
    initialExecute: true,
    page,
    rows: rowsPerPage,
    search: search,
  })

  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
    includeAffiliatedProducts: true,
  })

  const { data: currentTenant } = useCurrentTenant()

  const columns: readonly IDataTableColumn<TWebhook>[] = [
    {
      id: 'productName',
      label: 'Produto',
      minWidth: 150,
      format: (value) => value || 'Todos',
    },
    { id: 'identifier', label: 'Nome', minWidth: 150 },
    {
      id: 'id',
      label: 'Ações',
      width: 90,
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton size="small" onClick={() => handleOpen(row)}>
              <img src="/assets/icons/actions/edit.svg" alt="edit" />
            </IconButton>
            <IconButton
              size="small"
              color="error"
              onClick={() => handleDelete(row)}
            >
              <img src="/assets/icons/actions/delete.svg" alt="delete" />
            </IconButton>
          </Stack>
        )
      },
    },
  ]

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleOpen = (webhook: TWebhook) => {
    setCurrent(webhook)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent(webhookEmpty)
    setOpen(false)
  }

  const handleSaveWebhook = async () => {
    if (current.identifier.trim() === '') {
      setErrorSend('É necessário informar um nome')
      return
    }

    if (current.url.trim() === '') {
      setErrorSend('É necessário informar uma URL')
      return
    }

    if (!Validations.URL(current.url)) {
      setErrorSend('URL inválido')
      return
    }

    if ((current.events?.length || 0) <= 0) {
      setErrorSend('Selecione no mínimo um evento')
      return
    }

    setSending(true)
    try {
      if (current.id === undefined || current.id === '') {
        const response = await WebhookController.insert({
          data: current,
        })
        if (!response.success) {
          setErrorSend(response.error)
          return
        }
      } else {
        const response = await WebhookController.update({
          data: current,
        })
        if (!response.success) {
          setErrorSend(response.error)
          return
        }
      }
      setSuccess(true)
    } finally {
      setSending(false)
    }
  }

  const handleDelete = (webhook: TWebhook) => {
    setCurrent(webhook)
    setOpenConfirmeDelete(true)
  }

  const closeDelete = () => {
    setCurrent(webhookEmpty)
    setOpenConfirmeDelete(false)
  }

  const deleteWebhook = async () => {
    if (!current) {
      return
    }

    setSending(true)
    try {
      if (current.id && current.id !== '') {
        const response = await WebhookController.remove({ id: current.id })
        if (!response.success) {
          setErrorSend(response.error)
          return
        }
        setSuccess(true)
      }
    } finally {
      setSending(false)
    }

    setCurrent(webhookEmpty)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Paper
            elevation={0}
            sx={{
              borderRadius: '8px',
              p: '7px',
              fontSize: '0rem',
              backgroundColor: '#F2F2FF',
              border: '1px solid #D8D9FF',
              width: '40px',
              height: '40px',
            }}
          >
            <img
              src="/assets/icons/menu/webhook/webhook-black.svg"
              alt="webhook"
              style={{ width: '24px', height: '24px' }}
            />
          </Paper>
          <TitlePageTypography>Webhooks</TitlePageTypography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <DataTableHeaderWeb
            labelItems="webhooks"
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(webhookEmpty)}
            sx={{
              borderRadius: '4px',
              padding: '8px 16px',
              gap: '10px',
              textDecoration: 'none',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: 700,
              }}
            >
              Novo Webhook
            </Typography>
          </Button>
        </Stack>
      </Box>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <DataTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        hasNewCustomHeader
      />

      <Box sx={{ height: 80 }} />

      <WebhookSwipeableDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        webhook={current}
        setWebhook={setCurrent}
        onSave={handleSaveWebhook}
        sending={sending}
        products={products}
        setError={setErrorSend}
        setLoading={setSending}
        tenant={currentTenant}
        setCopied={setCopied}
      />

      <ConfirmDialog
        open={openConfirmDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o webhook?"
        onClose={closeDelete}
        onYes={deleteWebhook}
      />

      <LoadingBackdrop open={loading || loadingProducts || sending} />

      <ErrorSnackbar open={errorSend !== ''} onClose={() => setErrorSend('')}>
        {errorSend}
      </ErrorSnackbar>

      <SuccessSnackbar
        open={success}
        autoHideDuration={2000}
        onClose={() => {
          setSuccess(false)
          handleClose()
          execute()
        }}
      >
        Concluído com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}
