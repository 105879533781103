export enum TWebhookEventEnum {
  BoletoGenerated = 1,
  PixGenerated = 2,
  PurchaseDeclined = 3,
  ApprovedPurchase = 4,
  Chargeback = 5,
  ExpiredPurchase = 6,
  LatePurchase = 7,
  WaitingPayment = 8,
  AbandonedCheckout = 9,
  Protested = 10,
  Refunded = 11,
  Affiliation = 16,
}

export enum TWebhookGenerateEnum {
  BoletoGenerated = 1,
  PixGenerated,
  PurchaseDeclined,
  ApprovedPurchase,
  Chargeback,
  ApprovedPurchaseComplete,
  PurchaseDeclinedBlocked,
  PurchaseDeclinedNoFunds,
  ExpiredPurchaseExpired,
  LatePurchaseOverdue,
  WaitingPayment,
  ProcessingTransaction,
  AbandonedCheckout,
  Protested,
  Refunded,
  Affiliation,
}

export enum TWebhookEventStatusEnum {
  Approved = 1,
  Complete,
  Blocked,
  PurchaseDeclined,
  NoFunds,
  Expired,
  Overdue,
  WaitingPayment,
  ProcessingTransaction,
  Abandoned,
  Protested,
  Refunded,
  Chargeback,
  UnderAnalisys,
  Affiliation,
}

export type TWebhookSend = {
  id: string
  identifier: string
  url: string
  productName?: string
  customerName?: string
  customerEmail?: string
  event?: TWebhookGenerateEnum
  returnCode?: string
  createdDate: Date
  jsonSend: string
  jsonReturn: string
}

export type TWebhookSendsPagination = {
  items: TWebhookSend[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}

export type TWebhook = {
  id?: string
  identifier: string
  url: string
  numberAttempts?: number
  allProducts: boolean
  productId?: string
  productName?: string
  events?: TWebhookEventEnum[]
  productIds?: string[]
  isSendToOrderbumps: boolean
}

export type TWebhooksPagination = {
  items: TWebhook[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}
