import {
  TWebhookEventEnum,
  TWebhookEventStatusEnum,
  TWebhookGenerateEnum,
} from '../types/Webhook'

const all = [
  TWebhookEventEnum.BoletoGenerated,
  TWebhookEventEnum.PixGenerated,
  TWebhookEventEnum.PurchaseDeclined,
  TWebhookEventEnum.ApprovedPurchase,
  TWebhookEventEnum.Chargeback,
  TWebhookEventEnum.ExpiredPurchase,
  TWebhookEventEnum.LatePurchase,
  TWebhookEventEnum.WaitingPayment,
  TWebhookEventEnum.AbandonedCheckout,
  TWebhookEventEnum.Protested,
  TWebhookEventEnum.Refunded,
]

const webhookByPayments = [
  {
    payment: 'Boleto',
    events: [
      TWebhookEventEnum.WaitingPayment,
      TWebhookEventEnum.ExpiredPurchase,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
    ],
  },

  {
    payment: 'Pix',
    events: [
      TWebhookEventEnum.WaitingPayment,
      TWebhookEventEnum.ExpiredPurchase,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
    ],
  },

  {
    payment: 'CreditCard',
    events: [
      TWebhookEventEnum.ApprovedPurchase,
      TWebhookEventEnum.PurchaseDeclined,
      TWebhookEventEnum.Chargeback,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
    ],
  },

  {
    payment: 'DebitCard',
    events: [
      TWebhookEventEnum.ApprovedPurchase,
      TWebhookEventEnum.PurchaseDeclined,
      TWebhookEventEnum.Chargeback,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
    ],
  },

  {
    payment: 'BankTransfer',
    events: [
      TWebhookEventEnum.PurchaseDeclined,
      TWebhookEventEnum.Protested,
      TWebhookEventEnum.Refunded,
    ],
  },
]

const statusString = (value?: TWebhookEventEnum) => {
  switch (value) {
    case TWebhookEventEnum.ApprovedPurchase:
      return 'Compra Aprovada'
    case TWebhookEventEnum.BoletoGenerated:
      return 'Boleto Gerado'
    case TWebhookEventEnum.PixGenerated:
      return 'PIX Gerado'
    case TWebhookEventEnum.Chargeback:
      return 'Estorno'
    case TWebhookEventEnum.PurchaseDeclined:
      return 'Compra Recusada'
    case TWebhookEventEnum.ExpiredPurchase:
      return 'Compra expirada'
    case TWebhookEventEnum.LatePurchase:
      return 'Recorrência em atraso'
    case TWebhookEventEnum.WaitingPayment:
      return 'Aguardando pagamento'
    case TWebhookEventEnum.AbandonedCheckout:
      return 'Compras abandonadas'
    case TWebhookEventEnum.Protested:
      return 'Solicitação reembolso'
    case TWebhookEventEnum.Refunded:
      return 'Reembolso'
    case TWebhookEventEnum.Affiliation:
      return 'Afiliação'
  }
}

const statusGenerateString = (value?: TWebhookGenerateEnum) => {
  switch (value) {
    case TWebhookGenerateEnum.BoletoGenerated:
      return 'Boleto Gerado'
    case TWebhookGenerateEnum.PixGenerated:
      return 'Pix Gerado'
    case TWebhookGenerateEnum.PurchaseDeclined:
      return 'Compra Negada'
    case TWebhookGenerateEnum.ApprovedPurchase:
      return 'Compra Aprovada'
    case TWebhookGenerateEnum.Chargeback:
      return 'Estorno'
    case TWebhookGenerateEnum.ApprovedPurchaseComplete:
      return 'Compra Aprovada Concluída'
    case TWebhookGenerateEnum.PurchaseDeclinedBlocked:
      return 'Compra Bloqueada'
    case TWebhookGenerateEnum.PurchaseDeclinedNoFunds:
      return 'Compra Recusada '
    case TWebhookGenerateEnum.ExpiredPurchaseExpired:
      return 'Compra Expirada'
    case TWebhookGenerateEnum.LatePurchaseOverdue:
      return 'Recorrência em Atraso'
    case TWebhookGenerateEnum.WaitingPayment:
      return 'Aguardando Pagamento'
    case TWebhookGenerateEnum.ProcessingTransaction:
      return 'Processando Transação'
    case TWebhookGenerateEnum.AbandonedCheckout:
      return 'Compras Abandonadas'
    case TWebhookGenerateEnum.Protested:
      return 'Solicitação Reembolso'
    case TWebhookGenerateEnum.Refunded:
      return 'Reembolso'
  }
}

const eventType = (value?: TWebhookEventEnum) => {
  switch (value) {
    case TWebhookEventEnum.ApprovedPurchase:
      return 'ApprovedPurchase'
    case TWebhookEventEnum.BoletoGenerated:
      return 'BoletoGenerated'
    case TWebhookEventEnum.PixGenerated:
      return 'PixGenerated'
    case TWebhookEventEnum.Chargeback:
      return 'Chargeback'
    case TWebhookEventEnum.PurchaseDeclined:
      return 'PurchaseDeclined'
    case TWebhookEventEnum.ExpiredPurchase:
      return 'ExpiredPurchase'
    case TWebhookEventEnum.LatePurchase:
      return 'LatePurchase'
    case TWebhookEventEnum.WaitingPayment:
      return 'WaitingPayment'
    case TWebhookEventEnum.AbandonedCheckout:
      return 'AbandonedCheckout'
    case TWebhookEventEnum.Protested:
      return 'Protested'
    case TWebhookEventEnum.Refunded:
      return 'Refunded'
  }
  return ''
}

const eventStatus = (value?: TWebhookEventStatusEnum) => {
  if (!value) {
    return ''
  }

  const values = {
    [TWebhookEventStatusEnum.Abandoned]: 'Abandoned',
    [TWebhookEventStatusEnum.Approved]: 'Approved',
    [TWebhookEventStatusEnum.Blocked]: 'Blocked',
    [TWebhookEventStatusEnum.Chargeback]: 'Chargeback',
    [TWebhookEventStatusEnum.Complete]: 'Complete',
    [TWebhookEventStatusEnum.Expired]: 'Expired',
    [TWebhookEventStatusEnum.NoFunds]: 'NoFunds',
    [TWebhookEventStatusEnum.Overdue]: 'Overdue',
    [TWebhookEventStatusEnum.ProcessingTransaction]: 'ProcessingTransaction',
    [TWebhookEventStatusEnum.Protested]: 'Protested',
    [TWebhookEventStatusEnum.PurchaseDeclined]: 'PurchaseDeclined',
    [TWebhookEventStatusEnum.Refunded]: 'Refunded',
    [TWebhookEventStatusEnum.UnderAnalisys]: 'UnderAnalisys',
    [TWebhookEventStatusEnum.WaitingPayment]: 'WaitingPayment',
    [TWebhookEventStatusEnum.Affiliation]: 'Affiliation',
  }

  return values[value]
}

const generateString = (value?: TWebhookGenerateEnum) => {
  if (!value) {
    return ''
  }

  const values = {
    [TWebhookGenerateEnum.AbandonedCheckout]: 'AbandonedCheckout',
    [TWebhookGenerateEnum.ApprovedPurchase]: 'ApprovedPurchase',
    [TWebhookGenerateEnum.ApprovedPurchaseComplete]: 'ApprovedPurchaseComplete',
    [TWebhookGenerateEnum.BoletoGenerated]: 'BoletoGenerated',
    [TWebhookGenerateEnum.Chargeback]: 'Chargeback',
    [TWebhookGenerateEnum.ExpiredPurchaseExpired]: 'ExpiredPurchaseExpired',
    [TWebhookGenerateEnum.LatePurchaseOverdue]: 'LatePurchaseOverdue',
    [TWebhookGenerateEnum.PixGenerated]: 'PixGenerated',
    [TWebhookGenerateEnum.ProcessingTransaction]: 'ProcessingTransaction',
    [TWebhookGenerateEnum.Protested]: 'Protested',
    [TWebhookGenerateEnum.PurchaseDeclined]: 'PurchaseDeclined',
    [TWebhookGenerateEnum.PurchaseDeclinedBlocked]: 'PurchaseDeclinedBlocked',
    [TWebhookGenerateEnum.PurchaseDeclinedNoFunds]: 'PurchaseDeclinedNoFunds',
    [TWebhookGenerateEnum.Refunded]: 'Refunded',
    [TWebhookGenerateEnum.WaitingPayment]: 'WaitingPayment',
    [TWebhookGenerateEnum.Affiliation]: 'Affiliation',
  }

  return values[value]
}

const chargeStatusString = (value?: TWebhookEventEnum) => {
  switch (value) {
    case TWebhookEventEnum.ApprovedPurchase:
      return 'all'
    case TWebhookEventEnum.BoletoGenerated:
      return 'boleto'
    case TWebhookEventEnum.PixGenerated:
      return 'pix'
    case TWebhookEventEnum.Chargeback:
      return 'credit_card | debit_card'
    case TWebhookEventEnum.PurchaseDeclined:
      return 'credit_card | debit_card'
    case TWebhookEventEnum.ExpiredPurchase:
      return 'boleto | pix'
    case TWebhookEventEnum.LatePurchase:
      return 'boleto | pix'
    case TWebhookEventEnum.WaitingPayment:
      return 'boleto | pix'
    case TWebhookEventEnum.AbandonedCheckout:
      return 'all'
    case TWebhookEventEnum.Protested:
      return 'all'
    case TWebhookEventEnum.Refunded:
      return 'all'
  }
}

const orderStatusString = (value?: TWebhookEventEnum) => {
  switch (value) {
    case TWebhookEventEnum.ApprovedPurchase:
      return 'paid'
    case TWebhookEventEnum.BoletoGenerated:
      return 'pending'
    case TWebhookEventEnum.PixGenerated:
      return 'pending'
    case TWebhookEventEnum.Chargeback:
      return 'chargedback'
    case TWebhookEventEnum.PurchaseDeclined:
      return 'failed | canceled'
    case TWebhookEventEnum.ExpiredPurchase:
      return 'pending'
    case TWebhookEventEnum.LatePurchase:
      return 'pending'
    case TWebhookEventEnum.WaitingPayment:
      return 'pending'
    case TWebhookEventEnum.AbandonedCheckout:
      return ''
    case TWebhookEventEnum.Protested:
      return 'chargedback'
    case TWebhookEventEnum.Refunded:
      return 'chargedback'
  }
}

const getEventStatusEnum = (value?: TWebhookEventEnum) => {
  if (!value) {
    return TWebhookEventStatusEnum.WaitingPayment
  }

  const values = {
    [TWebhookEventEnum.AbandonedCheckout]: TWebhookEventStatusEnum.Abandoned,
    [TWebhookEventEnum.ApprovedPurchase]: TWebhookEventStatusEnum.Approved,
    [TWebhookEventEnum.BoletoGenerated]: TWebhookEventStatusEnum.WaitingPayment,
    [TWebhookEventEnum.Chargeback]: TWebhookEventStatusEnum.Chargeback,
    [TWebhookEventEnum.ExpiredPurchase]: TWebhookEventStatusEnum.Expired,
    [TWebhookEventEnum.LatePurchase]: TWebhookEventStatusEnum.Expired,
    [TWebhookEventEnum.PixGenerated]: TWebhookEventStatusEnum.WaitingPayment,
    [TWebhookEventEnum.Protested]: TWebhookEventStatusEnum.Protested,
    [TWebhookEventEnum.PurchaseDeclined]:
      TWebhookEventStatusEnum.PurchaseDeclined,
    [TWebhookEventEnum.Refunded]: TWebhookEventStatusEnum.Refunded,
    [TWebhookEventEnum.WaitingPayment]: TWebhookEventStatusEnum.WaitingPayment,
    [TWebhookEventEnum.Affiliation]: TWebhookEventStatusEnum.Affiliation,
  }

  return values[value]
}

const getGenerateEnum = (
  eventEnum: TWebhookEventEnum,
  status?: TWebhookEventStatusEnum
) => {
  if (eventEnum == TWebhookEventEnum.BoletoGenerated)
    return TWebhookGenerateEnum.BoletoGenerated
  if (eventEnum == TWebhookEventEnum.PixGenerated)
    return TWebhookGenerateEnum.PixGenerated

  if (eventEnum == TWebhookEventEnum.ApprovedPurchase) {
    if (status == TWebhookEventStatusEnum.Complete)
      return TWebhookGenerateEnum.ApprovedPurchaseComplete
    return TWebhookGenerateEnum.ApprovedPurchase
  }

  if (eventEnum == TWebhookEventEnum.PurchaseDeclined) {
    if (status == TWebhookEventStatusEnum.Blocked)
      return TWebhookGenerateEnum.PurchaseDeclinedBlocked
    if (status == TWebhookEventStatusEnum.NoFunds)
      return TWebhookGenerateEnum.PurchaseDeclinedNoFunds
    return TWebhookGenerateEnum.PurchaseDeclined
  }

  if (eventEnum == TWebhookEventEnum.WaitingPayment) {
    if (status == TWebhookEventStatusEnum.ProcessingTransaction)
      return TWebhookGenerateEnum.ProcessingTransaction
    return TWebhookGenerateEnum.WaitingPayment
  }

  if (eventEnum == TWebhookEventEnum.ExpiredPurchase)
    return TWebhookGenerateEnum.ExpiredPurchaseExpired
  if (eventEnum == TWebhookEventEnum.LatePurchase)
    return TWebhookGenerateEnum.LatePurchaseOverdue
  if (eventEnum == TWebhookEventEnum.AbandonedCheckout)
    return TWebhookGenerateEnum.AbandonedCheckout
  if (eventEnum == TWebhookEventEnum.Chargeback)
    return TWebhookGenerateEnum.Chargeback
  if (eventEnum == TWebhookEventEnum.Protested)
    return TWebhookGenerateEnum.Protested
  if (eventEnum == TWebhookEventEnum.Refunded)
    return TWebhookGenerateEnum.Refunded
}

const WebhookFunction = {
  all,
  statusString,
  eventType,
  chargeStatusString,
  orderStatusString,
  webhookByPayments,
  statusGenerateString,
  eventStatus,
  getGenerateEnum,
  generateString,
  getEventStatusEnum,
}

export default WebhookFunction
