import React, { useRef } from 'react'
import { TOccurrenceDetails } from '../types/OccurrenceLog'
import { IErrorResponse } from '../types/ErrorResponse'
import OccurrenceLogController from '../controllers/OccurrenceLogController'

export interface IOccurrenceDetailsProp {
  id: string | undefined
}

export const useOccurrenceDetails = ({ id }: IOccurrenceDetailsProp) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TOccurrenceDetails | null>(null)
  const [error, setError] = React.useState<string>('')

  const executing = useRef(false)

  const execute = async () => {
    if (id && !loading && !executing.current) {
      executing.current = true
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        const response = await OccurrenceLogController.getDetails({ id })
        const responseError = response as IErrorResponse
        const responseData = response as TOccurrenceDetails

        if (responseError.code) {
          setError(responseError.error)
          setData(null)
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
        executing.current = false
      }
    }
  }

  React.useEffect(() => {
    execute()
  }, [id])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
