import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { styled, useTheme } from '@mui/material/styles'
import useChart from '../../../components/Chart/useChart'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import Chart from '../../../components/Chart/Chart'
import { ApexOptions } from 'apexcharts'
import { Box, Paper, Stack, Typography } from '@mui/material'
import ValueOrPercentageGroupButtons, {
  ValueOrPercentageGroupButtonsEnum,
} from '../../../components/Buttons/ValueOrPercentageGroupButtons'
import InvisibleDashboardCard from '../../../components/Cards/InvisibleDashboardCard'
import NotFoundChart from '../../../components/Chart/NotFoundChart'

const CHART_HEIGHT = 300

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
    height: `100% !important`,
  },
  '& .apexcharts-legend': {
    //height: LEGEND_HEIGHT,
    //top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    //width: "99%",
    height: 0,
  },
  '& .apexcharts-tooltip, .apexcharts-tooltip-series-group.active': {
    background: '#ffffff !important',
  },
}))

export interface IAppCurrentVisitsChartProps {
  labels?: Array<string>
  colors?: Array<string>
  series: Array<{ label: string; value: number; percentage: number }>
  options?: Array<any>
}

export interface IAppCurrentVisitsProps {
  chart: IAppCurrentVisitsChartProps
  title: string
  isVisibleValue: boolean
  selected: ValueOrPercentageGroupButtonsEnum
  setSelected: (value: ValueOrPercentageGroupButtonsEnum) => void
}

export default function AppTopSellingProductTypes({
  title,
  chart,
  isVisibleValue,
  selected,
  setSelected,
  ...other
}: IAppCurrentVisitsProps) {
  const theme = useTheme()

  const { colors, series, options } = chart

  const chartSeries = series.map((i) => i.percentage)

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors: colors ?? ['#1c52bd', '#637eef', '#9faaff'],
    labels: series.map((i) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
      show: false,
      lineCap: 'round',
      curve: 'smooth',
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      y: {
        formatter: (value: number, opts: any) => {
          const serie = series[opts.seriesIndex]
          return selected === ValueOrPercentageGroupButtonsEnum.Percentage
            ? `${serie.percentage.toFixed(0)}%`
            : `${NumberFunctions.formatMoneyDefault(serie.value)}`
        },
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
          color: '#38393B',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
        },
        color: '#898493',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
      },
      x: {
        formatter: (value: any, opts: any) => {
          const serie = series[opts.seriesIndex]
          return selected === ValueOrPercentageGroupButtonsEnum.Percentage
            ? `${serie.percentage.toFixed(0)}%`
            : NumberFunctions.formatMoneyDefault(serie.value)
        },
      },
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          show: false,
        },
        hollow: {
          size: series.length < 3 ? '40%' : '20%',
        },
        track: {
          margin: 16,
        },
      },
    },
    ...options,
  } as ApexOptions)

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: '8px',
        height: '100%',
      }}
      {...other}
    >
      <Stack
        direction="row"
        spacing={1}
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3 }}
      >
        <Typography
          fontWeight={600}
          fontSize="16px"
          lineHeight="20px"
          color="#343948"
        >
          {title}
        </Typography>

        <ValueOrPercentageGroupButtons
          selected={selected}
          setSelected={setSelected}
        />
      </Stack>

      {isVisibleValue &&
        series.length > 0 &&
        selected === ValueOrPercentageGroupButtonsEnum.Percentage && (
          <StyledChart
            key="ValueOrPercentageGroupButtonsEnum.Percentage"
            dir="ltr"
            type="radialBar"
            series={chartSeries}
            options={chartOptions}
            width="100%"
            height={280}
          />
        )}
      {isVisibleValue &&
        series.length > 0 &&
        selected === ValueOrPercentageGroupButtonsEnum.Value && (
          <StyledChart
            key="ValueOrPercentageGroupButtonsEnum.Value"
            dir="ltr"
            type="radialBar"
            series={chartSeries}
            options={chartOptions}
            width="100%"
            height={280}
          />
        )}
      <NotFoundChart isVisible={isVisibleValue && series.length <= 0} />
      <InvisibleDashboardCard visible={!isVisibleValue} variant="chart" />

      {isVisibleValue && (
        <Box sx={{ py: 2, px: 3 }}>
          {series.map((i, index) => (
            <Stack
              key={`top-products-types-${index}`}
              direction="row"
              spacing={1}
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 1 }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
              >
                <Paper
                  elevation={0}
                  sx={{
                    height: '8px',
                    width: '8px',
                    backgroundColor: chartOptions.colors[index],
                    borderRadius: '50%',
                  }}
                ></Paper>
                <Typography
                  color="#38393B"
                  fontWeight={400}
                  fontSize="12px"
                  lineHeight="16px"
                >
                  {i.label}
                </Typography>
              </Stack>
              <Typography
                color="#38393B"
                fontWeight={500}
                fontSize="14px"
                lineHeight="18px"
              >
                {selected === ValueOrPercentageGroupButtonsEnum.Percentage
                  ? `${i.percentage.toFixed(0)}%`
                  : NumberFunctions.formatMoneyDefault(i.value)}
              </Typography>
            </Stack>
          ))}
        </Box>
      )}
    </Card>
  )
}
