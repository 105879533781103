import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import BlockIcon from '@mui/icons-material/Block'
import ActiveIcon from '@mui/icons-material/TaskAlt'
import DesactiveIcon from '@mui/icons-material/HighlightOffOutlined'
import InfoIcon from '@mui/icons-material/InfoRounded'
import { useEffect, useState } from 'react'
import { useAffiliateds } from '../../core/hooks/useAffiliateds'
import { TAffiliated, TAffiliatedStatus } from '../../core/types/Affiliated'
import AffiliationController from '../../core/controllers/AffiliationController'
import InputSearch from '../../components/Inputs/InputSearch'
import LoadingBackdrop from '../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../components/Snackbar/SuccessSnackbar'
import SwipeableDrawerRightDialog from '../../components/Dialogs/SwipeableDrawerRightDialog'
import TablePaginationDefault from '../../components/Tables/TablePaginationDefault'
import ListItemDetail from '../../components/ListItem/ListItemDetail'
import NumberFunctions from '../../core/functions/NumberFunctions'
import { maskPercentage } from '../../masks/masks'
import { HtmlTooltip } from '../../components/Tooltip/HtmlTooltip'
import AlertDefault from '../../components/Alerts'

interface Column {
  id:
    | 'tenantName'
    | 'email'
    | 'tenantCodeId'
    | 'productName'
    | 'status'
    | 'action'
    | 'commissionPercentage'
    | 'globalCommissionPercentage'
    | 'affiliatedMonthlyFeesPaid'
    | 'affiliatedCommissionValue'
    | 'enabledCommission'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'tenantCodeId', label: 'Código', minWidth: 100 },
  { id: 'tenantName', label: 'Afiliado', minWidth: 120 },
  { id: 'email', label: 'Email', minWidth: 120 },
  {
    id: 'productName',
    label: 'Produto',
    minWidth: 100,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 70,
  },
  {
    id: 'commissionPercentage',
    label: 'Comissão',
    minWidth: 70,
    align: 'right',
    format: (value) => NumberFunctions.toPercentage(value),
  },
  {
    id: 'globalCommissionPercentage',
    label: 'Global Comissão',
    minWidth: 70,
    align: 'right',
    format: (value) => NumberFunctions.toPercentage(value),
  },
  {
    id: 'affiliatedMonthlyFeesPaid',
    label: 'Vendas',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toString(),
  },
  {
    id: 'affiliatedCommissionValue',
    label: 'Comissões',
    minWidth: 70,
    align: 'right',
    format: (value) => NumberFunctions.formatMoneyDefault(value),
  },
  {
    id: 'enabledCommission',
    label: 'Automático',
    minWidth: 80,
  },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 80,
  },
]

export const ActivAffiliateds = () => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [success, setSuccess] = React.useState(false)
  const [errorUpdate, setErrorUpdate] = React.useState('')
  const [sending, setSending] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [current, setCurrent] = React.useState<TAffiliated | undefined>(
    undefined
  )

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useAffiliateds({
    page,
    rows: rowsPerPage,
    search,
    status: TAffiliatedStatus.Accepted,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleStatusLabel = (status: TAffiliatedStatus) => {
    switch (status) {
      case TAffiliatedStatus.Accepted:
        return 'Aceito'
      case TAffiliatedStatus.Pending:
        return 'Pendente'
      case TAffiliatedStatus.Refused:
        return 'Recusado'
      case TAffiliatedStatus.Blocked:
        return 'Bloqueado'
      default:
        return ''
    }
  }

  const handleBlockedAffiliated = async (id?: string) => {
    if (id === '' || id === undefined) return
    setSending(true)
    setSuccess(false)
    setErrorUpdate('')

    try {
      var response = await AffiliationController.updateAffiliatedStatus({
        id,
        status: TAffiliatedStatus.Blocked,
      })
      if (!response.success) {
        setErrorUpdate(response.error)
      } else {
        setSuccess(true)
        await execute()
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeItem = (item: TAffiliated) => {
    setCurrent({ ...item })
    setOpen(true)
  }

  const handleCloseItem = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleSaveItem = async () => {
    if (current) {
      setSending(true)
      try {
        const response = await AffiliationController.updateCommission({
          data: {
            affiliatedId: current.id!,
            commissionPercentage: NumberFunctions.toNumber(
              current.commissionPercentage
            ),
            globalCommissionPercentage: NumberFunctions.toNumber(
              current.globalCommissionPercentage
            ),
          },
        })

        if (!response.success) {
          setErrorUpdate(response.error)
          return
        }

        setSuccess(true)
        execute()
        handleCloseItem()
      } finally {
        setSending(false)
      }
    }
  }

  const handleChangeEnabledTargetCommission = async (
    id: string,
    enabledCommission: boolean
  ) => {
    setSending(true)
    try {
      const response = await AffiliationController.enabledCommission({
        data: {
          affiliatedId: id,
          isEnabled: !enabledCommission,
        },
      })

      if (!response.success) {
        setErrorUpdate(response.error)
        return
      }

      setSuccess(true)
      execute()
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box>
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <InputSearch
        name="Filtrar por nome, email ou produto"
        setSearch={setSearch}
        execute={execute}
      />

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (column.id === 'enabledCommission') {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignContent="center"
                          alignItems="center"
                        >
                          {column.label}
                          <HtmlTooltip
                            title={
                              <Typography>
                                Ao desativar essa opção o afiliado não terá suas
                                comissões alteradas por regras automáticas.
                              </Typography>
                            }
                          >
                            <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                          </HtmlTooltip>
                        </Stack>
                      </TableCell>
                    )
                  }
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    {columns.map((column) => {
                      if (column.id === 'action') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="contained"
                              size="small"
                              startIcon={<BlockIcon />}
                              color="warning"
                              onClick={() => handleBlockedAffiliated(row.id)}
                            >
                              Bloquear
                            </Button>
                          </TableCell>
                        )
                      }
                      if (column.id === 'enabledCommission') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="text"
                              size="small"
                              startIcon={
                                row.enableTargetCommission === true ? (
                                  <DesactiveIcon />
                                ) : (
                                  <ActiveIcon />
                                )
                              }
                              color={
                                row.enableTargetCommission === true
                                  ? 'error'
                                  : 'primary'
                              }
                              onClick={() =>
                                handleChangeEnabledTargetCommission(
                                  row.id!,
                                  row.enableTargetCommission || false
                                )
                              }
                            >
                              {row.enableTargetCommission === true
                                ? 'Desativar'
                                : 'Ativar'}
                            </Button>
                          </TableCell>
                        )
                      }
                      if (column.id === 'status') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleChangeItem(row)}
                          >
                            <Chip
                              size="small"
                              color="success"
                              label={handleStatusLabel(row.status)}
                            />
                          </TableCell>
                        )
                      }
                      const value = row[column.id]
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => handleChangeItem(row)}
                        >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Box sx={{ height: 80 }} />

      <LoadingBackdrop open={loading || sending} />

      <SwipeableDrawerRightDialog
        title="Informações do afiliado"
        subtitle={current?.tenantName}
        open={open}
        setOpen={setOpen}
        onClose={handleCloseItem}
        /*actions={
                    <Stack direction="row" spacing={1} sx={{ p: 1 }} justifyContent="end">
                        <Button variant="outlined" color="error" disabled={sending} onClick={handleCloseItem}>
                            Cancelar
                        </Button>
                        <Button variant="contained" disabled={sending} onClick={handleSaveItem}>
                            Salvar
                        </Button>
                    </Stack>
                }*/
        buttons={[
          { title: 'Cancelar', onClick: handleCloseItem, type: 'negative' },
          { title: 'Salvar', onClick: handleSaveItem, type: 'positive' },
        ]}
        buttonsDisabled={sending}
      >
        <Stack direction="column" spacing={2} sx={{ py: 1 }}>
          <Divider />
          <ListItemDetail
            title="Comissão Produto"
            value={NumberFunctions.toPercentage(
              current?.productCommissionPercentage
            )}
            viewDivider={false}
            py={1}
            widthTitlePercentage={50}
          />
          <ListItemDetail
            title="Comissão Global Produto"
            value={NumberFunctions.toPercentage(
              current?.productGlobalCommissionPercentage
            )}
            py={1}
            widthTitlePercentage={50}
          />
          <ListItemDetail
            title="Comissão (%)"
            value={
              <TextField
                fullWidth
                size="small"
                type="text"
                value={maskPercentage(current?.commissionPercentage)}
                onChange={(e) => {
                  const commissionPercentage = maskPercentage(e.target.value)
                    .replaceAll('.', '')
                    .replaceAll(',', '.')
                  setCurrent({
                    ...current!,
                    commissionPercentage: Number(commissionPercentage),
                  })
                }}
              />
            }
            viewDivider={false}
            py={1}
            widthTitlePercentage={50}
          />
          <ListItemDetail
            title="Comissão Global (%)"
            value={
              <TextField
                fullWidth
                size="small"
                type="text"
                value={maskPercentage(current?.globalCommissionPercentage)}
                onChange={(e) => {
                  const globalCommissionPercentage = maskPercentage(
                    e.target.value
                  )
                    .replaceAll('.', '')
                    .replaceAll(',', '.')
                  setCurrent({
                    ...current!,
                    globalCommissionPercentage: globalCommissionPercentage,
                  })
                }}
              />
            }
            viewDivider={false}
            py={1}
            widthTitlePercentage={50}
          />
        </Stack>
      </SwipeableDrawerRightDialog>

      <ErrorSnackbar
        open={errorUpdate !== ''}
        onClose={() => setErrorUpdate('')}
      >
        {errorUpdate}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
