import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FilledInput,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CopyIcon from '@mui/icons-material/ContentCopy'
import SendIcon from '@mui/icons-material/Send'
import InfoIcon from '@mui/icons-material/Info'
import { TWebhook, TWebhookEventEnum } from '../../../core/types/Webhook'
import { TProductSimple } from '../../../core/types/Product'
import { useCallback, useEffect, useState } from 'react'
import ContentDialog from '../../../components/Dialogs/ContentDialog'
import WebhookFunction from '../../../core/functions/webhooks'
import { testWebhookJSON } from '../../../core/data/webhookTest'
import { ITenant } from '../../../core/types/Tenant'
import Hosts from '../../../core/functions/hosts'
import WebhookExamples from './Examples/WebhookExamples'
import WebhookEvents from './WebhookEvents'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import WebhookController from '../../../core/controllers/WebhookController'
import SwitchRoundSmall from '../../../components/Switch/SwitchRoundSmall'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'
import { webhookSendAffiliatedExample } from '../../../core/data/webhookSendAffiliatedExample'

export interface IWebhookSwipeableDrawerProp {
  open: boolean
  setOpen: (value: boolean) => void
  onClose: () => void
  webhook: TWebhook
  setWebhook: (webhook: TWebhook) => void
  onSave: () => void
  sending: boolean
  products: TProductSimple[]
  setError: (error: string) => void
  setLoading: (loading: boolean) => void
  tenant: ITenant | null
  setCopied: (value: boolean) => void
}

interface ITestWebhook {
  status: number
  code: number
}

const WebhookSwipeableDrawer = ({
  open,
  setOpen,
  onClose,
  webhook,
  setWebhook,
  onSave,
  sending,
  products,
  setError,
  setLoading,
  tenant,
  setCopied,
}: IWebhookSwipeableDrawerProp) => {
  const [options, setOptions] = useState<TProductSimple[]>([])
  const [testResults, setTestResults] = useState<ITestWebhook[]>([])

  const [openTest, setOpenTest] = useState(false)
  const [openResult, setOpenResult] = useState(false)
  const [openExample, setOpenExample] = useState(false)
  const [testing, setTesting] = useState(false)

  const [webhookTest, setWebhookTest] = useState<TWebhook>({ ...webhook })

  const isNew = !webhook.id || webhook.id === ''

  useEffect(() => {
    if (options.length <= 0) {
      options.push({
        id: '',
        name: 'Todos',
      })
    }

    if (options.length <= 1) {
      products.map((product) => {
        options.push(product)
      })
    }

    if (options.length > 0) {
      setOptions(options)
    }
  }, [products])

  useEffect(() => {
    if (webhook) {
      setWebhookTest({ ...webhook })
    }
  }, [webhook, setWebhookTest])

  const setWebhookEvents = (events: number[] | undefined) => {
    webhook.events = events
    setWebhook({ ...webhook })
  }

  const sendWebhookPurchaseTest = useCallback(
    async (event: TWebhookEventEnum) => {
      const status = WebhookFunction.eventType(event)
      const statusEnum = WebhookFunction.getEventStatusEnum(event)

      const json = testWebhookJSON
      json.status = status
      json.eventTypeIdentifier = `${status}.${WebhookFunction.eventStatus(statusEnum)}`

      if (webhookTest.productId) {
        json.items[0].id = webhookTest.productId || ''
        json.items[0].name = webhookTest.productName || ''
      }
      try {
        const response = await WebhookController.test({
          data: json,
          url: webhookTest.url,
          token: tenant?.token ?? '',
        })
        return {
          status: event,
          code: response.data,
        }
      } catch (e: any) {
        return {
          status: event,
          code: 0,
        }
      }
    },
    [webhookTest]
  )

  const sendWebhookAffiliatedTest = useCallback(
    async (event: TWebhookEventEnum) => {
      const json = webhookSendAffiliatedExample
      if (webhookTest.productId) {
        json.product.id = webhookTest.productId || ''
        json.product.name = webhookTest.productName || ''
      }
      try {
        const response = await WebhookController.testEvent({
          data: JSON.stringify(json),
          url: webhookTest.url,
          token: tenant?.token ?? '',
        })
        return {
          status: event,
          code: response.data,
        }
      } catch (e: any) {
        return {
          status: event,
          code: 0,
        }
      }
    },
    [webhookTest]
  )

  const sendWebhookTest = useCallback(
    async (event: TWebhookEventEnum) => {
      if (event === TWebhookEventEnum.Affiliation)
        return await sendWebhookAffiliatedTest(event)
      return await sendWebhookPurchaseTest(event)
    },
    [sendWebhookAffiliatedTest, sendWebhookPurchaseTest]
  )

  const handlePositiveTest = async () => {
    if (webhookTest.url.trim() === '') {
      setError('URL não preenchida')
      return false
    }
    if (!webhookTest?.events || webhookTest.events.length <= 0) {
      setError('Selecione um evento')
      return false
    }

    setTesting(true)
    try {
      const responses: ITestWebhook[] = []

      if (webhookTest.events) {
        await Promise.all(
          webhookTest.events?.map(async (event) => {
            const response = await sendWebhookTest(event)
            responses.push(response)
          })
        )
      }

      setTestResults(responses)
      setOpenResult(true)
    } finally {
      setTesting(false)
    }

    return true
  }

  const SendStatus = ({ code }: { code?: string }) => {
    const status = Hosts.returnCodeString(code)
    const returnCode = code || ''
    return (
      <Chip
        size="small"
        label={status}
        color={
          returnCode.startsWith('1')
            ? 'info'
            : returnCode.startsWith('2')
              ? 'success'
              : returnCode.startsWith('3')
                ? 'warning'
                : returnCode.startsWith('4') || returnCode.startsWith('5')
                  ? 'error'
                  : 'default'
        }
      />
    )
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={onClose}
      setOpen={setOpen}
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Box>{`${isNew ? 'Criar' : 'Editar'} Webhook`}</Box>
          <Button
            size="small"
            color="info"
            startIcon={
              <img src="/assets/icons/infos/infoBlack.svg" alt="info" />
            }
            onClick={() => setOpenExample(true)}
            sx={{ textTransform: 'none' }}
          >
            <Typography
              color="#5D5E61"
              fontSize="14px"
              lineHeight="24px"
              fontWeight={600}
            >
              Exemplos
            </Typography>
          </Button>
        </Stack>
      }
      buttons={[
        { title: 'Cancelar', onClick: onClose, type: 'negative' },
        { title: 'Salvar', onClick: onSave, type: 'positive' },
      ]}
      buttonsDisabled={sending}
    >
      <>
        <Stack
          direction="column"
          spacing={2}
          sx={{ width: '100%', px: 1, py: 1 }}
        >
          <Stack direction="column">
            <TextField
              fullWidth
              type="text"
              size="small"
              label="Nome"
              value={webhook.identifier}
              onChange={(e) =>
                setWebhook({ ...webhook, identifier: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
          <Stack direction="column">
            <TextField
              fullWidth
              type="text"
              size="small"
              label="URL do Webhook"
              placeholder="https://example.com/api/pbd/?u=5df7741ff..."
              value={webhook.url}
              onChange={(e) => setWebhook({ ...webhook, url: e.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            alignContent="center"
            alignItems="center"
          >
            <Button
              size="small"
              variant="text"
              startIcon={
                <img src="/assets/icons/actions/play.svg" alt="play" />
              }
              onClick={() => setOpenTest(true)}
              sx={{ textTransform: 'none' }}
            >
              <Typography
                color="#1C52BD"
                fontSize="14px"
                lineHeight="24px"
                fontWeight={600}
              >
                Testar Webhook
              </Typography>
            </Button>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel
                htmlFor="filled-adornment-token"
                shrink={true}
                sx={{ backgroundColor: 'white', px: 1 }}
              >
                Token
              </InputLabel>
              <OutlinedInput
                fullWidth
                id="filled-adornment-token"
                type="text"
                size="small"
                placeholder=""
                value={tenant?.token}
                endAdornment={
                  <InputAdornment position="end" sx={{ cursor: 'default' }}>
                    <Stack direction="row" spacing={0.5}>
                      <IconButton
                        aria-label="toggle search"
                        size="small"
                        onClick={async () => {
                          if (tenant) {
                            await Hosts.copyTextToClipboard(tenant.token)
                            setCopied(true)
                          }
                        }}
                        edge="end"
                      >
                        <img
                          src="/assets/icons/copy/copyGreen.svg"
                          alt="copy"
                        />
                      </IconButton>
                      <HtmlTooltip title="Esse Token será enviado no Header da requisição no campo Api-Token para validação da autenticidade do envio.">
                        <img src="/assets/icons/infos/info.svg" alt="info" />
                      </HtmlTooltip>
                    </Stack>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel
                id="number-attempts-label"
                shrink
                sx={{ backgroundColor: 'white' }}
              >
                Quantidade de tentativas
              </InputLabel>
              <Select
                displayEmpty
                variant="outlined"
                labelId="number-attempts-label"
                label="Quantidade de tentativas"
                value={webhook.numberAttempts}
                defaultValue={webhook.numberAttempts}
                onChange={(e) =>
                  setWebhook({
                    ...webhook,
                    numberAttempts: Number(e.target.value),
                  })
                }
              >
                {[1, 2, 3, 4, 5].map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="column">
            <Autocomplete
              disablePortal
              size="small"
              options={options}
              getOptionLabel={(option) => option.name}
              onChange={(event, option) => {
                if (option && option !== null) {
                  setWebhook({
                    ...webhook,
                    productId: option.id,
                    productName: option.name,
                    allProducts: !option.id || option.id === '',
                  })
                }
              }}
              value={{
                id: webhook.productId || '',
                name:
                  (webhook.productName || '') === ''
                    ? 'Todos'
                    : webhook.productName || '',
              }}
              renderInput={(params) => (
                <TextField {...params} label="Produto" />
              )}
            />
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <FormControlLabel
              control={
                <SwitchRoundSmall
                  sx={{ m: 1 }}
                  checked={webhook.isSendToOrderbumps}
                  onChange={(e) => {
                    setWebhook({
                      ...webhook,
                      isSendToOrderbumps: e.target.checked,
                    })
                  }}
                />
              }
              label="Enviar para os orderbumps"
            />
            <HtmlTooltip title="Quando essa opção estiver habilitada será feito o envio por produto. Ex.: Comprando o produto e mais um orderbump, será enviado os dois eventos caso tenha configurações para o mesmo.">
              <img src="/assets/icons/infos/info.svg" alt="info" />
            </HtmlTooltip>
          </Stack>
          <Stack direction="column">
            <WebhookEvents
              events={webhook.events}
              setEvents={setWebhookEvents}
            />
          </Stack>
        </Stack>

        <ContentDialog
          open={openTest}
          title="Testar Webhook"
          onClose={() => {
            setOpenTest(false)
          }}
          labelPrositive="Testar Webhook"
          onPositive={handlePositiveTest}
        >
          {testing && (
            <Stack sx={{ p: 5 }} alignContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          )}
          {!testing && (
            <Stack>
              <Stack direction="column" sx={{ pl: 3, pt: 2, pr: 3, pb: 1 }}>
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  label="URL do Webhook"
                  placeholder="https://example.com/api/pbd/?u=5df7741ff..."
                  value={webhookTest.url}
                  onChange={(e) =>
                    setWebhookTest({ ...webhookTest, url: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
              <Stack direction="column" sx={{ pl: 3, pt: 1, pr: 3, pb: 1 }}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel htmlFor="filled-adornment-token" shrink={true}>
                    Token
                  </InputLabel>
                  <FilledInput
                    fullWidth
                    id="filled-adornment-token"
                    type="text"
                    size="small"
                    placeholder=""
                    value={tenant?.token}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle search"
                          onClick={async () => {
                            if (tenant) {
                              await Hosts.copyTextToClipboard(tenant.token)
                              setCopied(true)
                            }
                          }}
                          edge="end"
                        >
                          <CopyIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>
              <Stack direction="column" sx={{ pl: 3, pt: 1, pr: 3, pb: 1 }}>
                <Autocomplete
                  disablePortal
                  size="small"
                  options={options}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, option) => {
                    if (option && option !== null) {
                      setWebhookTest({
                        ...webhookTest,
                        productId: option.id,
                        productName: option.name,
                        allProducts: !option.id || option.id === '',
                      })
                    }
                  }}
                  value={{
                    id: webhookTest.productId || '',
                    name:
                      (webhookTest.productName || '') === ''
                        ? 'Todos'
                        : webhookTest.productName || '',
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Produto" />
                  )}
                />
              </Stack>
              <Stack direction="column" sx={{ pl: 3, pt: 1, pr: 3, pb: 1 }}>
                <WebhookEvents
                  events={webhookTest.events}
                  setEvents={(events) => {
                    if (events) {
                      setWebhookTest({ ...webhookTest, events: [...events] })
                    } else {
                      setWebhookTest({ ...webhookTest, events: [] })
                    }
                  }}
                />
              </Stack>
              <Stack direction="column" sx={{ pl: 3, pt: 1, pr: 3, pb: 1 }}>
                <Alert severity="warning">
                  Será enviado um webhook de teste com dados fictícios.
                </Alert>
              </Stack>
            </Stack>
          )}
        </ContentDialog>

        <ContentDialog
          open={openResult}
          title="Resultado"
          onClose={() => {
            setTestResults([])
            setOpenResult(false)
          }}
        >
          <Stack direction="column" spacing={1} sx={{ p: 2 }}>
            {testResults?.map((result) => (
              <Stack
                direction="row"
                spacing={2}
                alignContent="center"
                alignItems="center"
              >
                <SendStatus code={result.code.toString()} />
                <Typography fontWeight={500}>
                  {WebhookFunction.statusString(result.status)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </ContentDialog>

        <WebhookExamples
          open={openExample}
          setOpen={setOpenExample}
          setCopied={setCopied}
        />
      </>
    </SwipeableDrawerRightDialog>
  )
}

export default WebhookSwipeableDrawer
