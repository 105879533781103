import { Box, Button, Chip, IconButton, Stack, Typography } from '@mui/material'
import { IDataTableColumn } from '../../../components/Tables/DataTable'
import { checkoutOrderColumnsApproved } from './CheckoutOrderColumns/CheckoutOrderColumnsApproved'
import { checkoutOrderColumnsOpened } from './CheckoutOrderColumns/CheckoutOrderColumnsOpened'
import Hosts from '../../../core/functions/hosts'
import Convertions from '../../../core/functions/convertions'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import MethodTypeSmallIcon from '../../../components/Checkout/Icons/MethodTypeSmallIcon'
import { CheckoutOrderColumnsAbandoned } from './CheckoutOrderColumns/CheckoutOrderColumnsAbandoned'
import OrderFunctions from '../../../core/functions/OrderFunctions'
import {
  TCheckoutOrder,
  TSubscription,
} from '../../../core/types/CheckoutOrder'
import { TLostSale } from '../../../core/types/LostSale'
import GetAppIcon from '@mui/icons-material/GetApp'
import OrderController from '../../../core/controllers/OrderController'

export const CHECKOUT_TAB_INDEX_APPROVED = 0
export const CHECKOUT_TAB_INDEX_ALL = 1
export const CHECKOUT_TAB_INDEX_OPENED = 2
export const CHECKOUT_TAB_INDEX_ABANDONED = 3

export const checkoutOrderStatusColorMap: Record<
  string,
  { backgroundColor: string; color: string }
> = {
  pending: {
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
  failed: {
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  paid: {
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  default: {
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
  warning: {
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

interface ICheckoutOrderDataTableColumnsProps {
  setCopied: (value: boolean) => void
}

interface ICheckoutOrderOpenedDataTableColumnsProps {
  setCopied: (value: boolean) => void
  onDownloadBoleto: (value: string) => Promise<void>
  onCopyPix: (value: string) => Promise<void>
  execute: () => void
}

export const CheckoutOrderDataTableColumns = ({
  setCopied,
  onDownloadBoleto,
  onCopyPix,
  execute,
}: ICheckoutOrderOpenedDataTableColumnsProps) => {
  const columns = {
    ['approved']: checkoutOrderColumnsApproved({ execute }),
    ['all']: checkoutOrderColumnsApproved({ execute }),
    ['opened']: checkoutOrderColumnsOpened(
      setCopied,
      onDownloadBoleto,
      onCopyPix,
      execute
    ),
    ['abandoned']: CheckoutOrderColumnsAbandoned(setCopied, execute),
  }

  return columns
}

export const checkoutOrderStatusColumn: IDataTableColumn<TCheckoutOrder> = {
  id: 'status',
  label: 'Status',
  render(id, value, row) {
    const colorConfig =
      checkoutOrderStatusColorMap[value] || checkoutOrderStatusColorMap.default
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          <Chip
            label={Convertions.checkoutOrderStatusToString(value)}
            size="small"
            sx={{
              backgroundColor: colorConfig.backgroundColor,
              color: colorConfig.color,
            }}
          />
        </Box>
        {row.inAssurance === true && (
          <HtmlTooltip
            title={<Typography>Venda em período de garantia</Typography>}
          >
            <img
              src="/assets/icons/lock/lock.svg"
              alt="icon-sell-type"
              style={{
                backgroundColor: '#FBF3E2',
                width: '24px',
                height: '24px',
              }}
            />
          </HtmlTooltip>
        )}
      </Stack>
    )
  },
}

export const checkoutOrderStatusTimeColumn: IDataTableColumn<
  TCheckoutOrder | TLostSale
> = {
  id: 'status',
  label: 'Status',
  render(id, value, row) {
    const isLostSale = !(row as TCheckoutOrder).valueProductType
    const labelDays = `${!isLostSale ? 'Aberto' : ''} ${OrderFunctions.getDiffDaysDescription(row.createdDate)}`

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          <Chip
            label={
              <Typography fontSize="12px" lineHeight="16px" fontWeight={500}>
                {labelDays}
              </Typography>
            }
            size="small"
            sx={{
              backgroundColor: '#FBF3E2',
              color: '#887100',
            }}
          />
        </Box>
      </Stack>
    )
  },
}

export const checkoutOrderPaymentMethodColumn: IDataTableColumn<TCheckoutOrder> =
  {
    id: 'paymentyMethod',
    label: 'Forma de Pagamento',
    render(id, value, row) {
      return (
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <MethodTypeSmallIcon methodType={value} />
          <Typography
            fontSize="14px"
            lineHeight="18px"
            color="#1C52BD"
            fontWeight={500}
          >
            {Convertions.paymentMethodToString(value)}
          </Typography>
        </Stack>
      )
    },
  }

export const checkoutOrderPaymentAndCommissionColumns: IDataTableColumn<TCheckoutOrder>[] =
  [
    checkoutOrderPaymentMethodColumn,
    {
      id: 'value',
      label: 'Comissão',
      align: 'right',
      sx: { fontWeight: 500 },
      format: (value) => NumberFunctions.formatMoneyDefault(value),
    },
  ]

export const CheckoutOrderLinkColumn = ({
  setCopied,
  onDownloadBoleto,
  onCopyPix,
}: ICheckoutOrderOpenedDataTableColumnsProps): IDataTableColumn<TCheckoutOrder> => ({
  id: 'codeId',
  label: 'Checkout',
  minWidth: 60,
  render(id, value, row) {
    const pathAffiliated =
      row.affiliatedCode && row.affiliatedCode !== ''
        ? `?afid=${row.affiliatedCode}`
        : ''
    const url = `${process.env.REACT_APP_PAY_HOST}/c/${value}${pathAffiliated}`
    return (
      <Stack direction="row" spacing={1}>
        <HtmlTooltip title="Esse link trás todas as informações do usuário no checkout, aumentando a taxa de recuperação.">
          <IconButton
            onClick={async (event) => {
              event.stopPropagation()
              await Hosts.copyTextToClipboard(url)
              setCopied(true)
            }}
          >
            <img src="/assets/icons/actions/link.svg" alt="link" />
          </IconButton>
        </HtmlTooltip>
        {row.paymentyMethod === 'boleto' ? (
          <Button
            variant="text"
            size="small"
            startIcon={<GetAppIcon />}
            sx={{ textTransform: 'none' }}
            onClick={(event) => {
              event.stopPropagation()
              onDownloadBoleto(row.id ?? '')
            }}
          >
            <Typography
              fontSize="14px"
              lineHeight="18px"
              color="#1C52BD"
              fontWeight={500}
            >
              Baixar Boleto
            </Typography>
          </Button>
        ) : row.paymentyMethod === 'pix' ? (
          <Button
            variant="text"
            size="small"
            startIcon={<img src="/assets/icons/copy/copy.svg" alt="copy" />}
            sx={{ textTransform: 'none' }}
            onClick={(event) => {
              event.stopPropagation()
              onCopyPix(row.id ?? '')
            }}
          >
            <Typography
              fontSize="14px"
              lineHeight="18px"
              color="#1C52BD"
              fontWeight={500}
            >
              Copiar PIX
            </Typography>
          </Button>
        ) : null}
      </Stack>
    )
  },
})

export const CheckoutOrderCustomerWhatsAppColumn = ({
  execute,
}: {
  execute: () => void
}): IDataTableColumn<TCheckoutOrder> => {
  return {
    id: 'phone',
    label: 'Ação',
    minWidth: 60,
    render(id, value, row) {
      let text = `Olá ${row.name},\nObrigado por comprar ${row.productName}`

      if (row.status !== 'paid') {
        const pathAffiliated =
          row.affiliatedCode && row.affiliatedCode !== ''
            ? `?afid=${row.affiliatedCode}`
            : ''
        const url = `${process.env.REACT_APP_PAY_HOST}/c/${row.codeId}${pathAffiliated}`
        text = url
      }

      return (
        <IconButton
          onClick={async (event) => {
            event.stopPropagation()
            Hosts.openNewBlank(
              `https://wa.me/55${value}?text=${encodeURIComponent(text)}`
            )

            if (!row.reachedOutForAbandonedOrder) {
              const resp = await OrderController.reachedOutForAbandonedOrder({
                orderId: row.id ?? '',
              })
              if (resp.success) {
                execute()
              }
            }
          }}
        >
          <img src="/assets/icons/actions/whatsapp.svg" alt="link" />
        </IconButton>
      )
    },
  }
}
