import { Box } from '@mui/material'
import {
  TSubscription,
  TSubscriptionDetails,
} from '../../../../../core/types/CheckoutOrder'
import { maskCpfCnpjNumber, maskPhoneNumber } from '../../../../../masks/masks'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'

export interface IOrderDetailCustomerProp {
  checkoutOrder?: TSubscription
  details?: TSubscriptionDetails
}

const SubscriptionDetailCustomer = ({
  checkoutOrder,
  details,
}: IOrderDetailCustomerProp) => {
  return (
    <Box>
      <ListItemDefault title="Nome" value={checkoutOrder?.name} />
      <ListItemDefault title="E-mail" value={checkoutOrder?.email} />
      <ListItemDefault
        title="Celular"
        value={maskPhoneNumber(details?.phone)}
      />
      <ListItemDefault title="CPF" value={maskCpfCnpjNumber(details?.phone)} />
    </Box>
  )
}

export default SubscriptionDetailCustomer
