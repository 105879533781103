import {
  Box,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import ListItemData from '../../../../../components/ListItem/ListItemData'
import { TCoproducerStatus } from '../../../../../core/types/Coproducer'
import {
  addDays,
  dateTimeString,
  diffDays,
} from '../../../../../core/functions/dateTime'
import Convertions from '../../../../../core/functions/convertions'
import MyCoproducerFunctions from '../../../MyCoproducers/MyCoproducerFunctions'
import { IProductCoproducerItem } from './ProductCoproducerItem'

const ProductCoproducerItemWeb = ({
  coproducer,
  index,
  isAdmin,
  onEdit,
  onCancel,
}: IProductCoproducerItem) => {
  return (
    <ListItemData key={`ProductCoproducerItemWeb${index}`}>
      <Stack
        key={coproducer.id}
        direction="row"
        spacing={1}
        alignContent="center"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Box sx={{ width: '18%' }}>
          <Typography color="#38393B" fontSize="14px" lineHeight="18px">
            {dateTimeString(coproducer.createdDate)}
          </Typography>
        </Box>
        <Stack direction="column" sx={{ width: '22%' }}>
          <Typography color="#38393B" fontSize="14px" lineHeight="18px">
            {coproducer.tenantName}
          </Typography>
          <Typography variant="caption" noWrap>
            {coproducer.email}
          </Typography>
        </Stack>
        <Stack direction="column" sx={{ width: '16%' }}>
          <Typography color="#38393B" fontSize="14px" lineHeight="18px">
            {coproducer.commissionPercentage}%
          </Typography>
          <Typography variant="caption">De todas as vendas</Typography>
        </Stack>
        <Box sx={{ width: '14%' }}>
          <Typography color="#38393B" fontSize="14px" lineHeight="18px">
            {coproducer.contractDuration === 999
              ? 'Eterno'
              : dateTimeString(
                  addDays(
                    coproducer.contractDuration || 0,
                    coproducer.createdDate
                  )
                )}
          </Typography>
        </Box>
        <Box sx={{ width: '16%' }}>
          {coproducer.status !== TCoproducerStatus.Canceled &&
            coproducer.contractDuration !== 999 &&
            diffDays(
              new Date(),
              addDays(coproducer.contractDuration ?? 0, coproducer.createdDate)
            ) > 0 && <Chip size="small" label="Expirado" color="warning" />}
          {(coproducer.status === TCoproducerStatus.Canceled ||
            coproducer.contractDuration === 999 ||
            diffDays(
              new Date(),
              addDays(coproducer.contractDuration ?? 0, coproducer.createdDate)
            ) <= 0) && (
            <Chip
              size="small"
              label={Convertions.coproducerStatusToString(coproducer.status)}
              sx={MyCoproducerFunctions.getStatusChipStyles(coproducer.status)}
            />
          )}
        </Box>
        {coproducer.status !== TCoproducerStatus.Canceled && (
          <Stack direction="row" sx={{ width: '8%' }}>
            {!isAdmin && (
              <>
                <Tooltip title="Editar coprodutor">
                  <IconButton
                    size="small"
                    onClick={() => onEdit(coproducer, index)}
                  >
                    <img src="/assets/icons/actions/edit.svg" alt="edit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancelar coprodutor">
                  <IconButton
                    size="small"
                    onClick={() => onCancel(coproducer, index)}
                  >
                    <img src="/assets/icons/actions/cancel.svg" alt="delete" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        )}
        {coproducer.status === TCoproducerStatus.Canceled && (
          <Stack direction="row" sx={{ width: '8%' }} />
        )}
      </Stack>
    </ListItemData>
  )
}

export default ProductCoproducerItemWeb
