import React from 'react'
import Alert from '@mui/material/Alert'
import { EIdentityValidation } from '../../../core/types/Tenant'

interface IdentityAlertProps {
  status?: EIdentityValidation
  withdrawlsEnabled?: boolean | null
}

const IdentityAlert: React.FC<IdentityAlertProps> = ({
  status,
  withdrawlsEnabled,
}) => {
  const renderAlert = () => {
    switch (status) {
      case EIdentityValidation.Unverified:
        return <Alert severity="info">Identidade não verificada</Alert>
      case EIdentityValidation.Waiting:
        return (
          <Alert severity="warning">Aguardando verificação de identidade</Alert>
        )
      case EIdentityValidation.Pending:
        return (
          <Alert severity="warning">Verificação de identidade pendente</Alert>
        )
      case EIdentityValidation.Denied:
        return <Alert severity="error">Verificação de identidade negada</Alert>
    }

    if (withdrawlsEnabled === false) {
      return (
        <Alert severity="info">
          Falta pouco, sua conta foi escolhida para passar por uma análise
          manual do time de segurança e logo seu saque estará liberado. Para
          dúvidas, entre em contato com o nosso time de suporte.
        </Alert>
      )
    }

    return null
  }

  return <>{renderAlert()}</>
}

export default IdentityAlert
