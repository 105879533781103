import {
  SubscriptionDateFilterType,
  SubscriptionStatusEnum,
  TCheckoutOrderReport,
  TCheckoutOrderReportTab,
} from './Order'
import { TProductFrequency, TProductTypePayment } from './Product'
import { TProductVoucherValue } from './Voucher'

export enum TCheckoutValueType {
  Plataform = 1,
  Producer = 2,
  CoProducer = 3,
  Affiliated = 4,
  GlobalAffiliated = 5,
  Unknown = 6,
}

export enum TValueProductTypeEnum {
  Main = 1,
  Orderbump,
  Upsell,
}

export enum TItemTypeSaleEnum {
  Order = 1,
  Subscription,
}

export enum TCheckoutOrderDetailsError {
  Paid = 1,
  InsufficientBalance = 2,
  SuspiciusTransaction = 3,
  RefusedByBank = 4,
  RefusedByBank2 = 5,
  RefusedByBank3 = 6,
  RefusedByBank4 = 7,
  TransactionDeclinedDueToSecurity = 8,
  IncorrectCardDetails = 9,
  ExpiredCardOrIncorrectDate = 10,
  TransactionDeclined = 11,
  IncorrectCardDetails2 = 12,
  CardBlockedCanceledOrInactive = 13,
  RefusedByBank5 = 14,
  RefusedByBank6 = 15,
  TransactionDeclined2 = 16,
  BankErrorOrCardoperatorSystem = 17,
  RejectedDueToManyAttempts = 18,
  TransactionDeclined3 = 19,
  TransactionDeclined4 = 20,
  TransactionDeclined5 = 21,
  TransactionDeclined6 = 22,
  TransactionDeclined7 = 23,
}

export type TCheckoutOrder = {
  id?: string
  codeId?: string
  name: string
  email: string
  productPriceId?: string
  productName: string
  status: string
  paymentyMethod: string
  value: number
  createdDate: Date
  updatedDate: Date

  monthlyFeesPaid?: number
  commissionValue?: number
  totalAmountPaid?: number
  frequency?: TProductFrequency
  valueType?: TCheckoutValueType
  sellerName?: string
  mainProduct?: boolean
  inAssurance?: boolean

  checkoutModelName?: string | null
  totalOrderbumpsSold?: number | null
  productTransaction?: number | null
  utmCampaign?: string | null
  offerName?: string | null

  valueProductType: TValueProductTypeEnum
  itemTypeSale: TItemTypeSaleEnum

  phone?: string | null
  affiliatedCode?: string | null

  reachedOutForAbandonedOrder?: boolean | null
}

export type TSubscription = {
  id?: string
  codeId?: string
  name: string
  email: string
  phone?: string | null

  productName: string
  offerName: string
  status: SubscriptionStatusEnum
  paymentyMethod: number
  value: number
  commissionValue?: number
  sellerName?: string | null

  createdDate: Date

  chargeNumbers?: number
  frequency?: TProductFrequency
}

export type TCheckoutOrderPagination = {
  items: TCheckoutOrder[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}

export type TSubscriptionPagination = {
  items: TSubscription[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}

export type TCheckoutOrderRecipient = {
  name: string
  value: number
}

export type TCheckoutOrderSubscriptionsPaidItem = {
  id: string
  chargeDate: Date
  value: number
}

export type TCheckoutOrderSubscriptionsPaid = {
  id: string
  code: string
  items: TCheckoutOrderSubscriptionsPaidItem[]
}

export type TCheckoutOrderError = {
  createdDate: Date
  type: TCheckoutOrderDetailsError
}

export type TCheckoutOrderDetails = {
  tenantId: string
  codeId: string
  value: number
  basePrice: number
  rates: number
  installments: number
  customerPhone: string
  customerDocument: string
  recipients?: TCheckoutOrderRecipient[]
  subscriptionsChargesPaid?: TCheckoutOrderSubscriptionsPaid

  src?: string | null
  sck?: string | null
  utm_campaign?: string | null
  utm_source?: string | null
  utm_medium?: string | null
  utm_content?: string | null
  utm_term?: string | null

  voucherIdentifier?: string | null
  voucherValue?: number | null
  voucherType?: TProductVoucherValue | null

  isSubscriptionPaymentCancel?: boolean
  isBlocked?: boolean
  reasonBlocked?: string | null

  errors?: TCheckoutOrderError[]

  paymentDate?: Date | null
}

export type TSubscriptionTransaction = {
  referenceNumber: number
  subscriptionId: string
  serviceId: string
  serviceCode: string
  status: string
  createdDate: Date
  attemptNumbers: number
  lastTransaction: {
    subscriptionPaymentId: string
    orderChargeId: string
    status: number
    chargeData: string | null
    attemptNumber: number
  }
}

export type TSubscriptionOrderData = {
  id: string
  codeId: string
  name: string
  email: string
  phone: string
  productPriceId: string
  productName: string
  offerName: string
  productType: TProductTypePayment
  status: string
  paymentyMethod: string
  value: number
  createdDate: Date
  updatedDate: Date | null
  monthlyFeesPaid: number
  totalAmountPaid: number
  commissionValue: number
  frequency: TProductFrequency
  valueType: TCheckoutValueType
  sellerName: string
  mainProduct: true
  valueProductType: TValueProductTypeEnum
  inAssurance: false
  utmCampaign: string
  voucherIdentifier: string
  voucherValue: number
  itemTypeSale: TItemTypeSaleEnum
  affiliatedCode: null
  reachedOutForAbandonedOrder: false
}

export type TSubscriptionDetails = {
  id: string
  codeId: string
  name: string
  email: string
  phone: string

  productName: string
  offerName: string
  paymentyMethod: number
  value: number
  commisionValue: number
  chargeNumbers: number
  frquency: TProductFrequency
  status: SubscriptionStatusEnum
  createdDate: Date
  closingDate?: Date | null
  nextPaymentDate?: Date | null

  orderData: TSubscriptionOrderData
  orderDetails: TCheckoutOrderDetails
  transactions: TSubscriptionTransaction[]
}

export type TCheckoutAccess = {
  id?: string
  productId?: string
  productPriceId?: string
  productName: string
  paymentyMethod: string | null
  productPriceCode: string
  productPriceValue: number
  productType?: TProductTypePayment
  productTypeDescription?: string
  checkoutOrderStatus?: string | null
  checkoutModelId: string
  checkoutModelName: string
  transaction: number
  totalOrderbumpsSold: number | null
  checkoutOnlyUser: number
  orderId?: string | null
  customerName?: string | null
  createdDate: Date
}

export type TCheckoutAccessPagination = {
  items: TCheckoutAccess[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}

export type TCheckoutOrderAnticipation = {
  code: string
  name: string
  email: string
  fee: number
  comission: number
  values: number
  withdraws: number
  withdrawFees: number
  available: number
  waitingFunds: number
  total: number
  ourFee: number
  amountReceive: number
  amountPayment: number
}

export type TCheckoutOrderAnticipationPagination = {
  items: TCheckoutOrderAnticipation[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}

export interface ICheckoutOrdersFilter {
  startDate: Date | null | undefined
  endDate: Date | null | undefined
  sellerType?: number | null
  products?: string[] | null
  affiliateds?: string[] | null
  payments?: string[] | null
  status?: string[] | null
  lastDays?: number
}

export interface ICheckoutOrdersReportProps {
  page: number
  rows: number
  search?: string
  type: TCheckoutOrderReportTab
  report: TCheckoutOrderReport
  filters: ICheckoutOrdersFilter
}

export interface ISubscriptionsFilter {
  dateType: SubscriptionDateFilterType
  startDate: Date | null | undefined
  endDate: Date | null | undefined
  sellerType?: number | null
  products?: string[] | null
  affiliateds?: string[] | null
  payments?: string[] | null
  status?: string[] | null
}

export interface ISubscriptionsReportProps {
  page: number
  rows: number
  search?: string
  type: SubscriptionStatusEnum
  filters: ISubscriptionsFilter
}
