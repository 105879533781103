export enum TOccurrenceTypeEnum {
  Info = 1,
  Warnning = 2,
  Error = 3,
}

export enum TOccurrenceEventEnum {
  Withdraws = 1,
  MemberService = 2,
  MemberServiceInclude = 3,
  MemberServiceCancel = 4,
  SubscriptionFailed = 5,
  VoxuyUpdateStatus = 6,
  VoxuyAbandonedCheckout = 7,
}

export const OccurrenceEventEnumDescription = {
  [TOccurrenceEventEnum.Withdraws]: 'Solicitação de saque',
  [TOccurrenceEventEnum.MemberService]: 'Área de membros',
  [TOccurrenceEventEnum.MemberServiceInclude]:
    'Área de membros - Inclusão de acesso',
  [TOccurrenceEventEnum.MemberServiceCancel]:
    'Área de membros - Cancelamento de acesso',
  [TOccurrenceEventEnum.SubscriptionFailed]: 'Assinatura - Falha na cobrança',
  [TOccurrenceEventEnum.VoxuyUpdateStatus]: 'Voxuy - Atualização de status',
  [TOccurrenceEventEnum.VoxuyAbandonedCheckout]:
    'Voxuy - Atualização de status do carrinho abandonado',
}

export enum TOccurrenceStatusEnum {
  Active = 1,
  Inactive = 2,
  Resolved = 3,
  Unresolved = 4,
}

export type TOccurrenceLog = {
  id: string
  codeId?: string
  description?: string
  status?: TOccurrenceStatusEnum
  type?: TOccurrenceTypeEnum
  event?: TOccurrenceEventEnum
  eventDescription?: string
  createdDate: Date
  updatedDate: Date
}

export type TOccurrenceLogPagination = {
  items: TOccurrenceLog[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}

export type TOccurrenceDetails = {
  id: string
  codeId?: string
  description?: string
  data?: string
  status?: TOccurrenceStatusEnum
  type?: TOccurrenceTypeEnum
  event?: TOccurrenceEventEnum
  isFixed?: boolean
  createdDate: Date
  updatedDate: Date

  interactions: TOccurrenceInteration[]
}

export type TOccurrenceInteration = {
  actorName: string
  message: string
  isFixed: boolean
}
