import { Box, Button, Stack } from '@mui/material'
import { SubscriptionDateFilter } from './filters/SubscriptionDateFilter'
import { SearchTypeFilter } from './filters/SearchTypeFilter'
import { ProductFilter } from './filters/ProductFilter'
import { AffiliatedFilter } from './filters/AffiliatedFilter'
import { PaymentMethodsFilter } from './filters/PaymentMethodsFilter'
import { StatusFilter } from './filters/StatusFilter'
import { useState } from 'react'
import { TProductSimple } from '../../../../core/types/Product'
import { TAffiliatedSimple } from '../../../../core/types/Affiliated'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { ISubscriptionsFilter } from '../../../../core/types/CheckoutOrder'
import { SubscriptionDateFilterType } from '../../../../core/types/Order'

export interface IAdvancedFiltersProps {
  open: boolean
  products: TProductSimple[]
  affiliateds: TAffiliatedSimple[]
  startDateDefault: Date | null
  endDateDefault: Date | null
  onlyRangeDate: boolean
  filters: ISubscriptionsFilter
  hasTypeFilter?: boolean
  setOpen: (value: boolean) => void
  handleCloseFilter: () => void
  onChangeFilters: (value: ISubscriptionsFilter) => void
}

export const SubscriptionAdvancedFilters = ({
  open,
  affiliateds,
  products,
  onlyRangeDate,
  filters,
  hasTypeFilter,
  setOpen,
  handleCloseFilter,
  onChangeFilters,
}: IAdvancedFiltersProps) => {
  const [dateType, setDateType] = useState<SubscriptionDateFilterType>(
    filters.dateType
  )
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    filters.startDate
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    filters.endDate
  )
  const [searchType, setSearchType] = useState(filters.sellerType)

  const [productIds, setProductIds] = useState<string[]>(filters.products ?? [])
  const [productsList, setProductLists] = useState<string[]>([])

  const [affiliatedIds, setAffiliatedIds] = useState<string[]>(
    filters.affiliateds ?? []
  )
  const [selectdAffiliateds, setSelectdAffiliateds] = useState<string[]>([])

  const [paymentIds, setPaymentIds] = useState<string[]>(filters.payments ?? [])
  const [selectdPaymentsMethods, setSelectdPaymentsMethods] = useState<
    string[]
  >([])

  const [status, setStatus] = useState<string[]>(filters.status ?? [])
  const [selectdStatus, setSelectdStatus] = useState<string[]>([])

  const handleCleanFilters = () => {
    setStartDate(new Date(1900, 0, 1))
    setEndDate(new Date())
    setSearchType(0)
    setProductIds([])
    setProductLists([])
    setAffiliatedIds([])
    setSelectdAffiliateds([])
    setPaymentIds([])
    setSelectdPaymentsMethods([])
    setStatus([])
    setSelectdStatus([])
  }

  const handleDateFilter = (value: any) => {
    setDateType(value.dateType)
    setStartDate(value.startDate)
    setEndDate(value.endDate)
  }

  const handleChangeFilter = () => {
    onChangeFilters({
      ...filters,
      dateType,
      startDate,
      endDate,
      affiliateds: affiliatedIds,
      payments: paymentIds,
      products: productIds,
      sellerType: searchType,
      status: status,
    })
    handleCloseFilter()
  }

  const handleChangeCancel = () => {
    const oldProductLists = products
      .filter((p) => (filters.products ?? []).includes(p.id))
      .map((p) => p.name)

    const oldSelectedAffiliateds = affiliateds
      .filter((a) => (filters.affiliateds ?? []).includes(a.id))
      .map((a) => a.name)

    setDateType(filters.dateType)
    setStartDate(filters.startDate)
    setEndDate(filters.endDate)
    setSearchType(filters.sellerType)
    setProductIds(filters.products ?? [])
    setProductLists(oldProductLists)
    setAffiliatedIds(filters.affiliateds ?? [])
    setSelectdAffiliateds(oldSelectedAffiliateds)

    handleCloseFilter()
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleChangeCancel}
      setOpen={setOpen}
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Box>Filtros avançados</Box>
          <Button
            variant="text"
            size="small"
            onClick={handleCleanFilters}
            sx={{ textTransform: 'none' }}
          >
            Limpar Filtros
          </Button>
        </Stack>
      }
      buttons={[
        { title: 'Cancelar', onClick: handleChangeCancel, type: 'negative' },
        { title: 'Filtrar', onClick: handleChangeFilter, type: 'positive' },
      ]}
    >
      <Stack direction="column" spacing={2} sx={{ width: '100%', pt: 1 }}>
        <SubscriptionDateFilter
          dateType={dateType}
          startDate={startDate}
          endDate={endDate}
          handleDateFilter={handleDateFilter}
        />

        {!onlyRangeDate && (
          <>
            {hasTypeFilter && (
              <SearchTypeFilter
                typeFilterValue={searchType ?? 0}
                handleTypeFilter={setSearchType}
              />
            )}

            <ProductFilter
              products={products}
              handleProductFilter={setProductIds}
              productsList={productsList}
              setProductLists={setProductLists}
            />

            <AffiliatedFilter
              affiliateds={affiliateds}
              handleAffiliatedFilter={setAffiliatedIds}
              affiatedList={selectdAffiliateds}
              setAffiliatedList={setSelectdAffiliateds}
            />

            <PaymentMethodsFilter
              handlePaymentMethodFilter={setPaymentIds}
              selectds={selectdPaymentsMethods}
              setSelectds={setSelectdPaymentsMethods}
            />

            <StatusFilter
              handleStatusFilter={setStatus}
              selectds={selectdStatus}
              setSelectds={setSelectdStatus}
            />
          </>
        )}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}
