import React, { useCallback, useRef } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import {
  ISubscriptionsReportProps,
  TCheckoutOrderPagination,
  TSubscriptionPagination,
} from '../types/CheckoutOrder'
import SubscriptionController from '../controllers/SubscriptionController'

const valueDefault = {
  items: [],
  page: 0,
  rowsPerPage: 0,
  totalPages: 0,
  total: 0,
}

export const useSubscriptions = ({
  page,
  rows,
  search,
  type,
  filters,
}: ISubscriptionsReportProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TSubscriptionPagination>(valueDefault)
  const [error, setError] = React.useState<string>('')

  const executing = useRef(false)

  const execute = useCallback(async () => {
    if (!loading && !executing.current) {
      executing.current = true
      if (!loading) {
        const {
          dateType,
          startDate,
          endDate,
          affiliateds,
          payments,
          products,
          sellerType,
          status,
        } = filters

        try {
          setLoading(true)

          if (error !== '') {
            setError('')
          }

          var response = await SubscriptionController.getAll({
            page,
            rows,
            search,
            type,
            dateType,
            startDate,
            endDate,
            affiliateds,
            payments,
            products,
            sellerType,
            status,
          })

          const responseError = response as IErrorResponse
          const responseData = response as TSubscriptionPagination

          if (responseError.code) {
            setError(responseError.error)
            setData(valueDefault)
          } else {
            setData(responseData)
          }
        } catch (e) {
          var message = 'Erro não identificado'
          if (typeof e === 'string') {
            message = e.toUpperCase()
          } else if (e instanceof Error) {
            const err = e as Error
            message = err.message
          }
          setError(message)
        } finally {
          setLoading(false)
          executing.current = false
        }
      }
    }
  }, [error, loading, page, rows, search, type, executing, filters])

  React.useEffect(() => {
    execute()
  }, [page, rows, type, filters])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
