import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { TMarketplace } from '../../core/types/Marketplace'
import React, { useCallback, useEffect } from 'react'
import { TProduct } from '../../core/types/Product'
import ProductController from '../../core/controllers/ProductController'
import { IErrorResponse } from '../../core/types/ErrorResponse'
import AffiliatesFunction from '../../core/functions/affiliates'
import NumberFunctions from '../../core/functions/NumberFunctions'
import { TAffiliated, TAffiliatedStatus } from '../../core/types/Affiliated'
import AffiliationController from '../../core/controllers/AffiliationController'
import { ITenant } from '../../core/types/Tenant'
import Hosts, { payUrl } from '../../core/functions/hosts'
import CheckoutIcon from '@mui/icons-material/ContentCopy'
import { maskPhoneNumber } from '../../masks/masks'
import SwipeableDrawerRightDialog from '../../components/Dialogs/SwipeableDrawerRightDialog'
import { a11yProps } from '../../components/Tabs'
import ListItemDefault from '../../components/ListItem/ListItemDefault'
import { HtmlTooltip } from '../../components/Tooltip/HtmlTooltip'

export interface IMarketplaceDetailsProps {
  marketplace?: TMarketplace
  tenant?: ITenant | null
  open: boolean
  handleClose: () => void
  setOpen: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setCopied: React.Dispatch<React.SetStateAction<boolean>>
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

const MarketplaceDetails = ({
  marketplace,
  tenant,
  open,
  handleClose,
  setOpen,
  setSuccess,
  setCopied,
}: IMarketplaceDetailsProps) => {
  const [value, setValue] = React.useState(0)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TProduct | null>(null)
  const [error, setError] = React.useState<string>('')
  const [affiliated, setAffiliated] = React.useState<TAffiliated | undefined>(
    undefined
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleAffiliated = async () => {
    if (data && tenant) {
      if (!data.affiliate || !data.affiliate.id) {
        setError('Produto não configurado para afiliação')
        return
      }

      setLoading(true)
      try {
        const affiliate: TAffiliated = {
          tenantId: tenant.id,
          productAffiliationId: data.affiliate.id,
          status: TAffiliatedStatus.Pending,
          productId: '',
          productActive: true,
          productAllowRegistratioinThanksPages: false,
          activedRecoverySale: false,
        }
        const response = await AffiliationController.insertAffiliated({
          data: affiliate,
        })
        if (!response.success) {
          setError(response.error)
        } else {
          setSuccess(true)
          getProduct(data.id)
        }
      } finally {
        setLoading(false)
      }
    }
  }

  const getProduct = useCallback(async (productId: string) => {
    try {
      setLoading(true)

      if (error !== '') {
        setError('')
      }
      if (data !== null) {
        setData(null)
      }

      const response = await ProductController.get({ id: productId })
      var responseError = response as IErrorResponse

      if (responseError.code) {
        setError(responseError.error)
      } else {
        const responseData = response as TProduct
        setData(responseData)

        const res = await AffiliationController.getAffiliatedByProductId({
          productId,
        })
        responseError = res as IErrorResponse
        if (!responseError.code) {
          const dataAffiliate = res as TAffiliated
          setAffiliated(dataAffiliate)
        } else {
          setAffiliated(undefined)
        }
      }
    } catch (e) {
      var message = 'Erro não identificado'
      if (typeof e === 'string') {
        message = e.toUpperCase()
      } else if (e instanceof Error) {
        const err = e as Error
        message = err.message
      }
      setError(message)
    } finally {
      setLoading(false)
    }
  }, [])

  const hasAffiliated = () => {
    const isAffiliated =
      !loading &&
      error === '' &&
      marketplace &&
      tenant &&
      marketplace.tenantId !== tenant.id
    return isAffiliated
  }

  useEffect(() => {
    if (marketplace) {
      getProduct(marketplace.productId)
    }
  }, [marketplace])

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleClose}
      setOpen={setOpen}
      title=""
    >
      <Stack direction="column" width="100%" height="60vh">
        {/*<DrawerHeader />*/}
        <Stack direction="column" sx={{ px: 2 }}>
          <Stack
            direction="row"
            spacing={1.5}
            alignContent="center"
            alignItems="center"
          >
            <img
              src={
                !marketplace?.imageProductUrl
                  ? `${process.env.PUBLIC_URL}/assets/logo/icon.svg`
                  : marketplace?.imageProductUrl
              }
              style={{
                height: '80px',
                width: '80px',
                maxWidth: 380,
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 5,
              }}
            />
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <Typography
                color="#343948"
                fontWeight={700}
                fontSize="16px"
                lineHeight="20px"
                sx={{ flexGrow: 1 }}
              >
                {marketplace?.productName}
              </Typography>
              <Typography
                color="#1C52BD"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                sx={{ flexGrow: 1 }}
              >
                {marketplace?.author}
              </Typography>
            </Stack>
            {hasAffiliated() && (
              <Stack direction="column" sx={{ width: '40%', flexGrow: 0 }}>
                {(!affiliated || !affiliated.codeId) && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleAffiliated}
                    sx={{ textTransform: 'none' }}
                  >
                    Solicitar Afiliação
                  </Button>
                )}
                {affiliated &&
                  affiliated.status === TAffiliatedStatus.Pending && (
                    <Chip
                      label="Solicitação pendente"
                      size="medium"
                      color="info"
                    />
                  )}
                {affiliated &&
                  affiliated.status === TAffiliatedStatus.Accepted && (
                    <Chip
                      label="Você já é um afiliado"
                      size="medium"
                      color="success"
                    />
                  )}
                {affiliated &&
                  affiliated.status === TAffiliatedStatus.Refused && (
                    <Chip label="Negada" size="medium" color="warning" />
                  )}
                {affiliated &&
                  affiliated.status === TAffiliatedStatus.Blocked && (
                    <Chip label="Bloqueada" size="medium" color="error" />
                  )}
              </Stack>
            )}
          </Stack>
        </Stack>

        {loading && (
          <Stack
            direction="column"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            height={250}
          >
            <CircularProgress />
          </Stack>
        )}

        {!loading && error !== '' && (
          <Box sx={{ m: 2 }}>
            <Alert
              onClose={() => {
                setError('')
                handleClose()
              }}
              severity="error"
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Box>
        )}

        {!loading && error === '' && (
          <Box sx={{ p: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Produto" {...a11yProps(0)} />
                <Tab label="Detalhes" {...a11yProps(1)} />
                <Tab label="Ofertas" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box>
                <ListItemDefault
                  variant="compact"
                  title="Categoria"
                  value={AffiliatesFunction.descriptionProductAffiliatesCategory(
                    data?.affiliate?.category
                  )}
                  backgroundColor="#F3F3F4"
                  viewDivider={false}
                  widthTitlePercentage={50}
                  textAlignValue="right"
                />
                {data?.productSaleLink && (
                  <ListItemDefault
                    variant="compact"
                    title="Página de Venda"
                    value={
                      <Stack
                        direction="row"
                        spacing={1}
                        width="100%"
                        alignItems="center"
                        justifyContent="end"
                      >
                        {data?.productSaleLink ?? ''}
                        <HtmlTooltip title="Copiar página de venda">
                          <IconButton
                            size="small"
                            onClick={async () => {
                              await Hosts.copyTextToClipboard(
                                data?.productSaleLink ?? ''
                              )
                              setCopied(true)
                            }}
                          >
                            <CheckoutIcon />
                          </IconButton>
                        </HtmlTooltip>
                      </Stack>
                    }
                    backgroundColor="#F3F3F4"
                    viewDivider={false}
                    widthTitlePercentage={50}
                    textAlignValue="right"
                  />
                )}
                <ListItemDefault
                  variant="compact"
                  title="Descrição"
                  value={
                    <Box>
                      {(data?.affiliate?.description || '')
                        .split('\n')
                        .map((paragraph) => (
                          <Typography variant="body2">{paragraph}</Typography>
                        ))}
                    </Box>
                  }
                  backgroundColor="#F3F3F4"
                  viewDivider={false}
                  direction="column"
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box>
                <ListItemDefault
                  variant="compact"
                  title="Telefone de Suporte Afiliados"
                  value={maskPhoneNumber(data?.affiliate?.supportPhone)}
                  backgroundColor="#F3F3F4"
                  viewDivider={false}
                  widthTitlePercentage={50}
                  textAlignValue="right"
                />
                <ListItemDefault
                  variant="compact"
                  title="Comissão"
                  value={`${affiliated?.commissionPercentage || 0}%`}
                  backgroundColor="#F3F3F4"
                  viewDivider={false}
                  widthTitlePercentage={50}
                  textAlignValue="right"
                />
                <ListItemDefault
                  variant="compact"
                  title="Comissão Global"
                  value={`${affiliated?.globalCommissionPercentage || 0}%`}
                  backgroundColor="#F3F3F4"
                  viewDivider={false}
                  widthTitlePercentage={50}
                  textAlignValue="right"
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              {(marketplace?.tenantId === tenant?.id ||
                !affiliated ||
                affiliated.status !== TAffiliatedStatus.Accepted) && (
                <>
                  {data?.prices
                    ?.filter((p) => !p.isDisabled)
                    .map((price) => (
                      <ListItemDefault
                        variant="compact"
                        title={price.offerName}
                        value={NumberFunctions.formatMoneyDefault(price.value)}
                        backgroundColor="#F3F3F4"
                        viewDivider={false}
                        widthTitlePercentage={50}
                        textAlignValue="right"
                      />
                    ))}
                </>
              )}
              {affiliated &&
                affiliated.status === TAffiliatedStatus.Accepted &&
                marketplace?.tenantId !== tenant?.id && (
                  <>
                    {data?.prices
                      ?.filter((p) => !p.isDisabled)
                      .map((price) => (
                        <ListItemDefault
                          variant="compact"
                          title={price.offerName}
                          value={
                            <Stack
                              direction="row"
                              spacing={1}
                              width="100%"
                              alignItems="center"
                              justifyContent="end"
                            >
                              {NumberFunctions.formatMoneyDefault(price.value)}
                              <HtmlTooltip title="Copiar Link">
                                <IconButton
                                  size="small"
                                  onClick={async () => {
                                    await Hosts.copyTextToClipboard(
                                      `${payUrl}/${price.codeId}?afid=${affiliated.codeId}`
                                    )
                                    setCopied(true)
                                  }}
                                >
                                  <CheckoutIcon />
                                </IconButton>
                              </HtmlTooltip>
                            </Stack>
                          }
                          backgroundColor="#F3F3F4"
                          viewDivider={false}
                          widthTitlePercentage={50}
                          textAlignValue="right"
                        />
                      ))}
                  </>
                )}
            </TabPanel>
          </Box>
        )}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default MarketplaceDetails
