import { useResponsive } from '../../../../../core/hooks/useResponsive'
import { TCoproducer } from '../../../../../core/types/Coproducer'
import ProductAffiliatesItemMobile from './ProductCoproducerItemMobile'
import ProductAffiliatesItemWeb from './ProductCoproducerItemWeb'

export type ProductCoproducerItemEvent = (
  coproducer: TCoproducer,
  index: number
) => void

export interface IProductCoproducerItem {
  coproducer: TCoproducer
  index: number
  isAdmin: boolean
  onEdit: ProductCoproducerItemEvent
  onCancel: ProductCoproducerItemEvent
}

const ProductCoproducerItem = (data: IProductCoproducerItem) => {
  const mdUp = useResponsive('up', 'md')

  if (mdUp) return <ProductAffiliatesItemWeb {...data} />

  return <ProductAffiliatesItemMobile {...data} />
}

export default ProductCoproducerItem
