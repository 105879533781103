import { useContext, useEffect, useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Icon,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { BalanceLightCard } from './BalanceLightCard'
import { BalanceDarkCard } from './BalanceDarkCard'
import { useCurrentTenantBalance } from '../../../core/hooks/useCurrentTenantBalance'
import { TenantController } from '../../../core/controllers/TenantController'
import { WithdrawGrid } from './WithdrawGrid'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import TenantBalanceWihdrawCard from '../../../components/Cards/TenantBalanceWihdrawCard'
import TenantAnticipationDrawerRight from '../TenantAnticipation/TenantAnticipation'
import { useCurrentTenantSettings } from '../../../core/hooks/useCurrentTenantSettings'
import { AuthContext } from '../../../core/context/AuthContext'
import { maskMoney } from '../../../masks/masks'
import ContentDialog from '../../../components/Dialogs/ContentDialog'
import SimpleCard from '../../../components/Cards/SimpleCard'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import { grey } from '@mui/material/colors'
import { EIdentityValidation, IWithdraw } from '../../../core/types/Tenant'
import IdentityVerificationModal from './VerifyIdentity'
import IdentityAlert from './IdentityVerificationAlert'
import { useTenantSettingsByTenantId } from '../../../core/hooks/useTenantSettingsByTenantId'

export const TenantBalanceWithdraw = () => {
  const { user } = useContext(AuthContext)

  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [openAnticipation, setOpenAnticipation] = useState(false)
  const [openWithdraw, setOpenWithdraw] = useState(false)
  const [error, setError] = useState('')
  const [value, setValue] = useState('0')
  const [grid, setGrid] = useState<IWithdraw[]>([])
  const [openVerifyIdentityModal, setVerifyIdentityModal] = useState(false)
  const { data, loading, execute } = useCurrentTenantBalance()
  const {
    data: tenantSettings,
    loading: tenantSettingsLoading,
    execute: getTenantSettings,
  } = useCurrentTenantSettings({ tenantId: user?.TenantId ?? '' })

  const { data: settings } = useTenantSettingsByTenantId({
    id: user?.TenantId ?? '',
  })

  const handleChangeRefresh = () => {
    execute()
    getTenantSettings()
  }

  const handleChange = (e: any) => {
    setValue(e.target.value)
  }

  const handleOpenWithdraw = () => {
    setValue('0')
    setOpenWithdraw(true)
  }

  const closeWithdraw = () => {
    setOpenWithdraw(false)
  }

  const getAmountValue = () => {
    if (isErrorWithdrawValue()) {
      return 0
    }

    const onlyValue =
      typeof value === 'string' ? value.replaceAll('R$ ', '') : value
    const amount = parseFloat(onlyValue ?? '0')
    return amount < 3.67 ? 0 : amount - 3.67
  }

  const isErrorWithdrawValue = () => {
    const onlyValue =
      typeof value === 'string' ? value.replaceAll('R$ ', '') : value
    const amount = parseFloat(onlyValue ?? '0')
    return amount > (data?.availableAmount ?? 0)
  }

  const isPositiveDisable = () => {
    const onlyValue =
      typeof value === 'string' ? value.replaceAll('R$ ', '') : value
    const amount = parseFloat(onlyValue ?? '0')
    if (
      data?.minWithdrawValue !== undefined &&
      amount < data.minWithdrawValue
    ) {
      return true
    }

    return isErrorWithdrawValue()
  }

  const handleVerifyidentitySubmit = async () => {
    setVerifyIdentityModal(true)
    return false
  }

  const handleSubmit = async () => {
    const onlyValue =
      typeof value === 'string' ? value.replaceAll('R$ ', '') : value
    const amount = parseFloat(onlyValue || '0')

    if (
      data?.minWithdrawValue !== undefined &&
      amount < data.minWithdrawValue
    ) {
      setError(
        `O valor mínimo para solicitar um saque é de ${NumberFunctions.formatMoneyDefault(data?.minWithdrawValue)}`
      )
      return false
    }

    if (!data?.availableAmount) {
      setError('Verifique o saldo disponível')
      return false
    }

    if (data?.availableAmount && amount > data?.availableAmount) {
      setError('O valor solicitado é maior que o saldo disponível')
      return false
    }

    if (data?.recipientStatus !== 'active') {
      setError('Por favor, verifique a situação do recebedor com o suporte')
      return false
    }

    setSending(true)
    setSuccess(false)
    setError('')

    try {
      let response
      response = await TenantController.withdrawRequest(amount)

      if (!response.success) {
        setError(response.error)
        return false
      } else {
        setSuccess(true)
        setValue('0')
      }
    } finally {
      execute()
      setSending(false)
    }

    return true
  }

  useEffect(() => {
    if (data !== null) {
      const items = data?.withdraws || []
      setGrid(items)
    }
  }, [data])

  if (loading) {
    return <LoadingBackdrop open={true} />
  }

  return (
    <>
      <Stack direction={'column'} p={2} sx={{ width: '100%' }}>
        <IdentityAlert
          status={data?.identityValidation}
          withdrawlsEnabled={settings?.withdrawlsEnabled}
        />
        <Stack
          direction="column"
          spacing={2}
          alignContent="center"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Box width="100%">
            <Box
              display="flex"
              width="100%"
              sx={{
                backgroundImage: {
                  xs: 'none',
                  md: "url('assets/crown/crownVector.png')",
                },
                backgroundRepeat: { xs: 'none', md: 'no-repeat' },
                backgroundPosition: { xs: 'none', md: 'right top ' },
                backgroundSize: { xs: 'none', md: '236px 168px' },
              }}
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={2}
                width="100%"
                gap="16px"
                sx={{
                  m: 0,
                  p: { xs: '0', md: '20px 40px 20px 40px' },
                }}
              >
                <Stack
                  direction={{ sm: 'column', md: 'row' }}
                  spacing={2}
                  width="100%"
                >
                  <BalanceDarkCard balance={data?.availableAmount ?? 0} />
                  <Divider orientation="vertical" flexItem />
                  <BalanceLightCard balance={data?.totalAmount ?? 0} />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={{ xs: 'center', md: 'flex-end' }}
                  spacing={2}
                >
                  {[
                    EIdentityValidation.Unverified,
                    EIdentityValidation.Waiting,
                  ].includes(
                    data?.identityValidation ?? EIdentityValidation.Unverified
                  ) && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={handleVerifyidentitySubmit}
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '50%',
                          padding: 0,
                          backgroundColor: '#F3F3F4',
                          border: '1px solid #DCDDDE',
                        }}
                      >
                        <img
                          src="/assets/icons/withdraw/GrantedBuy.svg"
                          alt="grantedBuy"
                          style={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '16px',
                          color: '#38393B',
                          marginTop: '8px',
                          textAlign: 'center',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Verificar Identidade
                      </Typography>
                    </Box>
                  )}
                  {data?.identityValidation ===
                    EIdentityValidation.Verified && (
                    <IconButton
                      color="primary"
                      type="submit"
                      size="small"
                      onClick={handleOpenWithdraw}
                      sx={{
                        ml: 1,
                        p: '0px',
                        fontSize: '0px',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src="/assets/icons/withdraw/withdrawIcon.svg"
                        alt="withdraw"
                      />
                      <Typography
                        sx={{
                          width: '100%',
                          fontWeight: '500',
                          size: '12px',
                          lineHeight: '16px',
                          alignContent: 'center',
                          color: '#38393B',
                        }}
                      >
                        Sacar
                      </Typography>
                    </IconButton>
                  )}
                </Stack>
              </Stack>
            </Box>
            <Divider flexItem />
          </Box>

          <Stack
            direction={{ md: 'row', sm: 'column' }}
            spacing={2}
            width="100%"
            sx={{ mb: 2 }}
          >
            <Box
              display="flex"
              width={
                data && data.immediateWithdrawal > 0
                  ? { sx: '100%', md: '60%' }
                  : '100%'
              }
            >
              <TenantBalanceWihdrawCard
                current={data?.anticipationType ?? null}
                tenantSettings={tenantSettings}
                onClick={() => setOpenAnticipation(true)}
              />
            </Box>

            {(data?.immediateWithdrawal || 0) > 0 && (
              <Stack
                direction={'row'}
                width={{ sx: '100%', md: '40%' }}
                alignContent="center"
                alignItems="center"
              >
                <Divider orientation="vertical" flexItem />

                <Alert
                  severity="success"
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '18px',
                    color: '#707275',
                  }}
                  iconMapping={{
                    success: (
                      <Box
                        sx={{
                          width: '40px',
                          height: '40px',
                          backgroundColor: '#E9F8E7',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img src="assets/icons/pix/pixIcon.svg" alt="pix" />
                      </Box>
                    ),
                  }}
                >
                  <AlertTitle
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600',
                      lineHeight: '18px',
                      color: '#38393B',
                    }}
                  >
                    Saque Imediato (ativado)
                  </AlertTitle>
                  Parabéns. Agora você pode sacar vendas no PIX
                  instantaneamente.
                </Alert>
              </Stack>
            )}
          </Stack>

          <Divider flexItem />

          <WithdrawGrid data={grid} />

          <TenantAnticipationDrawerRight
            open={openAnticipation}
            setOpen={setOpenAnticipation}
            sending={sending}
            tenantSettings={tenantSettings}
            anticipationType={data?.anticipationType}
            setSending={setSending}
            refresh={handleChangeRefresh}
            setError={setError}
          />

          <ContentDialog
            open={openWithdraw}
            onClose={closeWithdraw}
            maxWidth="xs"
            title="Solicitar saque"
            labelPrositive={'Solicitar saque'}
            onPositive={async () => {
              return await handleSubmit()
            }}
            buttonsDisabled={isPositiveDisable()}
          >
            <Stack direction="column" width="100%" spacing={2} sx={{ py: 1 }}>
              <SimpleCard
                sx={{
                  px: 2,
                  mb: 2,
                  backgroundColor: '#F2F2FF',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Icon
                  sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src="assets/icons/money/moneyIcon.svg" alt="money" />
                </Icon>
                <Stack flexDirection="column">
                  <Typography variant="caption" color="#707275">
                    Saldo disponível para saque
                  </Typography>
                  <Typography
                    variant="h5"
                    color={(theme) => theme.palette.grey[900]}
                    fontWeight={500}
                  >
                    {NumberFunctions.formatMoneyDefault(data?.availableAmount)}
                  </Typography>
                </Stack>
              </SimpleCard>
              <Stack direction="column" spacing={0}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Valor do saque"
                  focused
                  value={maskMoney(value)}
                  onChange={(e) => {
                    const globalCommissionPercentage = maskMoney(e.target.value)
                      .replaceAll('.', '')
                      .replaceAll(',', '.')
                    setValue(globalCommissionPercentage)
                  }}
                  error={isErrorWithdrawValue()}
                />
                <Typography variant="caption">
                  Informe o valor a ser retirado.
                </Typography>
                <Typography variant="caption">
                  Valor mínimo de{' '}
                  {NumberFunctions.formatMoneyDefault(data?.minWithdrawValue)} e
                  valor máximo do saldo disponível.
                </Typography>
              </Stack>
              <Alert
                severity="warning"
                sx={{ color: '#707275', backgroundColor: '#FBF3E2' }}
              >
                Cobramos uma taxa de <strong>R$ 3,67</strong> por saque
              </Alert>
              <SimpleCard
                sx={{
                  px: 0,
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  color={grey[600]}
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="14px"
                >
                  Valor líquido do saque que será creditado
                </Typography>
                <Typography
                  variant="h5"
                  color={grey[900]}
                  fontWeight={600}
                  fontSize="20px"
                  lineHeight="28px"
                >
                  {NumberFunctions.formatMoneyDefault(getAmountValue())}
                </Typography>
              </SimpleCard>
            </Stack>
          </ContentDialog>

          <LoadingBackdrop open={loading || sending || tenantSettingsLoading} />

          <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
            {error}
          </ErrorSnackbar>

          <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
            Solicitação de nova retirada criado com sucesso.
          </SuccessSnackbar>

          <IdentityVerificationModal
            open={openVerifyIdentityModal}
            setOpen={setVerifyIdentityModal}
            isRequestIdentityValidation={data?.isRequestIdentityValidation}
            isIdentityValidationWaiting={
              data?.identityValidation === EIdentityValidation.Waiting
            }
            identityValidationUrl={data?.identityValidationUrl}
          />
        </Stack>
      </Stack>
    </>
  )
}
