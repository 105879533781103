import {
  Box,
  Slide,
  Typography,
  Paper,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TabPanel } from './TabPanel'
import { useCurrentTenant } from '../../core/hooks/useCurrentTenant'
import { TenantBankAccount } from './TenantBankAccount'
import { TenantBalanceWithdraw } from './TenantBalanceWithdraw'
import TenantIndividual from './TenantIndividual'
import TenantCorporation from './TenantCorporation'
import { TenantIntegrationApi } from './TenantIntegrationApi'
import { useCurrentTenantRecipient } from '../../core/hooks/useCurrentTenantRecipient'
import { TPersonType } from '../../core/types/Tenant'
import ContentDialog from '../../components/Dialogs/ContentDialog'
import { TenantBankAccountWidget } from './TenantBankAccountWidget'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '../../components/Query/useQuery'

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const SettingsPage = () => {
  const [animationState, setAnimationState] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const { data } = useCurrentTenant()
  const { data: recipientData, loading, execute } = useCurrentTenantRecipient()
  const navigate = useNavigate()
  const query = useQuery()
  const initialTabIndex = query.get('page') === 'withdraw' ? 2 : 0

  const tabsInfo = [
    {
      index: 0,
      label:
        recipientData?.personType === TPersonType.Individual
          ? 'Pessoa Fisica'
          : 'Pessoa Juridica',
      icon: (
        <img
          src={`/assets/icons/user/userIcon${tabIndex === 0 ? 'Selected' : ''}.svg`}
          alt="User"
        />
      ),
    },
    {
      index: 1,
      label: 'Dados bancário',
      icon: (
        <img
          src={`/assets/icons/bank/bankIcon${tabIndex === 1 ? 'Selected' : ''}.svg`}
          alt="Bank"
        />
      ),
    },
    {
      index: 2,
      label: 'Saldo/Retirada',
      icon: (
        <img
          src={`/assets/icons/balance/balanceIcon${tabIndex === 2 ? 'Selected' : ''}.svg`}
          alt="Balance"
        />
      ),
    },
    {
      index: 3,
      label: 'APi de Integração',
      icon: (
        <img
          src={`/assets/icons/integration/integrationIcon${tabIndex === 3 ? 'Selected' : ''}.svg`}
          alt="Integration"
        />
      ),
    },
  ]

  useEffect(() => {
    setTabIndex(initialTabIndex)
  }, [])

  useEffect(() => {
    setAnimationState(true)
    return () => setAnimationState(false)
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const getTabElement = (tab: any) => {
    if (tab.index === 0 && recipientData)
      return recipientData.personType === TPersonType.Individual ? (
        <TenantIndividual
          data={recipientData}
          loading={loading}
          execute={execute}
        />
      ) : (
        <TenantCorporation
          data={recipientData}
          loading={loading}
          execute={execute}
        />
      )

    if (tab.index === 1) return <TenantBankAccount tenantId={data?.id || ''} />
    if (tab.index === 2) return <TenantBalanceWithdraw />
    if (tab.index === 3) return <TenantIntegrationApi />
    return <div></div>
  }

  const handleCloseNotTenantBank = () => {
    navigate('/')
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [windowSize, setWindowSize] = React.useState([
    window.outerWidth,
    window.outerHeight,
  ])

  React.useEffect(() => {
    setAnimationState(true)

    window.addEventListener('resize', (ev) => {
      setWindowSize([window.outerWidth, window.outerHeight])
    })

    return () => setAnimationState(false)
  }, [])

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Configurações
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: '100%', margin: '0 auto' }}
      >
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <Box>
            <Box
              display="flex"
              component={Paper}
              pt={2}
              pb={5}
              sx={{
                flexDirection: 'column',
                justifyContent: 'left',
                flexBasis: 1,
                alignItems: 'left',
                width: '100%',
                maxWidth: '100%',
                margin: '0 auto',
                p: 0,
              }}
              elevation={0}
            >
              <Stack direction={{ xs: 'column', md: 'row' }}>
                <Box
                  display="flex"
                  sx={{
                    display: 'flex',
                    minHeight: { xs: 0, md: 224 },
                    maxWidth: {
                      xs: windowSize[0],
                    },
                    width: isMobile ? undefined : 260,
                  }}
                >
                  <Tabs
                    value={tabIndex}
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="basic tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                  >
                    {tabsInfo.map((item, index) => (
                      <Tab
                        label={item.label}
                        icon={item.icon}
                        iconPosition="start"
                        {...a11yProps(index)}
                        key={`key001-${index}`}
                        sx={{
                          alignSelf: 'start',
                          orientation: 'isMobile',
                        }}
                      />
                    ))}
                  </Tabs>
                </Box>
                <Box
                  display="flex"
                  sx={{
                    width: '100%',
                    justifyContent: { xs: 'center', md: 'left' },
                    alignItems: { xs: 'center', md: 'left' },
                  }}
                >
                  {tabsInfo.map((item, index) => (
                    <TabPanel
                      value={tabIndex}
                      index={index}
                      key={`key-02${index}`}
                      p={index === 2 ? 0 : 3}
                    >
                      {getTabElement(item)}
                    </TabPanel>
                  ))}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Slide>
      </Box>

      {data && (
        <ContentDialog
          open={recipientData?.bankAccount === null}
          onClose={() => {}}
          hasCancel={false}
          isTitle={false}
          hideButtons
        >
          <TenantBankAccountWidget
            tenantId={data?.id ?? ''}
            tenant={data}
            reload={execute}
            onClose={handleCloseNotTenantBank}
          />
        </ContentDialog>
      )}
    </Box>
  )
}
