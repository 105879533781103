import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import AccordionDefault from '../../../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../../../components/Accordions/AccordionSummaryDefault'
import MyCoproducerFunctions from '../../../MyCoproducers/MyCoproducerFunctions'
import AccordionDetailsDefault from '../../../../../components/Accordions/AccordionDetailsDefault'
import { addDays, dateTimeString } from '../../../../../core/functions/dateTime'
import { IProductCoproducerItem } from './ProductCoproducerItem'

const ProductCoproducerItemMobile = ({
  coproducer,
  index,
  isAdmin,
  onEdit,
  onCancel,
}: IProductCoproducerItem) => {
  const truncateString = (str: string = '', num: number) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  return (
    <Box key={`coproducer${index}`} width="100%" sx={{ mb: 1 }}>
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography
                    sx={{
                      color: 'rgba(56, 57, 59, 1) ',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 500,
                    }}
                  >
                    {truncateString(coproducer.tenantName, 20)}
                  </Typography>
                </Box>
              </Box>

              <Chip
                label={MyCoproducerFunctions.getStatusLabel(coproducer.status)}
                size="small"
                sx={MyCoproducerFunctions.getStatusChipStyles(
                  coproducer.status
                )}
              />
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Início
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {dateTimeString(coproducer.createdDate)}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Vencimento
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {coproducer.contractDuration === 999
                  ? 'Eterno'
                  : dateTimeString(
                      addDays(
                        coproducer.contractDuration || 0,
                        coproducer.createdDate
                      )
                    )}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                NFe
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {coproducer.issuanceInvoices === true ? 'Sim' : 'Não'}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Comissão
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {coproducer.commissionPercentage}%
              </Typography>
            </Box>

            {!isAdmin && (
              <Stack sx={{ mt: 1, width: '120px' }}>
                <Button
                  size="small"
                  onClick={() => onEdit(coproducer, index)}
                  startIcon={
                    <img
                      src={'/assets/icons/eyes/eyeButtonWhite.svg'}
                      alt="icon-eyes"
                      style={{ width: '20px', height: '20px' }}
                    />
                  }
                  sx={{
                    backgroundColor: 'rgba(56, 57, 59, 1)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(56, 57, 59, 0.9)',
                    },
                    borderRadius: '4px',
                    padding: '2px 8px 2px 4px',
                  }}
                >
                  <Typography
                    sx={{
                      height: '16px',
                      fontFamily: 'Montserrat',
                      fontWeight: 700,
                      fontSize: '12px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Visualizar
                  </Typography>
                </Button>
              </Stack>
            )}
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </Box>
  )
}

export default ProductCoproducerItemMobile
