import { Stack } from '@mui/material'
import IconFilterButton from '../IconFilterButton'

export enum ValueOrPercentageGroupButtonsEnum {
  Value,
  Percentage,
}

export interface IValueOrPercentageGroupButtonsProps {
  selected: ValueOrPercentageGroupButtonsEnum
  setSelected: (value: ValueOrPercentageGroupButtonsEnum) => void
}

const ValueOrPercentageGroupButtons = ({
  selected,
  setSelected,
}: IValueOrPercentageGroupButtonsProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignContent="center"
      alignItems="center"
    >
      <IconFilterButton
        icon={
          <>
            <img
              alt="Percentagem"
              src={`/assets/icons/values/percentage.svg`}
              style={{
                display:
                  selected === ValueOrPercentageGroupButtonsEnum.Percentage
                    ? 'none'
                    : 'block',
              }}
            />
            <img
              alt="Percentagem"
              src={`/assets/icons/values/percentage-selected.svg`}
              style={{
                display:
                  selected === ValueOrPercentageGroupButtonsEnum.Percentage
                    ? 'block'
                    : 'none',
              }}
            />
          </>
        }
        backgroundColor={
          selected === ValueOrPercentageGroupButtonsEnum.Percentage
            ? '#F2F2FF'
            : '#F3F3F4'
        }
        onClick={() =>
          setSelected(ValueOrPercentageGroupButtonsEnum.Percentage)
        }
      />
      <IconFilterButton
        icon={
          <>
            <img
              alt="Valor"
              src={`/assets/icons/values/money.svg`}
              style={{
                display:
                  selected === ValueOrPercentageGroupButtonsEnum.Value
                    ? 'none'
                    : 'block',
              }}
            />
            <img
              alt="Valor"
              src={`/assets/icons/values/money-selected.svg`}
              style={{
                display:
                  selected === ValueOrPercentageGroupButtonsEnum.Value
                    ? 'block'
                    : 'none',
              }}
            />
          </>
        }
        backgroundColor={
          selected === ValueOrPercentageGroupButtonsEnum.Value
            ? '#F2F2FF'
            : '#F3F3F4'
        }
        onClick={() => setSelected(ValueOrPercentageGroupButtonsEnum.Value)}
      />
    </Stack>
  )
}

export default ValueOrPercentageGroupButtons
