import * as React from 'react'
import { useState } from 'react'
import { addDays, dateDescription } from '../../../../core/functions/dateTime'
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import TitlePageTypography from '../../../../components/Typographys/TitlePageTypography'
import SubtitlePageTypography from '../../../../components/Typographys/SubtitlePageTypography'
import DataTable from '../../../../components/Tables/DataTable'
import { TOccurrenceLog } from '../../../../core/types/OccurrenceLog'
import { useOccurrenceLog } from '../../../../core/hooks/useOccurrenceLogs'
import OccurrenceDetailsPage from './OccurrenceLogDetails/OccurrenceDetailPage'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import { a11yProps } from '../../../../components/Tabs'
import { occurrenceLogColumns } from './OccurrenceLogConsts'

export default function OccurrenceLogPage() {
  const [tabIndex, setTabIndex] = React.useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [type, setType] = useState('allOccurences')
  const [current, setCurrent] = useState<TOccurrenceLog | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorSending, setErrorSending] = useState('')
  const [startDate, setStartdate] = useState<Date | null | undefined>(
    addDays(-29)
  )
  const [endDate, setEndDate] = useState<Date | null | undefined>(new Date())
  const [openFilter, setOpenFilter] = useState(false)

  const { loading, data, error, execute, clear } = useOccurrenceLog({
    page,
    rows: rowsPerPage,
    startDate,
    endDate,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleDateFilter = (value: any) => {
    setStartdate(value.startDate)
    setEndDate(value.endDate)
  }

  const handleOpenFilter = () => setOpenFilter(true)

  const handleChangeItem = (occurenceLog: TOccurrenceLog) => {
    setCurrent(occurenceLog)
    setOpen(true)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        setType('allOccurrences')
        break
    }
    setTabIndex(newValue)
  }

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Stack flexGrow="1">
          <TitlePageTypography>Registro de Ocorrências</TitlePageTypography>
          <SubtitlePageTypography>
            {`Filtro do período de ${dateDescription(startDate)} à ${dateDescription(endDate)}`}
          </SubtitlePageTypography>
        </Stack>

        <TextField
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              execute()
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img alt="Pesquisar" src="/assets/icons/search/search.svg" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'black',
            },
            shrink: search !== '',
            required: false,
          }}
          sx={(theme) => ({
            backgroundColor: 'white',
            '& .Mui-focused .MuiInputAdornment-root': {
              color: theme.palette.primary.main,
            },
            '& .Mui-error .MuiInputAdornment-root': {
              color: theme.palette.error.main,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              px: 5.5,
            },
          })}
          label="Pesquisar"
        />

        <Button
          sx={{
            p: 0,
            m: 0,
            minWidth: 0,
            borderRadius: '8px',
          }}
          onClick={handleOpenFilter}
        ></Button>
      </Stack>

      <Stack
        direction="row"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        alignItems="center"
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Ocorrências" {...a11yProps(0)} />
        </Tabs>
      </Stack>

      <DataTable
        columns={occurrenceLogColumns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onItemClick={handleChangeItem}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <OccurrenceDetailsPage
        occurrence={current}
        open={open}
        handleClose={handleClose}
        setOpen={setOpen}
        setSending={setSending}
        setSuccess={setSuccess}
        setError={setErrorSending}
        execute={execute}
      />
      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>
      <LoadingBackdrop open={loading || sending} />
    </Box>
  )
}
