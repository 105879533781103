import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Stack,
  Select,
  MenuItem,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CheckoutIcon from '@mui/icons-material/ContentCopy'
import { useNavigate } from 'react-router-dom'
import { useLostSales } from '../../../core/hooks/useLostSales'
import { dateTimeString } from '../../../core/functions/dateTime'
import Hosts from '../../../core/functions/hosts'
import { maskPhoneNumber } from '../../../masks/masks'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import { useQuery } from '../../../components/Query/useQuery'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../core/hooks/useDownloadFile'
import { ExportToFile } from '../ExportToFile'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import AlertDefault from '../../../components/Alerts'

interface Column {
  id: 'createdDate' | 'name' | 'email' | 'productName' | 'phone' | 'codeId'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'createdDate', label: 'Criado', minWidth: 80 },
  { id: 'name', label: 'Nome', minWidth: 100 },
  {
    id: 'productName',
    label: 'Produto',
    minWidth: 100,
  },
  {
    id: 'phone',
    label: 'Celular',
    minWidth: 100,
  },
  {
    id: 'codeId',
    label: 'Checkout',
    minWidth: 60,
  },
]

export default function CheckoutAbandonedPage() {
  const query = useQuery()

  const report =
    (query.get('type') || 'order') === 'order' ? 'order' : 'subscription'

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [lastDays, setLastDays] = React.useState(30)
  const [copied, setCopied] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')

  const { loading, data, error, execute, clear } = useLostSales({
    page,
    rows: rowsPerPage,
    search,
    lastDays,
    report,
  })

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  const navigate = useNavigate()

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.getLostSales({
      fileType: typeFile,
      search,
      lastDays,
      type: '',
      report,
    })
  }

  const handlerDownloadingError = (e: string) => setErrorSending(e)
  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '_').replace('.', '_')}.${
      type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'
    }`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const handleConvertToXLS = async () => {
    downloadCallback(ExportFileTypeEnum.XLS)
  }

  const handleConvertToCSV = async () => {
    downloadCallback(ExportFileTypeEnum.CSV)
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        {report === 'order' ? 'Vendas ' : 'Assinaturas '} Abandonadas
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <Stack direction="row" spacing={1}>
        <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-filter">
            Filtrar por nome
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-filter"
            onChange={(e) => setSearch(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle search"
                  onClick={execute}
                  //onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Filtrar por nome"
          />
        </FormControl>

        <FormControl sx={{ width: '20%' }}>
          <InputLabel id="days-select-label">Últimos</InputLabel>
          <Select
            variant="outlined"
            labelId="days-select-label"
            label="Últimos"
            defaultValue={30}
            value={lastDays}
            onChange={(e) => setLastDays(Number(e.target.value))}
          >
            <MenuItem value={30}>30 dias</MenuItem>
            <MenuItem value={60}>60 dias</MenuItem>
            <MenuItem value={90}>90 dias</MenuItem>
            <MenuItem value={180}>180 dias</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack direction="row">
        <ExportToFile
          convertToCSV={handleConvertToCSV}
          convertToXLS={handleConvertToXLS}
        />
      </Stack>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                const query = `name=${encodeURIComponent(row.name)}&email=${encodeURIComponent(
                  row.email || ''
                )}&phone=${encodeURIComponent(row.phone || '')}&zipcode=${encodeURIComponent(
                  row.cep || ''
                )}`
                const afid = row.affiliatedCode
                  ? `afid=${row.affiliatedCode}&`
                  : ''
                const urlBase = `${process.env.REACT_APP_PAY_HOST}/${row.productPriceCodeId}?${afid}`
                const url = `${urlBase}${query}`
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.codeId}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'createdDate') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.createdDate
                              ? dateTimeString(new Date(row.createdDate))
                              : ''}
                          </TableCell>
                        )
                      }
                      if (column.id === 'phone') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value && (
                              <Button
                                variant="text"
                                size="small"
                                startIcon={<WhatsAppIcon />}
                                onClick={() =>
                                  Hosts.openNewBlank(
                                    `https://wa.me/55${value}?text=${encodeURIComponent(
                                      url
                                    )}`
                                  )
                                }
                              >
                                {maskPhoneNumber(row.phone)}
                              </Button>
                            )}
                          </TableCell>
                        )
                      }
                      if (column.id === 'codeId') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="text"
                              size="small"
                              startIcon={<CheckoutIcon />}
                              onClick={async () => {
                                await Hosts.copyTextToClipboard(url)
                                setCopied(true)
                              }}
                            >
                              {urlBase}...
                            </Button>
                          </TableCell>
                        )
                      }
                      if (column.id === 'name') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Stack direction="column">
                              <Typography variant="subtitle1">
                                {row.name}
                              </Typography>
                              <Typography variant="subtitle2">
                                {row.email}
                              </Typography>
                            </Stack>
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : (value || '').toString()}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <LoadingBackdrop open={loading} />

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}
