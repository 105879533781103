import React, { useCallback } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import OrderController from '../controllers/OrderController'
import {
  ICheckoutOrdersFilter,
  TCheckoutAccessPagination,
} from '../types/CheckoutOrder'

export interface ICheckoutOrdersProp {
  page: number
  rows: number
  search?: string
  report: 'all' | 'order' | 'subscription'
  filters: ICheckoutOrdersFilter
}

const valueDefault = {
  items: [],
  page: 0,
  rowsPerPage: 0,
  totalPages: 0,
  total: 0,
}

export const useCheckoutAccess = ({
  page,
  rows,
  search,
  report,
  filters,
}: ICheckoutOrdersProp) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] =
    React.useState<TCheckoutAccessPagination>(valueDefault)
  const [error, setError] = React.useState<string>('')

  const execute = useCallback(async () => {
    if (!loading) {
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        var response = await OrderController.getCheckoutAccess({
          ...filters,
          page,
          rows,
          search,
          report,
        })
        const responseError = response as IErrorResponse
        const responseData = response as TCheckoutAccessPagination

        if (responseError.code) {
          setError(responseError.error)
          setData(valueDefault)
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
      }
    }
  }, [error, loading, page, rows, search, filters])

  React.useEffect(() => {
    execute()
  }, [page, rows, filters])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
