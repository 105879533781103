import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import { dateTimeString } from '../../../core/functions/dateTime'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'
import OrderFunctions from '../../../core/functions/OrderFunctions'
import Convertions from '../../../core/functions/convertions'
import MethodTypeSmallIcon from '../../../components/Checkout/Icons/MethodTypeSmallIcon'
import { checkoutOrderStatusColorMap } from '../Orders/CheckoutOrderConsts'
import { TSubscription } from '../../../core/types/CheckoutOrder'
import SubscriptionFunctions from '../../../core/functions/SubscriptionFunctions'

interface SubscriotionMobileItemProps {
  orders: TSubscription
  handleChangeItem: (value: TSubscription) => void
}

const SubscriptionMobileItem = ({
  orders,
  handleChangeItem,
}: SubscriotionMobileItemProps) => {
  const statusMap = SubscriptionFunctions.getStatusMap(orders.status)
  const { label, ...style } = statusMap

  return (
    <>
      <Box width="100%" sx={{ mb: 1, px: 2 }}>
        <AccordionDefault>
          <AccordionSummaryDefault>
            <Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      fontFamily="Montserrat"
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      color="rgba(56, 57, 59, 1)"
                    >
                      {orders.productName}
                    </Typography>
                    <Typography
                      fontFamily="Montserrat"
                      fontWeight={500}
                      fontSize="12px"
                      lineHeight="16px"
                      color="rgba(99, 126, 239, 1)"
                    >
                      {dateTimeString(orders.createdDate)}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  fontFamily="Montserrat"
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  {NumberFunctions.formatMoneyDefault(orders.value)}
                </Typography>
              </Stack>
            </Box>
          </AccordionSummaryDefault>

          <AccordionDetailsDefault>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily="Montserrat"
                  fontWeight={500}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  Tipo de venda
                </Typography>

                <Stack direction={'row'} spacing={1}>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={400}
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(28, 82, 189, 1)"
                  >
                    <img
                      src={'/assets/icons/arrow/roundArrow.svg'}
                      alt="icon-eyes"
                      style={{
                        width: '10px',
                        height: '10px',
                      }}
                    />
                    Assinatura
                  </Typography>
                </Stack>
              </Box>

              <Box
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily="Montserrat"
                  fontWeight={500}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  Vendedor:
                </Typography>
                <Stack direction={'row'} spacing={1}>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={600}
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(56, 57, 59, 1)"
                  >
                    {orders.sellerName}
                  </Typography>
                </Stack>
              </Box>

              <Box
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily="Montserrat"
                  fontWeight={500}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  Cliente:
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  {orders.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily="Montserrat"
                  fontWeight={500}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  Forma de Pagamento:
                </Typography>
                <Stack direction="row" spacing={1} justifyContent={'center'}>
                  <MethodTypeSmallIcon
                    widthCustom="16px"
                    heightCustom="16px"
                    methodType={orders.paymentyMethod}
                  />
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={400}
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(28, 82, 189, 1)"
                  >
                    {Convertions.methodTypeToString(orders.paymentyMethod)}
                  </Typography>
                </Stack>
              </Box>
              <Box
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily="Montserrat"
                  fontWeight={500}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  Comissão:
                </Typography>
                <Typography
                  variant="body2"
                  fontFamily="Montserrat"
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  {NumberFunctions.formatMoneyDefault(orders.value)}
                </Typography>
              </Box>

              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                sx={{ mt: 1 }}
              >
                <Button
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleChangeItem(orders)
                  }}
                  startIcon={
                    <img
                      src={'/assets/icons/eyes/eyeButtonWhite.svg'}
                      alt="icon-eyes"
                      style={{ width: '20px', height: '20px' }}
                    />
                  }
                  sx={{
                    backgroundColor: 'rgba(56, 57, 59, 1)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(56, 57, 59, 0.9)',
                    },
                    width: '120px',
                    height: '24px',

                    borderRadius: '4px',
                    padding: '2px 8px 2px 4px',
                    gap: '8px',
                  }}
                >
                  <Typography
                    sx={{
                      width: '61px',
                      height: '16px',
                      fontFamily: 'Montserrat',
                      fontWeight: 700,
                      fontSize: '12px',
                      lineHeight: '16px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Visualizar
                  </Typography>
                </Button>

                <Box>
                  <Chip size="small" label={label} sx={{ ...style }} />
                </Box>
              </Stack>
            </Box>
          </AccordionDetailsDefault>
        </AccordionDefault>
      </Box>
    </>
  )
}

export default SubscriptionMobileItem
