import * as React from 'react'
import { Box, Tabs, Tab, Button, Typography } from '@mui/material'
import MyProducts from './MyProducts'
import MyCoproducers from './MyCoproducers'
import MyAffiliations from './MyAffiliations'
import { useQuery } from '../../components/Query/useQuery'
import TitlePageTypography from '../../components/Typographys/TitlePageTypography'
import ContentDialog from '../../components/Dialogs/ContentDialog'
import { TenantBankAccountWidget } from '../settings/TenantBankAccountWidget'
import { useCurrentTenant } from '../../core/hooks/useCurrentTenant'
import { useCurrentTenantBank } from '../../core/hooks/useCurrentTenantBank'
import { AuthContext } from '../../core/context/AuthContext'
import NewProduct from './NewProduct'
import { TabPanel, a11yProps } from '../../components/Tabs'
import OrderBumps from '../OrderBumps'
import {
  TProductDelivery,
  TProductTypeChoose,
  TProductTypePayment,
} from '../../core/types/Product'
import ProductTypeChoose from './NewProduct/ProductTypeChoose'
import { TUserType } from '../../core/types/Auth'

const indexMyProducts = 0
const indexMyOrderbumps = 1
const indexMyCoproducers = 2
const indexMyAffiliations = 3

export default function ProductsPage() {
  const query = useQuery()

  const [tabIndex, setTabIndex] = React.useState(0)
  const [newProductType, setNewProductType] = React.useState<
    TProductTypeChoose | undefined
  >()

  React.useEffect(() => setTabIndex(initialTabIndex), [])

  const { user } = React.useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const [showNotTenantBank, setShowNotTenantBank] = React.useState(false)

  const { data: currentTenant } = useCurrentTenant()
  const {
    data: tenantBank,
    loading: loadingBank,
    execute: tenantBankExecute,
  } = useCurrentTenantBank({ tenantId: user?.TenantId ?? '' })

  const [openNew, setOpenNew] = React.useState(false)
  const [openRegister, setOpenRegister] = React.useState(false)
  const initialTabIndex =
    query.get('page') === 'affiliations' ? indexMyAffiliations : indexMyProducts

  const isNotTenantBank =
    !tenantBank ||
    !tenantBank?.accountName ||
    tenantBank?.accountName.trim() === '' ||
    !tenantBank?.recipientDocument ||
    tenantBank?.recipientDocument.trim() === ''

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const handleChangeShowNotTenantBank = () => {
    setShowNotTenantBank(true)
  }

  const handleCloseNotTenantBank = () => {
    setShowNotTenantBank(false)
  }

  React.useEffect(() => {
    setTabIndex(initialTabIndex)
  }, [])

  React.useEffect(() => {
    if (!loadingBank) {
      if (tabIndex === indexMyAffiliations) {
        if (isNotTenantBank) {
          handleChangeShowNotTenantBank()
          setTabIndex(indexMyProducts)
        }
      }
    }
  }, [loadingBank, tenantBank, tabIndex])

  const handleChangeNew = () => {
    if (isNotTenantBank) {
      handleChangeShowNotTenantBank()
      return
    }
    setOpenNew(true)
  }

  const navigateToOrderBumpNew = () => {
    setOpenRegister(true)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        <TitlePageTypography>
          {tabIndex === 1 ? 'Order Bumps' : 'Produtos'}
        </TitlePageTypography>

        {!isAdmin && [0, 1].includes(tabIndex) && (
          <Button
            variant="contained"
            color="primary"
            onClick={tabIndex === 1 ? navigateToOrderBumpNew : handleChangeNew}
            sx={{
              borderRadius: '4px',
              padding: '8px 16px',
              gap: '10px',
              textTransform: 'none',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: 700,
              }}
            >
              Novo {tabIndex === 1 ? 'Order Bump' : 'Produto'}
            </Typography>
          </Button>
        )}
      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            orientation="horizontal"
          >
            <Tab label="Meus produtos" {...a11yProps(indexMyProducts)} />
            <Tab label="Order Bump" {...a11yProps(indexMyOrderbumps)} />

            <Tab
              label="Minhas Coproduções"
              {...a11yProps(indexMyCoproducers)}
            />
            <Tab
              label="Minhas Afiliações"
              {...a11yProps(indexMyAffiliations)}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={tabIndex}
          index={indexMyProducts}
          sx={{ py: 3, px: 1 }}
        >
          <MyProducts
            isNotTenantBank={isNotTenantBank}
            showNotTenantBank={handleChangeShowNotTenantBank}
          />
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={indexMyOrderbumps}
          sx={{ py: 3, px: 1 }}
        >
          <OrderBumps open={openRegister} setOpen={setOpenRegister} />
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={indexMyCoproducers}
          sx={{ py: 3, px: 1 }}
        >
          <MyCoproducers />
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={indexMyAffiliations}
          sx={{ py: 3, px: 1 }}
        >
          <MyAffiliations />
        </TabPanel>
      </Box>

      {currentTenant && (
        <ContentDialog
          open={showNotTenantBank}
          onClose={() => {}}
          hasCancel={false}
          isTitle={false}
          hideButtons
        >
          <TenantBankAccountWidget
            tenantId={user?.TenantId ?? ''}
            tenant={currentTenant}
            reload={tenantBankExecute}
            onClose={handleCloseNotTenantBank}
          />
        </ContentDialog>
      )}

      <ProductTypeChoose
        open={openNew}
        setOpen={setOpenNew}
        onClose={() => setOpenNew(false)}
        onChange={(value) => {
          setOpenNew(false)
          setNewProductType(value)
        }}
      />

      <NewProduct
        open={newProductType !== undefined}
        setOpen={(value) => {
          if (!value) {
            setNewProductType(undefined)
          }
        }}
        typePayment={
          newProductType?.typePayment ?? TProductTypePayment.OneTimePayment
        }
        delivery={newProductType?.delivery ?? TProductDelivery.Course}
      />
    </Box>
  )
}
