import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { DateRangePicker, Range } from 'react-date-range'
import { useEffect, useState } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { defaultStaticRanges } from '../../../../../components/DateTime/ReactDateRange'
import 'react-date-range/dist/styles.css'
//import 'react-date-range/dist/theme/default.css'
import './theme/default.css'
import { addDays, dateString } from '../../../../../core/functions/dateTime'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import BasicDatePicker from '../../../../../components/DateTime/BasicDatePicker'
import { SubscriptionDateFilterType } from '../../../../../core/types/Order'

export const SubscriptionDateFilter = ({
  dateType,
  startDate,
  endDate,
  handleDateFilter,
}: {
  dateType: SubscriptionDateFilterType
  startDate: Date | null | undefined
  endDate: Date | null | undefined
  handleDateFilter: (value: {
    dateType: SubscriptionDateFilterType
    startDate: Date | null | undefined
    endDate: Date | null | undefined
  }) => void
}) => {
  const initialStartDate = addDays(-29)
  const initialEndDate = new Date()

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const [state, setState] = useState<Range[]>([
    {
      startDate: startDate ?? initialStartDate,
      endDate: endDate ?? initialEndDate,
      color: '#D8D9FF',
      key: 'sections',
      autoFocus: true,
      disabled: false,
      showDateDisplay: true,
    },
  ])

  const [type, setType] = useState<SubscriptionDateFilterType>(dateType)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChangeClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (type === SubscriptionDateFilterType.None) return
    setAnchorEl(event.currentTarget)
  }

  const handleChangeClose = () => {
    setAnchorEl(null)
  }

  const filter = (startDate?: Date, endDate?: Date) => {
    const filter = {
      dateType: type,
      startDate,
      endDate,
    }
    handleDateFilter(filter)
  }

  useEffect(() => {
    const filter = {
      dateType: type,
      startDate,
      endDate,
    }
    handleDateFilter(filter)
  }, [type])

  return (
    <Stack
      direction="row"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      spacing={1.5}
      sx={{ pt: 1, width: '100%' }}
    >
      <FormControl fullWidth>
        <InputLabel id="subscription-date-filter-type">Filtrar por</InputLabel>
        <Select
          variant="outlined"
          size="medium"
          autoWidth={false}
          disableUnderline={false}
          labelId="subscription-date-filter-type"
          label="Filtrar por"
          value={type}
          onChange={(event) => {
            setType(event.target.value as SubscriptionDateFilterType)
          }}
        >
          <MenuItem key="none" value={SubscriptionDateFilterType.None}>
            Todo o período
          </MenuItem>
          <MenuItem key="initial" value={SubscriptionDateFilterType.CreateDate}>
            Data de início
          </MenuItem>
          <MenuItem
            key="current"
            value={SubscriptionDateFilterType.NextPaymentDate}
          >
            Data próxima cobrança
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        aria-describedby={id}
        value={`${dateString(startDate)} à ${dateString(endDate)}`}
        label="Período"
        disabled={type === SubscriptionDateFilterType.None}
        onClick={handleChangeClick}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              disablePointerEvents={type === SubscriptionDateFilterType.None}
              sx={{ mr: -1, cursor: 'pointer' }}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </InputAdornment>
          ),
        }}
        sx={{ input: { cursor: 'pointer' } }}
      />
      <Popover
        id={id}
        open={open}
        onClose={handleChangeClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack direction="column">
          <Stack
            direction="row"
            spacing={1}
            width="100%"
            sx={{ pt: 1.5, pr: 0 }}
          >
            <div className="rdrDefinedRangesWrapper"></div>
            <Box maxWidth="153px">
              <BasicDatePicker
                fullWidth={false}
                label="Data inicial"
                value={startDate}
                setValue={(e) => {
                  if (e) {
                    setState((s) => [{ ...s[0], startDate: e }])
                    filter(e, state[0].endDate)
                  }
                }}
              />
            </Box>
            <Box maxWidth="153px">
              <BasicDatePicker
                fullWidth={false}
                label="Data final"
                value={endDate}
                setValue={(e) => {
                  if (e) {
                    setState((s) => [{ ...s[0], endDate: e }])
                    filter(state[0].startDate, e)
                  }
                }}
              />
            </Box>
          </Stack>
          <DateRangePicker
            onChange={(item) => {
              setState([item.sections])
              const { startDate, endDate } = item.sections
              filter(startDate, endDate)
            }}
            showPreview
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            showMonthArrow
            color="#1C52BD"
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="vertical"
            locale={ptBR}
            dateDisplayFormat="d MMM yyyy"
            inputRanges={[]}
            staticRanges={defaultStaticRanges}
            weekdayDisplayFormat="EEEEEE"
          />
        </Stack>
      </Popover>
    </Stack>
  )
}
