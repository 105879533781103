import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box, Typography, Button, Fab } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { useMemberServices } from '../../../../core/hooks/useMemberServices'
import InputSearch from '../../../../components/Inputs/InputSearch'
import TablePaginationDefault from '../../../../components/Tables/TablePaginationDefault'
import {
  TMemberService,
  TMemberServiceType,
} from '../../../../core/types/MemberService'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import MemberServiceController from '../../../../core/controllers/MemberServiceController'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import TheMembersRegister from '../TheMembersRegister'
import AlertDefault from '../../../../components/Alerts'

interface Column {
  id: 'identifier' | 'domain' | 'action'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'identifier', label: 'Nome', minWidth: 150 },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 100,
  },
]

const memberServideDefault: TMemberService = {
  id: '',
  identifier: '',
  domain: '',
  apiKey: '',
  token: '',
  webhookCode: '',
  type: TMemberServiceType.TheMembers,
}

export default function TheMembersPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [current, setCurrent] = React.useState<TMemberService>({
    ...memberServideDefault,
  })
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useMemberServices({
    page,
    rows: rowsPerPage,
    search: search,
    type: TMemberServiceType.TheMembers,
  })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleOpen = (memberService?: TMemberService) => {
    memberService ??= { ...memberServideDefault }
    if (memberService.id === '') {
      setCurrent({ ...memberService })
      setOpen(true)
    } else {
      getMemberService(memberService)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setCurrent({ ...memberServideDefault })
  }

  const handleDelete = (memberService?: TMemberService) => {
    if (!memberService) {
      return
    }
    setCurrent({ ...memberService })
    setOpenDelete(true)
  }

  const handleDeleteClose = () => {
    setOpenDelete(false)
    setCurrent({ ...memberServideDefault })
  }

  const deleteMemberService = async () => {
    if (!current || current.id === '') {
      return
    }

    setSending(true)
    setDeleted(false)
    setErrorSending('')

    try {
      const response = await MemberServiceController.remove({ id: current.id })

      if (!response.success) {
        setErrorSending(response.error)
      } else {
        setDeleted(true)
        handleDeleteClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  const getMemberService = React.useCallback(
    async (memberService: TMemberService) => {
      setSending(true)
      try {
        const response = await MemberServiceController.get({
          id: memberService.id,
        })
        const responseError = response as IErrorResponse
        if (responseError.code) {
          setErrorSending(responseError.error)
          return
        }

        const responseData = response as TMemberService
        setCurrent({ ...responseData })
        setOpen(true)
      } finally {
        setSending(false)
      }
    },
    []
  )

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        The Members
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <InputSearch name="Filtrar" setSearch={setSearch} execute={execute} />

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      if (column.id === 'action') {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Button
                              variant="text"
                              startIcon={<EditIcon />}
                              onClick={() => handleOpen(row)}
                            >
                              Editar
                            </Button>
                            <Button
                              variant="text"
                              color="error"
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDelete(row)}
                            >
                              Excluir
                            </Button>
                          </TableCell>
                        )
                      }
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Box sx={{ height: 80 }} />
      <Fab
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        aria-label={'Nova Oferta'}
        color="primary"
        onClick={() => handleOpen()}
      >
        <AddIcon />
      </Fab>

      <TheMembersRegister
        open={open}
        setOpen={setOpen}
        memberService={current}
        execute={execute}
      />

      <ConfirmDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="Deseja realmente deletar a configuração do The Members ?"
        onYes={deleteMemberService}
      />

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={deleted} onClose={() => setDeleted(false)}>
        Deletado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
