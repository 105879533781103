import { Chip, Stack } from '@mui/material'
import { IDataTableColumn } from '../../../../components/Tables/DataTable'
import { dateTimeString } from '../../../../core/functions/dateTime'
import OccurrenceLogFunction from '../../../../core/functions/occurrenceLog'
import {
  TOccurrenceLog,
  TOccurrenceStatusEnum,
  TOccurrenceTypeEnum,
} from '../../../../core/types/OccurrenceLog'
import DataTableSubcellTypography from '../../../../components/Typographys/DataTableSubcellTypography'
import OccurrenceStatusComponent from './Components/OccurrenceStatusComponent'
import OccurrenceTypeComponent from './Components/OccurrenceTypeComponent'

export const occurrenceInteractionDefault = {
  actorName: '',
  message: '',
  isFixed: false,
}

export const occurrenceStatusColumnStyle = {
  [TOccurrenceStatusEnum.Active]: {
    label: OccurrenceLogFunction.statusString(TOccurrenceStatusEnum.Active),
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  [TOccurrenceStatusEnum.Inactive]: {
    label: OccurrenceLogFunction.statusString(TOccurrenceStatusEnum.Inactive),
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  [TOccurrenceStatusEnum.Resolved]: {
    label: OccurrenceLogFunction.statusString(TOccurrenceStatusEnum.Resolved),
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  [TOccurrenceStatusEnum.Unresolved]: {
    label: OccurrenceLogFunction.statusString(TOccurrenceStatusEnum.Unresolved),
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

export const occurrenceTypeColumnStyle = {
  [TOccurrenceTypeEnum.Info]: {
    label: OccurrenceLogFunction.statusType(TOccurrenceTypeEnum.Info),
    backgroundColor: '#F2F2FF',
    color: '#476ADA',
  },
  [TOccurrenceTypeEnum.Warnning]: {
    label: OccurrenceLogFunction.statusType(TOccurrenceTypeEnum.Warnning),
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
  [TOccurrenceTypeEnum.Error]: {
    label: OccurrenceLogFunction.statusType(TOccurrenceTypeEnum.Error),
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
}

export const occurrenceLogColumns: readonly IDataTableColumn<TOccurrenceLog>[] =
  [
    {
      id: 'createdDate',
      label: 'Criado em',
      minWidth: 80,
      format: (value: any) => {
        const date = new Date(value)
        return isNaN(date.getTime())
          ? 'Data não encontrada'
          : dateTimeString(date)
      },
    },
    { id: 'codeId', label: 'Código', minWidth: 100 },
    { id: 'description', label: 'Descrição', minWidth: 80 },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      render(id, value, row) {
        return <OccurrenceStatusComponent occurrence={row} />
      },
    },
    {
      id: 'type',
      label: 'Tipo',
      minWidth: 100,
      render(id, value, row) {
        return <OccurrenceTypeComponent occurrence={row} />
      },
    },
    {
      id: 'event',
      label: 'Evento',
      minWidth: 80,
      render(id, value, row) {
        return (
          <Stack direction="column">
            <DataTableSubcellTypography>
              {OccurrenceLogFunction.statusEvent(value)}
            </DataTableSubcellTypography>
          </Stack>
        )
      },
    },
  ]
