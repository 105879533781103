import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  Fab,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { useCustomCheckouts } from '../../../core/hooks/useCustomCheckouts'
import InputSearch from '../../../components/Inputs/InputSearch'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import AlertDefault from '../../../components/Alerts'

interface Column {
  id: 'name' | 'productName' | 'action'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'name', label: 'Nome', minWidth: 150 },
  { id: 'productName', label: 'Produto', minWidth: 150 },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 100,
  },
]

export default function CustomCheckoutsPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useCustomCheckouts({
    page,
    rows: rowsPerPage,
    search: search,
  })

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  const navigate = useNavigate()

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Checkout Personalizado
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <Paper
        elevation={0}
        sx={{ width: '100%', overflow: 'hidden', p: 2, mb: 3 }}
      >
        <InputSearch
          name="Filtrar"
          mb={0}
          size="small"
          setSearch={setSearch}
          execute={execute}
        />
      </Paper>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items
                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        if (column.id === 'action') {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <Button
                                variant="text"
                                startIcon={<EditIcon />}
                                onClick={() => navigate(`/orderbump/${row.id}`)}
                              >
                                Editar
                              </Button>
                            </TableCell>
                          )
                        }
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Box sx={{ height: 80 }} />
      <Fab
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        aria-label={'Nova Oferta'}
        color="primary"
        onClick={() => navigate('/orderbump/new')}
      >
        <AddIcon />
      </Fab>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
