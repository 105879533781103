import { TCoproducerStatus } from '../../../core/types/Coproducer'

const getStatusChipStyles = (status?: TCoproducerStatus) => {
  const statusStyles = {
    [TCoproducerStatus.Confirmed]: {
      backgroundColor: 'rgba(28, 189, 63, 0.16)',
      color: 'rgba(0, 157, 35, 1)',
    },
    [TCoproducerStatus.Canceled]: {
      backgroundColor: 'rgba(189, 28, 28, 0.16)',
      color: 'rgba(212, 54, 44, 1)',
    },
    [TCoproducerStatus.Pending]: {
      backgroundColor: 'rgba(251, 243, 226, 1)',
      color: 'rgba(136, 113, 0, 1)',
    },
    [TCoproducerStatus.All]: {
      backgroundColor: 'rgba(216, 217, 255, 1)',
      color: 'rgba(28, 82, 189, 1)',
    },
  }

  return statusStyles[status ?? TCoproducerStatus.Pending]
}

const getStatusLabel = (status?: TCoproducerStatus) => {
  const statusLabels: Record<TCoproducerStatus, string> = {
    [TCoproducerStatus.Confirmed]: 'Confirmado',
    [TCoproducerStatus.Canceled]: 'Cancelado',
    [TCoproducerStatus.Pending]: 'Pendente',
    [TCoproducerStatus.All]: 'Todos',
  }

  return statusLabels[status ?? TCoproducerStatus.Pending]
}

const MyCoproducerFunctions = {
  getStatusChipStyles,
  getStatusLabel,
}

export default MyCoproducerFunctions
