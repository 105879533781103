import { Chip, Stack } from '@mui/material'
import {
  TOccurrenceLog,
  TOccurrenceTypeEnum,
} from '../../../../../core/types/OccurrenceLog'
import { occurrenceTypeColumnStyle } from '../OccurrenceLogConsts'
import DataTableSubcellTypography from '../../../../../components/Typographys/DataTableSubcellTypography'

interface OccurrenceTypeComponentProps {
  occurrence: TOccurrenceLog
}

const OccurrenceTypeComponent = ({
  occurrence,
}: OccurrenceTypeComponentProps) => {
  const { label, ...sx } =
    occurrenceTypeColumnStyle[occurrence.type ?? TOccurrenceTypeEnum.Info]

  return (
    <Stack direction="column">
      <DataTableSubcellTypography>
        <Chip label={label} size="small" sx={sx} />
      </DataTableSubcellTypography>
    </Stack>
  )
}

export default OccurrenceTypeComponent
