import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import { styled, useTheme } from '@mui/material/styles'
import useChart from '../../../components/Chart/useChart'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import Chart from '../../../components/Chart/Chart'
import { ApexOptions } from 'apexcharts'
import { Stack, Typography } from '@mui/material'
import ValueOrPercentageGroupButtons, {
  ValueOrPercentageGroupButtonsEnum,
} from '../../../components/Buttons/ValueOrPercentageGroupButtons'
import InvisibleDashboardCard from '../../../components/Cards/InvisibleDashboardCard'
import NotFoundChart from '../../../components/Chart/NotFoundChart'

const CHART_HEIGHT = 400

const LEGEND_HEIGHT = 82

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
    height: `100% !important`,
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
  '& .apexcharts-tooltip, .apexcharts-tooltip-series-group.active': {
    background: '#ffffff !important',
  },
}))

export interface IAppPaymentMethodsChartProps {
  labels?: Array<string>
  colors?: Array<string>
  series: Array<{ label: string; value: number }>
  options?: Array<any>
}

export interface IAppPaymentMethodsProps {
  chart: IAppPaymentMethodsChartProps
  title: string
  isVisibleValue: boolean
  selected: ValueOrPercentageGroupButtonsEnum
  setSelected: (value: ValueOrPercentageGroupButtonsEnum) => void
}

export default function AppPaymentMethods({
  title,
  chart,
  isVisibleValue,
  selected,
  setSelected,
  ...other
}: IAppPaymentMethodsProps) {
  const theme = useTheme()

  const { colors, series, options } = chart

  const chartSeries = series.map((i) => i.value)

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors,
    labels: series.map((i) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
      show: false,
    },
    legend: {
      show: true,
      floating: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontWeight: 400,
      fontSize: '12px',
      markers: {
        height: 8,
        width: 8,
      },
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
      style: {
        colors: ['#38393B'],
        fontSize: '12px',
        fontWeight: 400,
      },
      formatter: (value: any, opts: any) => {
        var v = chartSeries[opts.seriesIndex]
        return selected === ValueOrPercentageGroupButtonsEnum.Percentage
          ? `${Number(v).toFixed(0)}%`
          : NumberFunctions.formatMoneyDefault(v)
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value: any, opts: any) => {
          var v = chartSeries[opts.seriesIndex]
          return selected === ValueOrPercentageGroupButtonsEnum.Percentage
            ? `${Number(v).toFixed(0)}%`
            : NumberFunctions.formatMoneyDefault(v)
        },
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
          color: '#38393B',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '18px',
        },
        color: '#898493',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
          size: '50%',
        },
        dataLabels: {
          offset: 60,
          minAngleToShowLabel: 10,
        },
        customScale: 0.8,
      },
    },
    ...options,
  } as ApexOptions)

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: '8px',
        height: '100%',
      }}
      {...other}
    >
      <Stack
        direction="row"
        spacing={1}
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3 }}
      >
        <Typography
          fontWeight={600}
          fontSize="16px"
          lineHeight="20px"
          color="#343948"
        >
          {title}
        </Typography>

        <ValueOrPercentageGroupButtons
          selected={selected}
          setSelected={setSelected}
        />
      </Stack>

      {isVisibleValue &&
        series.length > 0 &&
        selected === ValueOrPercentageGroupButtonsEnum.Percentage && (
          <StyledChart
            key="ValueOrPercentageGroupButtonsEnum.Percentage"
            dir="ltr"
            type="donut"
            series={chartSeries}
            options={chartOptions}
            width="100%"
            height={300}
          />
        )}
      {isVisibleValue &&
        series.length > 0 &&
        selected === ValueOrPercentageGroupButtonsEnum.Value && (
          <StyledChart
            key="ValueOrPercentageGroupButtonsEnum.value"
            dir="ltr"
            type="donut"
            series={chartSeries}
            options={chartOptions}
            width="100%"
            height={300}
          />
        )}
      <NotFoundChart isVisible={isVisibleValue && series.length <= 0} />
      <InvisibleDashboardCard visible={!isVisibleValue} variant="chart" />
    </Card>
  )
}
