import React, { useState } from 'react'
import {
  TOccurrenceLog,
  TOccurrenceInteration,
} from '../../../../../core/types/OccurrenceLog'
import OccurrenceLogController from '../../../../../core/controllers/OccurrenceLogController'
import {
  Box,
  Divider,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import LoadingBackdrop from '../../../../../components/Loading/LoadingBackdrop'
import { useOccurrenceDetails } from '../../../../../core/hooks/useOccurrenceDetails'
import { dateTimeString } from '../../../../../core/functions/dateTime'
import SwitchRoundSmall from '../../../../../components/Switch/SwitchRoundSmall'
import { occurrenceInteractionDefault } from '../OccurrenceLogConsts'
import OccurrenceStatusComponent from '../Components/OccurrenceStatusComponent'
import OccurrenceTypeComponent from '../Components/OccurrenceTypeComponent'

export interface IOccurrenceDetailPageProp {
  occurrence?: TOccurrenceLog
  open: boolean
  handleClose: () => void
  setOpen: (value: boolean) => void
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  execute: () => void
}

const OccurrenceDetailsPage = ({
  occurrence,
  open,
  handleClose,
  setOpen,
  setSending,
  setSuccess,
  setError,
  execute,
}: IOccurrenceDetailPageProp) => {
  const [data, setData] = useState<TOccurrenceInteration>(
    occurrenceInteractionDefault
  )
  const [tabIndex, setTabIndex] = useState(0)
  const [errors, setErrors] = useState({ actorName: '', message: '' })

  const {
    loading,
    data: details,
    execute: detailsExecute,
  } = useOccurrenceDetails({
    id: occurrence?.id,
  })

  const handleMyClose = () => {
    handleClose()
  }

  const handleChangeInteration = async () => {
    const newErrors = { actorName: '', message: '' }
    let hasError = false

    if (!data.actorName) {
      newErrors.actorName = 'Nome da ocorrência é obrigatório'
      hasError = true
    }
    if (!data.message) {
      newErrors.message = 'Descrição é obrigatória'
      hasError = true
    }

    setErrors(newErrors)

    if (hasError) {
      return
    }

    if (!occurrence?.id) {
      setError('Nenhuma ocorrência selecionada')
      return
    }

    setSending(true)
    try {
      const response = await OccurrenceLogController.interation({
        occurrenceId: occurrence.id,
        data: {
          actorName: data.actorName,
          message: data.message,
          isFixed: data.isFixed,
        },
      })

      if (!response.success) {
        setError(response.error)
        return
      }

      setData({ ...occurrenceInteractionDefault })
      setSuccess(true)
      detailsExecute()
      execute()
    } finally {
      setSending(false)
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleMyClose}
      setOpen={setOpen}
      backgroundColor="#fafafa"
      title="Detalhes da Ocorrência"
      subtitle="Visualize os detalhes da ocorrência"
      buttons={
        tabIndex === 1
          ? [
              {
                title: 'Cancelar',
                onClick: () => setOpen(false),
                type: 'negative',
              },
              {
                title: 'Salvar',
                onClick: handleChangeInteration,
                type: 'positive',
              },
            ]
          : undefined
      }
    >
      <Stack>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Detalhes" />
          <Tab label="Interação" />
          <Tab label="Histórico" />
        </Tabs>
        <Divider />

        {tabIndex === 0 && (
          <Box>
            <ListItemDefault
              title="Ocorrido em"
              value={
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="space-between"
                >
                  <Box>{dateTimeString(occurrence?.createdDate)}</Box>
                  {occurrence && (
                    <OccurrenceStatusComponent occurrence={occurrence} />
                  )}
                </Stack>
              }
            />
            <ListItemDefault
              title="Código"
              value={
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="start"
                  justifyContent="space-between"
                >
                  <Box>{occurrence?.codeId}</Box>
                  {occurrence && (
                    <OccurrenceTypeComponent occurrence={occurrence} />
                  )}
                </Stack>
              }
            />
            <ListItemDefault
              title="Evento"
              value={occurrence?.eventDescription}
            />
            <ListItemDefault title="Descrição" value={details?.description} />
            <Typography variant="subtitle2" sx={{ px: 4 }}>
              Detalhes do erro
            </Typography>
            <ListItemDefault
              title=""
              value={details?.data}
              widthTitlePercentage={0.1}
              px={0.1}
            />
          </Box>
        )}

        {tabIndex === 1 && (
          <Box>
            <Stack>
              <ListItemDefault
                title="Nome da ocorrência"
                value={
                  <Box>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      rows={1}
                      type="text"
                      value={data.actorName}
                      onChange={(e) => {
                        setData({ ...data, actorName: e.target.value })
                        setErrors({ ...errors, actorName: '' })
                      }}
                      error={!!errors.actorName}
                    />
                    {errors.actorName && (
                      <Typography color="error">{errors.actorName}</Typography>
                    )}
                  </Box>
                }
              />
              <ListItemDefault
                title="Descrição"
                value={
                  <Box>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      minRows={2}
                      type="text"
                      value={data.message}
                      onChange={(e) => {
                        setData({ ...data, message: e.target.value })
                        setErrors({ ...errors, message: '' })
                      }}
                      error={!!errors.message}
                    />
                    {errors.message && (
                      <Typography color="error">{errors.message}</Typography>
                    )}
                  </Box>
                }
              />
              <ListItemDefault
                title="Resolvido"
                value={
                  <SwitchRoundSmall
                    sx={{ m: 1 }}
                    checked={data.isFixed}
                    onChange={(e) => {
                      setData({
                        ...data,
                        isFixed: e.target.checked,
                      })
                    }}
                  />
                }
              />
            </Stack>
          </Box>
        )}

        {tabIndex === 2 && (
          <Stack>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome da ocorrência</TableCell>
                  <TableCell>Descrição</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.interactions.length ?? 0 > 0 ? (
                  details?.interactions.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.actorName}</TableCell>
                      <TableCell>{entry.message}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      Nenhuma interação encontrada
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <LoadingBackdrop open={loading} />
          </Stack>
        )}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}
export default OccurrenceDetailsPage
