import { Chip, Stack } from '@mui/material'
import {
  TOccurrenceLog,
  TOccurrenceStatusEnum,
} from '../../../../../core/types/OccurrenceLog'
import { occurrenceStatusColumnStyle } from '../OccurrenceLogConsts'
import DataTableSubcellTypography from '../../../../../components/Typographys/DataTableSubcellTypography'

interface OccurrenceStatusComponentProps {
  occurrence: TOccurrenceLog
}

const OccurrenceStatusComponent = ({
  occurrence,
}: OccurrenceStatusComponentProps) => {
  const { label, ...sx } =
    occurrenceStatusColumnStyle[
      occurrence.status ?? TOccurrenceStatusEnum.Active
    ]

  return (
    <Stack direction="column">
      <DataTableSubcellTypography>
        <Chip label={label} size="small" sx={sx} />
      </DataTableSubcellTypography>
    </Stack>
  )
}

export default OccurrenceStatusComponent
