import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import 'dayjs/locale/pt-br'
import { useState } from 'react'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import {
  ICheckoutOrdersFilter,
  TCheckoutAccess,
} from '../../../../core/types/CheckoutOrder'
import { useProductsSimple } from '../../../../core/hooks/useProductsSimple'
import { useAffiliatedsSimple } from '../../../../core/hooks/useAffiliatedsSimple'
import {
  ExportFileTypeEnum,
  ReportDownloadController,
} from '../../../../core/controllers/ReportDownloadController'
import { useDownloadFile } from '../../../../core/hooks/useDownloadFile'
import FilterIcon from '@mui/icons-material/Tune'
import { ExportToFile } from '../../../Checkouts/ExportToFile'
import {
  addDays,
  dateTimeString,
  diffDays,
} from '../../../../core/functions/dateTime'
import TablePaginationDefault from '../../../../components/Tables/TablePaginationDefault'
import { AdvancedFilters } from '../../../Checkouts/AdvancedFilters'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../../components/Snackbar/ErrorSnackbar'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import CopiedSnackbar from '../../../../components/Snackbar/CopiedSnackbar'
import { useCheckoutAccess } from '../../../../core/hooks/useCheckoutAccess'
import AlertDefault from '../../../../components/Alerts'

interface Column {
  id:
    | 'createdDate'
    | 'productName'
    | 'productTypeDescription'
    | 'checkoutModelName'
    | 'transaction'
    | 'checkoutOnlyUser'
    | 'productPriceValue'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'createdDate', label: 'Criado', minWidth: 80 },
  { id: 'productName', label: 'Produto', minWidth: 100 },
  {
    id: 'productTypeDescription',
    label: 'Tipo',
    minWidth: 100,
  },
  {
    id: 'checkoutModelName',
    label: 'Layout Checkout',
    minWidth: 100,
  },
  {
    id: 'transaction',
    label: 'Transação',
    minWidth: 60,
    align: 'right',
    format: (value) => '1',
  },
  {
    id: 'checkoutOnlyUser',
    label: 'Primeiro Acesso',
    minWidth: 60,
    align: 'right',
    format: (value) => value.toString(),
  },
  {
    id: 'productPriceValue',
    label: 'Valor do Produto',
    minWidth: 60,
    align: 'right',
    format: (value) => NumberFunctions.formatMoneyDefault(value),
  },
]

export default function CheckoutAccessPage() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [copied, setCopied] = useState(false)
  const [type, setType] = useState<
    'all' | 'oneTimePayment' | 'recurringSubscription'
  >('all')
  const [current, setCurrent] = useState<TCheckoutAccess | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorSending, setErrorSending] = useState('')

  const [openFilter, setOpenFilter] = useState(false)

  const [filters, setFilters] = useState<ICheckoutOrdersFilter>({
    startDate: addDays(-29),
    endDate: new Date(),
    sellerType: 0,
    lastDays: 30,
    affiliateds: [],
    payments: [],
    products: [],
    status: [],
  })

  const { data: products, loading: loadingProducts } = useProductsSimple({
    initialExecute: true,
  })
  const { data: affiliateds, loading: loadingAffiliateds } =
    useAffiliatedsSimple({ initialExecute: true })

  const { loading, data, error, execute, clear } = useCheckoutAccess({
    page,
    rows: rowsPerPage,
    search,
    report: 'order',
    filters,
  })

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }
  }, [data, rowsPerPage])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const handleChangeItem = (checkoutOrder: TCheckoutAccess) => {
    setCurrent(checkoutOrder)
    setOpen(true)
  }

  const handleClose = () => {
    setCurrent(undefined)
    setOpen(false)
  }

  const handleDownloadFile = (typeFile: ExportFileTypeEnum) => {
    return ReportDownloadController.getOrdersAccesses({
      ...filters,
      fileType: typeFile,
      search,
      type,
      report: 'order',
    })
  }

  const handlerDownloadingError = (e: string) => setErrorSending(e)
  const getFileName = (type: ExportFileTypeEnum) =>
    `report_${new Date().toISOString().replace(':', '_').replace('.', '_')}.${
      type === ExportFileTypeEnum.XLS ? 'xlsx' : 'csv'
    }`

  const { download, downloading } = useDownloadFile({
    apiDefinition: handleDownloadFile,
    onError: handlerDownloadingError,
    getFileName,
  })

  const downloadCallback = React.useCallback(
    (type: ExportFileTypeEnum) => download(type),
    [download]
  )

  const handleConvertToXLS = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.XLS)
  }

  const handleConvertToCSV = async () => {
    if (isValidDownloadReport()) downloadCallback(ExportFileTypeEnum.CSV)
  }

  const isValidDownloadReport = (): boolean => {
    if (filters.startDate === null || filters.startDate === undefined) {
      setErrorSending('Necessário informar o período inicial.')
      return false
    }

    if (filters.endDate === null || filters.endDate === undefined) {
      setErrorSending('Necessário informar um período final.')
      return false
    }

    if (diffDays(filters.endDate, filters.startDate) > 30) {
      setErrorSending('Não informar um período maior que 30 dias')
      return false
    }

    return true
  }

  const handleOpenFilter = () => setOpenFilter(true)

  const handleCloseFilter = () => setOpenFilter(false)

  return (
    <Box>
      <Typography variant="h5" fontWeight={500} sx={{ mb: 2 }}>
        Acesso ao Checkout
      </Typography>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <Stack direction="row" spacing={1}>
        <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-filter">
            Filtrar por nome
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-filter"
            onChange={(e) => setSearch(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                execute()
              }
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle search"
                  onClick={handleOpenFilter}
                  edge="end"
                >
                  <FilterIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Filtrar por nome"
          />
        </FormControl>
      </Stack>
      {/* <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Paper sx={{ width: "100%", p: 2 }}>
          <Stack direction="column">
            <Typography variant="caption">
              Vendas encontradas
            </Typography>
            <Typography variant="h5">
              {data.total}
            </Typography>
          </Stack>
        </Paper>
        <Paper sx={{ width: "100%", p: 2 }}>
          <Stack direction="column">
            <Typography variant="caption">
              Valor líquido
            </Typography>
            <Typography variant="h5">
              {NumberFunctions.formatMoneyDefault(NumberFunctions.toNumber(data.info))}
            </Typography>
          </Stack>
        </Paper>
      </Stack> */}

      <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
        {/* <Button
          variant={type === "all" ? "outlined" : "text"}
          size="small"
          onClick={() => setType("all")}
        >
          Todas
        </Button>
        <Button
          variant={type === "oneTimePayment" ? "outlined" : "text"}
          size="small"
          onClick={() => setType("oneTimePayment")}
        >
          Pagamento único
        </Button>
        <Button
          variant={type === "recurringSubscription" ? "outlined" : "text"}
          size="small"
          onClick={() => setType("recurringSubscription")}
        >
          Assinatura recorrente
        </Button> */}

        <ExportToFile
          convertToCSV={handleConvertToCSV}
          convertToXLS={handleConvertToXLS}
        />
      </Stack>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={(row.id ?? row.createdDate).toString()}
                  >
                    {columns.map((column) => {
                      const value = row[column.id] ?? ''
                      if (column.id === 'createdDate') {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => handleChangeItem(row)}
                          >
                            {dateTimeString(new Date(value))}
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => handleChangeItem(row)}
                        >
                          <Typography>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value.toString()}
                          </Typography>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationDefault
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Box sx={{ height: 80 }} />

      <AdvancedFilters
        open={openFilter}
        setOpen={setOpenFilter}
        affiliateds={affiliateds}
        products={products}
        handleCloseFilter={handleCloseFilter}
        startDateDefault={addDays(-29)}
        endDateDefault={new Date()}
        onlyRangeDate={false}
        filters={filters}
        onChangeFilters={setFilters}
      />

      <LoadingBackdrop
        open={
          loading ||
          sending ||
          downloading ||
          loadingProducts ||
          loadingAffiliateds
        }
      />

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar open={copied} onClose={() => setCopied(false)} />
    </Box>
  )
}
