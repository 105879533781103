import { useState, useRef } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  useMediaQuery,
  Stack,
} from '@mui/material'
import MemberServiceController from '../../../core/controllers/MemberServiceController'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'

interface MemberClassModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  setSelectedOption: (value: string) => void
  onAcceptMemberClass: () => Promise<void>
}

const MemberClassModal = ({
  open,
  setOpen,
  setSelectedOption,
  onAcceptMemberClass,
}: MemberClassModalProps) => {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const sending = useRef(false)

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const handleClose = () => setOpen(false)

  const registerMemberClass = async () => {
    setLoading(true)
    try {
      if (sending.current) return

      sending.current = true
      const response = await MemberServiceController.registerMemberClass()

      if (!response.success) {
        setError(response.error)
        return
      }

      await onAcceptMemberClass()
      setSelectedOption('club')
      setSuccess(true)
      handleClose()
    } finally {
      setLoading(false)
      sending.current = false
    }
  }

  return (
    <>
      <SwipeableDrawerRightDialog
        open={open}
        onClose={handleClose}
        setOpen={setOpen}
        title="Celetus Club"
        buttons={[
          {
            title: 'Criar Celetus Club',
            type: 'positive',
            isDisabled: loading,
            onClick: registerMemberClass,
          },
          {
            title: 'Cancelar',
            onClick: handleClose,
            type: 'negative',
          },
        ]}
      >
        <Stack direction={isSmallScreen ? 'column' : 'row'}>
          <Box sx={{ p: 1 }}>
            <Typography
              fontFamily={'Montserrat'}
              fontWeight={700}
              fontSize={'16px'}
              lineHeight={'20px'}
              color={'rgba(56, 57, 59, 1)'}
            >
              Mais Vendas, mais engajamento e seus cursos em um só lugar.
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 2 }}>
              <ListItem sx={{ display: 'list-item', fontSize: '13px' }}>
                Todos os seus cursos e produtos em uma única vitrine
                personalizada.
              </ListItem>
              <ListItem sx={{ display: 'list-item', fontSize: '13px' }}>
                Novo layout de consume para seus alunos.
              </ListItem>
              <ListItem sx={{ display: 'list-item', fontSize: '13px' }}>
                Através de um único link, seus alunos verão os cursos comprados
                e também outros cursos à venda. aumentando a taxa de recompra.
              </ListItem>
              <ListItem sx={{ display: 'list-item', fontSize: '13px' }}>
                Proteção antifraude.
              </ListItem>
            </List>
          </Box>
        </Stack>
      </SwipeableDrawerRightDialog>
      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Registrado com sucesso
      </SuccessSnackbar>

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>
      <LoadingBackdrop open={loading} />
    </>
  )
}

export default MemberClassModal
