import { Box, Divider, Stack, Typography } from '@mui/material'

export type IListItemDefaultVariant = 'compact' | 'normal'

interface IListItemDefaultProp {
  key?: string
  title: string | React.ReactNode
  value?: string | JSX.Element
  widthTitlePercentage?: number
  viewDivider?: boolean | undefined
  py?: number
  px?: number
  variant?: IListItemDefaultVariant
  direction?: 'row' | 'column' | undefined
  children?: React.ReactNode
  backgroundColor?: string
  textAlignValue?: 'start' | 'center' | 'right'
}

const ListItemDefault = ({
  key,
  title,
  value,
  widthTitlePercentage,
  viewDivider,
  py,
  px,
  variant = 'normal',
  direction,
  children,
  backgroundColor,
  textAlignValue,
}: IListItemDefaultProp) => {
  const direct = direction || 'row'
  const align = direct === 'row' ? 'center' : 'start'
  const widthTitle =
    direct === 'row' ? `${widthTitlePercentage || 30}%` : '100%'
  const widthValue =
    direct === 'row' ? `${100 - (widthTitlePercentage || 30)}%` : '100%'
  const spacing = direct === 'row' ? 2 : 0

  const sxVariant = {
    ['compact']: {
      mx: 1,
      my: 0.8,
    },
    ['normal']: {
      mx: 2,
      my: 1.5,
    },
  }

  return (
    <Box key={key}>
      <Stack
        direction={direct}
        spacing={spacing}
        alignContent={align}
        alignItems={align}
        sx={{
          backgroundColor,
          py: py ?? 1.5,
          px: px ?? 1.5,
          border: viewDivider ?? true ? '1px solid #DCDDDE' : undefined,
          borderRadius: '4px',
          ...sxVariant[variant],
        }}
      >
        {!children && (
          <>
            <Typography variant="subtitle2" sx={{ width: widthTitle }}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              textAlign={textAlignValue}
              sx={{ width: widthValue }}
            >
              {value}
            </Typography>
          </>
        )}
        {children}
      </Stack>
    </Box>
  )
}

export default ListItemDefault
