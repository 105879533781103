import React from 'react'
import { Alert, AlertTitle, Stack, Typography } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../components/Dialogs/SwipeableDrawerRightDialog'
import { TenantController } from '../../../core/controllers/TenantController'
import Hosts from '../../../core/functions/hosts'

interface IdentityVerificationModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  sending?: boolean
  isRequestIdentityValidation: boolean | undefined
  isIdentityValidationWaiting: boolean
  identityValidationUrl: string | undefined
}

const IdentityVerificationModal: React.FC<IdentityVerificationModalProps> = ({
  isRequestIdentityValidation,
  open,
  setOpen,
  sending,
  isIdentityValidationWaiting,
  identityValidationUrl,
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  const handleVerificationIdentityUrl = async () => {
    if (isIdentityValidationWaiting && identityValidationUrl) {
      Hosts.redirect(identityValidationUrl)
      handleClose()
      return
    }

    try {
      let response = await TenantController.getIdentityVerificationURL()
      console.log(response)
      if ('identityValidationUrl' in response) {
        Hosts.redirect(response?.identityValidationUrl)
        handleClose()
      } else {
      }
    } finally {
    }
  }

  return (
    <SwipeableDrawerRightDialog
      title="Verificação de Identidade"
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      buttons={[
        { title: 'Cancelar', onClick: handleClose, type: 'negative' },
        {
          title: isIdentityValidationWaiting
            ? 'Retomar verificação'
            : isRequestIdentityValidation
              ? 'Iniciar verificação'
              : 'OK',
          onClick: () =>
            isRequestIdentityValidation || isIdentityValidationWaiting
              ? handleVerificationIdentityUrl()
              : handleClose(),
          type: 'positive',
        },
      ]}
      buttonsDisabled={sending}
    >
      <Stack direction="column" spacing={1}>
        {(isRequestIdentityValidation || isIdentityValidationWaiting) && (
          <Stack spacing={2}>
            <Alert severity="info">
              <AlertTitle>
                Tenha seus documentos em mãos para concluir o processo de
                verificação de identidade.
              </AlertTitle>
            </Alert>
            <Alert severity="warning">
              <AlertTitle>Atenção!</AlertTitle>O sistema não aceita fotos
              tiradas de telas de smartphones ou computadores. Para validação, é
              necessário que os documentos estejam em mãos, pois documentos
              digitais não são aceitos.
            </Alert>
          </Stack>
        )}

        {!isRequestIdentityValidation && !isIdentityValidationWaiting && (
          <Typography variant="body1">
            Solicitamos que, por gentileza, realize vendas que acumulem um valor
            mínimo de R$ 200,00 para que a verificação de identidade possa ser
            validada.
          </Typography>
        )}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default IdentityVerificationModal
