import { Box, Stack, Typography } from '@mui/material'
import ListItemTitleTypography from '../../../../../components/Typographys/ListItemTitleTypography'
import { useResponsive } from '../../../../../core/hooks/useResponsive'
import { TCoproducer } from '../../../../../core/types/Coproducer'
import ProductCoproducerItem, {
  ProductCoproducerItemEvent,
} from './ProductCoproducerItem'

interface ProductCoproducerListProps {
  coproducers: TCoproducer[]
  isAdmin: boolean
  onEdit: ProductCoproducerItemEvent
  onCancel: ProductCoproducerItemEvent
}

const ProductCoproducerList = ({
  coproducers,
  isAdmin,
  onEdit,
  onCancel,
}: ProductCoproducerListProps) => {
  const mdUp = useResponsive('up', 'md')

  if (coproducers.length <= 0) {
    return (
      <Typography variant="body1" align="center" sx={{ p: 3 }}>
        Nenhum coprodutor encontrado para este produto. Clique em + Novo
        Coprodutor e cadastre os coprodutores para o produto.
      </Typography>
    )
  }

  return (
    <Stack direction="column" spacing={1}>
      {coproducers.length > 0 && mdUp && (
        <Box sx={{ pt: 2 }}>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
            sx={{ px: 2 }}
          >
            <Box sx={{ width: '18%' }}>
              <ListItemTitleTypography>Criado em</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '22%' }}>
              <ListItemTitleTypography>Nome</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '16%' }}>
              <ListItemTitleTypography>Comissão</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '16%' }}>
              <ListItemTitleTypography>Validade</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '14%' }}>
              <ListItemTitleTypography>Status</ListItemTitleTypography>
            </Box>
            <Box sx={{ width: '8%' }}>
              <ListItemTitleTypography>Ações</ListItemTitleTypography>
            </Box>
          </Stack>
        </Box>
      )}
      {coproducers.map((coproducer, index) => (
        <ProductCoproducerItem
          key={`coproducerlist${index}`}
          coproducer={coproducer}
          index={index}
          isAdmin={isAdmin}
          onEdit={onEdit}
          onCancel={onCancel}
        />
      ))}
    </Stack>
  )
}

export default ProductCoproducerList
