import api, { getResponseError } from '../../services/api'
import { TSubscriptionPagination } from '../types/CheckoutOrder'
import { IErrorResponse } from '../types/ErrorResponse'
import {
  SubscriptionDateFilterType,
  SubscriptionStatusEnum,
} from '../types/Order'

interface IPaginationProp {
  page: number
  rows: number
  search?: string
  type: SubscriptionStatusEnum
  dateType: SubscriptionDateFilterType
  startDate?: Date | null | undefined
  endDate?: Date | null | undefined
  sellerType?: number | null
  products?: string[] | null
  affiliateds?: string[] | null
  payments?: string[] | null
  status?: string[] | null
}

const getAll = async ({
  page,
  rows,
  search,
  type,
  dateType,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IPaginationProp): Promise<TSubscriptionPagination | IErrorResponse> => {
  try {
    var path = `/Subscription?PageNumber=${page}&RowsPerPage=${rows}&Status=${type}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (dateType !== SubscriptionDateFilterType.None && startDate) {
      endDate ??= new Date()
      path += `&TypeDateFilter=${dateType}&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    const { data } = await api.get<TSubscriptionPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const SubscriptionController = {
  getAll,
}

export default SubscriptionController
